import type { FormField } from 'components/shared/organisms/form/FormSection/types';
import MoneyInput from 'components/ui/atoms/forms/MoneyInput';

export const GrossReceipts2021: FormField[] = [
  {
    id: 'grossReceipts2021Q1Cents',
    name: 'grossReceipts2021Q1Cents',
    label: '2021 Q1 gross receipts?',
    placeholder: 'Enter number',
    control: <MoneyInput />,
    required: true,
  },
  {
    id: 'grossReceipts2021Q2Cents ',
    name: 'grossReceipts2021Q2Cents',
    label: '2021 Q2 gross receipts?',
    placeholder: 'Enter number',
    control: <MoneyInput />,
    required: true,
  },
  {
    id: 'grossReceipts2021Q3Cents',
    name: 'grossReceipts2021Q3Cents',
    label: '2021 Q3 gross receipts?',
    placeholder: 'Enter number',
    control: <MoneyInput />,
    required: true,
  },
  {
    id: 'grossReceipts2021Q4Cents',
    name: 'grossReceipts2021Q4Cents',
    label: '2021 Q4 gross receipts?',
    placeholder: 'Enter number',
    control: <MoneyInput />,
    required: true,
  },
];
