import type { MouseEvent as ReactMouseEvent, ReactNode } from 'react';

import type { Option } from '../../../models';
import type { RenderValueParams } from '../../types';
import { StyledChip } from './styled';

export type SelectValueProps = {
  label: ReactNode;
  disabled?: boolean;
  onMouseDown?: (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDelete?: (event: any) => void;
};

const SelectValue = ({ label, onDelete, disabled, onMouseDown }: SelectValueProps) => {
  return (
    <StyledChip
      label={label}
      size="small"
      variant="outlined"
      onDelete={onDelete}
      onMouseDown={onMouseDown}
      disabled={disabled}
      clickable
    />
  );
};

export const renderValue = <TV extends string, TL extends string, T extends Option<TV, TL>>({
  selectedValue,
  handleDelete,
  disabled,
  model,
}: RenderValueParams<TV, TL, T>) => {
  const onMouseDown = (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  return selectedValue.map((value) => {
    const option = model.findOption(model.options, value);

    return (
      <SelectValue
        key={value}
        label={option ? model.getOptionLabel(option) : value}
        onDelete={() => handleDelete(value)}
        disabled={disabled}
        onMouseDown={onMouseDown}
      />
    );
  });
};
