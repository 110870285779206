import { ZIP_MASKED_OPTIONS } from 'config/masks/zipCode';

import type { EventHandler, Target } from 'components/ui/atoms/forms/Input';
import Input from 'components/ui/atoms/forms/Input';
import CustomIMaskInput from 'components/ui/atoms/forms/MaskInput/CustomIMaskInput';

type ZipInputProps = {
  value: string;
  name: string;
  onChange: ({ target }: { target: Target }) => void;
  onBlur: ({ target }: { target: Target }) => void;
};

const ZipInput = ({ value, name, onChange, onBlur, ...props }: ZipInputProps) => {
  const handleAccept = (newValue: string) => {
    onChange?.({
      target: {
        name,
        value: newValue || null,
      },
    });
  };

  const handleBlur: EventHandler = (event) => {
    const { target } = event ?? {};

    onBlur?.({ target: { name, value: target?.value || null } });
  };

  return (
    <Input
      value={value}
      inputComponent={CustomIMaskInput}
      name={name}
      onBlur={handleBlur}
      inputProps={{
        unmask: true,
        onAccept: handleAccept,
        ...ZIP_MASKED_OPTIONS,
      }}
      {...props}
    />
  );
};

export default ZipInput;
