import { NotifierProvider } from 'contexts/NotifierContext';
import ThemeProvider from 'contexts/ThemeContext';

import DefaultTemplate from 'components/templates/DefaultTemplate';
import Notifier from 'components/ui/atoms/Notifier';

import Header from './Header';
import RealEstateForm from './RealEstateForm';

const RealEstate = () => {
  return (
    <ThemeProvider>
      <NotifierProvider>
        <DefaultTemplate>
          <Header />
          <RealEstateForm />
          <Notifier />
        </DefaultTemplate>
      </NotifierProvider>
    </ThemeProvider>
  );
};

export default RealEstate;
