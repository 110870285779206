import { Wrapper } from './styled';

const NotFound = () => {
  return (
    <Wrapper>
      <h2>Page not found</h2>
    </Wrapper>
  );
};

export default NotFound;
