import { YUP_NULLABLE_REQUIRED, YUP_VALID_EMAIL, YUP_VALID_PHONE_NUMBER } from 'config/constants/yup';

import type { SignatureProps } from 'components/shared/molecules/Signature';
import SignatureInput from 'components/shared/molecules/Signature';
import EmailInput from 'components/ui/atoms/forms/EmailInput';
import PhoneInput from 'components/ui/atoms/forms/PhoneInput';
import StringInput from 'components/ui/atoms/forms/StringInput';

export const AGREE_TEXT =
  'By checking this box, I allow Premium Merchant Funding (PMF) and its associates to contact me by phone, text, or email';

enum ResubscribeFormFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  BusinessEmail = 'businessEmail',
  MobilePhone = 'mobilePhone',
  Signature = 'signature',
}

export type ResubscribeFormValues = {
  [ResubscribeFormFields.FirstName]: string | null;
  [ResubscribeFormFields.LastName]: string | null;
  [ResubscribeFormFields.BusinessEmail]: string | null;
  [ResubscribeFormFields.MobilePhone]: string | null;
  [ResubscribeFormFields.Signature]: string | null;
};

export const getResubscribeFields = (isMobile: boolean, setSignature: SignatureProps['setSignature']) => [
  {
    id: 'first-name',
    name: ResubscribeFormFields.FirstName,
    label: 'First Name',
    placeholder: 'Enter first name',
    control: <StringInput />,
    required: true,
    columns: isMobile ? 1 : 2,
  },
  {
    id: 'last-name',
    name: ResubscribeFormFields.LastName,
    label: 'Last Name',
    placeholder: 'Enter last name',
    control: <StringInput />,
    required: true,
    columns: isMobile ? 1 : 2,
  },
  {
    id: 'business-email',
    name: ResubscribeFormFields.BusinessEmail,
    label: 'Business Email',
    control: <EmailInput />,
    required: false,
  },
  {
    id: 'mobile-phone',
    name: ResubscribeFormFields.MobilePhone,
    label: 'Mobile Phone',
    control: <PhoneInput />,
    required: false,
  },
  {
    id: 'signature',
    name: ResubscribeFormFields.Signature,
    label: 'Signature',
    control: <SignatureInput setSignature={setSignature} />,
    required: true,
  },
];

export const DEFAULT_VALUES = {
  [ResubscribeFormFields.FirstName]: null,
  [ResubscribeFormFields.LastName]: null,
  [ResubscribeFormFields.BusinessEmail]: null,
  [ResubscribeFormFields.MobilePhone]: null,
  [ResubscribeFormFields.Signature]: null,
};

export const DEFAULT_VALIDATION_FIELDS = {
  [ResubscribeFormFields.FirstName]: YUP_NULLABLE_REQUIRED,
  [ResubscribeFormFields.LastName]: YUP_NULLABLE_REQUIRED,
  [ResubscribeFormFields.Signature]: YUP_NULLABLE_REQUIRED,
  [ResubscribeFormFields.BusinessEmail]: YUP_VALID_EMAIL,
  [ResubscribeFormFields.MobilePhone]: YUP_VALID_PHONE_NUMBER,
};
