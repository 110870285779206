import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DocumentGroup } from 'api/__generated__/enums';
import useCreateIntakeFormDocumentV2Mutation from 'api/mutations/intakeForm/useCreateIntakeFormDocumentV2Mutation';

import { UUID } from 'config/constants/queryParams';

import useDocumentName from 'hooks/useDocumentName';
import useNotifier from 'hooks/useNotifier';

import type { UploadedFile } from 'components/ui/molecules/File/types';

type CreateIntakeFormDocumentArgs = {
  uploadedBankStatements: Record<string, UploadedFile>;
  uploadedAdditionalDocs: Record<string, UploadedFile>;
};

type GetIntakeFormDocumentVariablesArgs = {
  files: Record<string, UploadedFile>;
  uuid: string | null;
  group: DocumentGroup;
  name?: string;
};

const getIntakeFormDocumentVariables = ({ files, group, name, uuid }: GetIntakeFormDocumentVariablesArgs) =>
  Object.values(files).map((documentTypeFile) => ({
    file: documentTypeFile,
    uuid,
    group,
    name,
  }));

const useCreateIntakeFormDocument = () => {
  const [isError, setIsError] = useState(false);
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get(UUID);

  const getDocumentName = useDocumentName();

  const { setError } = useNotifier();

  const [createIntakeFormDocumentMutation, { loading }] = useCreateIntakeFormDocumentV2Mutation({
    onCompleted: () => {},
    onError: (error) => {
      setIsError(true);
      setError(`Form error: ${error}`);
    },
  });

  const createIntakeFormDocument = async ({
    uploadedBankStatements,
    uploadedAdditionalDocs,
  }: CreateIntakeFormDocumentArgs) => {
    if (!Object.values({ ...uploadedBankStatements, ...uploadedAdditionalDocs }).length) {
      return;
    }

    const config = {
      [DocumentGroup.BankStatement]: {
        files: uploadedBankStatements,
        group: DocumentGroup.BankStatement,
        name: getDocumentName(DocumentGroup.BankStatement),
        uuid,
      },
      [DocumentGroup.Other]: {
        files: uploadedAdditionalDocs,
        group: DocumentGroup.Other,
        name: getDocumentName(DocumentGroup.Other),
        uuid,
      },
    };

    await Promise.all(
      Object.values(config)
        .flatMap((documentTypeConfig) => getIntakeFormDocumentVariables({ ...documentTypeConfig, uuid }))
        .map(async (variables) => {
          await createIntakeFormDocumentMutation({
            variables,
          });
        }),
    );
  };

  return { createIntakeFormDocument, loading, isError };
};

export default useCreateIntakeFormDocument;
