import type { MutationTuple } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

const CreateIntakeFormV2Mutation = loader('../../../graphql/mutations/intakeForm/createIntakeFormV2.graphql');

type Props = {
  onCompleted: (data: any) => void;
  onError: (error: any) => void;
};

const useCreateIntakeFormV2Mutation = ({ onCompleted, onError }: Props) => {
  const [createIntakeForm, mutationResult] = useMutation(CreateIntakeFormV2Mutation, { onCompleted, onError });

  return [createIntakeForm, mutationResult] as MutationTuple<any, any>;
};

export default useCreateIntakeFormV2Mutation;
