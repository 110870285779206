import type { FileUploader } from 'api/types/file';

import { getFileVars } from 'lib/utils/file';

import type { HolderTypes, Sign } from 'components/shared/molecules/Signature';
import { OWNER, PARTNER } from 'components/shared/molecules/Signature';

export const getVariables = (fileData: FileUploader, holderType: HolderTypes) => {
  const { filename, mimeType, size, id, storage } = getFileVars(fileData);
  const variable = {
    id,
    metadata: {
      filename,
      mimeType,
      size,
    },
    storage,
  };

  switch (holderType) {
    case OWNER:
      return {
        signature: variable,
      };
    case PARTNER:
      return {
        partnerSignature: variable,
      };
    default:
      return {};
  }
};

export const getSignatures = (withPartner: boolean, owner?: Sign, partner?: Sign) => ({
  [OWNER]: owner ?? null,
  ...(withPartner ? { [PARTNER]: partner ?? null } : {}),
});
