import type { ChangeEvent, FocusEvent, ForwardedRef, KeyboardEvent } from 'react';
import { forwardRef } from 'react';
import { toString } from 'lodash';

import { getCustomEvent, getKeyboardEvent } from './helpers/getEvent';
import StyledInput from './styled';
import type { InputProps } from './types';

const Input = (
  { value, isNoneNullable, onChange, onBlur, onKeyDown, ...props }: InputProps,
  ref: ForwardedRef<unknown>,
): JSX.Element => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange?.(getCustomEvent(event, isNoneNullable));
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onBlur?.(getCustomEvent(event, isNoneNullable));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onKeyDown?.(getKeyboardEvent(event));
  };

  return (
    <StyledInput
      ref={ref}
      value={toString(value)}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      {...props}
    />
  );
};

export default forwardRef(Input);
