import { ReactComponent as CircledCheck } from 'assets/icons/circledCheck.svg';

import BasicInformationForm from './components/BasicInformationForm';
import BusinessDetailsForm from './components/BusinessDetailsForm';
import DataSentInfo from './components/DataSentInfo';
import UploadDocumentsForm from './components/UploadDocumentsForm';

export enum INTAKE_FORM_STEPS {
  Step1 = 'STEP_1',
  Step2 = 'STEP_2',
  Step3 = 'STEP_3',
  Step4 = 'STEP_4',
}

export const INTAKE_FORM_FIRST_STEP = INTAKE_FORM_STEPS.Step1;
export const INTAKE_FORM_DEFAULT_STEP = INTAKE_FORM_FIRST_STEP;
export const INTAKE_FORM_LAST_STEP = INTAKE_FORM_STEPS.Step4;

export const STEPS_TITLES = {
  [INTAKE_FORM_STEPS.Step1]: {
    title: 'Let’s Get Started',
    subtitle:
      'We have a few questions about your business’s current financing and how we ' +
      'can customize our offer for your needs.',
    icon: null,
  },
  [INTAKE_FORM_STEPS.Step2]: {
    title: 'Tell us about your business',
    subtitle: 'We’d like to get some more details about your business.',
    icon: null,
  },
  [INTAKE_FORM_STEPS.Step3]: {
    title: 'Upload your bank statements',
    subtitle: 'Please include last 4 month of business bank statements',
    icon: null,
  },
  [INTAKE_FORM_STEPS.Step4]: {
    icon: <CircledCheck />,
    title: 'Data Sent',
    subtitle: ({ brandedPage }: { brandedPage?: boolean }) =>
      brandedPage
        ? 'Thank you for placing your trust in Premium Merchant Funding. ' +
          'We will process your request in 24 – 48 business hours.'
        : 'We will process your request in 24 – 48 business hours.',
  },
};

export const getSteps = () => [
  {
    label: 'Basic Information',
  },
  {
    label: 'Business Details',
  },
  {
    label: 'Upload Documents',
  },
  {
    label: 'Send',
  },
];

export const stepContents = [
  {
    step: INTAKE_FORM_STEPS.Step1,
    nextStep: INTAKE_FORM_STEPS.Step2,
    content: <BasicInformationForm />,
  },
  {
    step: INTAKE_FORM_STEPS.Step2,
    nextStep: INTAKE_FORM_STEPS.Step3,
    content: <BusinessDetailsForm />,
  },
  {
    step: INTAKE_FORM_STEPS.Step3,
    nextStep: INTAKE_FORM_STEPS.Step4,
    content: <UploadDocumentsForm />,
  },
  {
    step: INTAKE_FORM_STEPS.Step4,
    content: <DataSentInfo />,
  },
];
