import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import type { IntakeFormQuery as IntakeFormQueryOriginalData, IntakeFormQueryVariables } from './intakeForm.generated';

const intakeFormQuery = loader('./intakeForm.graphql');

type UseIntakeFormQueryArgs = {
  variables: IntakeFormQueryVariables;
  skip: boolean;
};

export type IntakeFormData = IntakeFormQueryOriginalData['intakeForm'];

const getData = (data: IntakeFormQueryOriginalData): IntakeFormData => data?.intakeForm;

const useIntakeFormQuery = ({ variables, skip = false }: UseIntakeFormQueryArgs) => {
  const { data, ...rest } = useQuery(intakeFormQuery, {
    fetchPolicy: 'cache-first',
    variables,
    skip,
  });

  return {
    data: getData(data),
    ...rest,
  };
};

export default useIntakeFormQuery;
