import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { styled as muiStyled } from '@mui/material/styles';

import Input from 'components/ui/atoms/forms/Input';
import MoneyInput from 'components/ui/atoms/forms/MoneyInput';

export const SliderFieldsContainer = styled.div(
  ({ theme: { spacing } }) => css`
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: ${spacing(3)};
    margin: ${spacing(3, 0)};
  `,
);

export const StyledInput = muiStyled(Input)(({ theme: { colors, palette, spacing } }) => ({
  height: '54px',
  width: '100px',

  '& .MuiOutlinedInput-input': {
    padding: spacing(1, 2),
    fontSize: '26px',
    color: colors.blackBlue,

    '&.Mui-disabled': {
      color: palette.text.secondary,
    },
  },
}));

export const StyledMoneyInput = muiStyled(MoneyInput)(({ theme: { colors, palette, spacing } }) => ({
  height: '54px',
  width: '200px',

  '& .MuiOutlinedInput-input': {
    padding: spacing(1, 2),
    fontSize: '26px',
    color: colors.blackBlue,

    '&.Mui-disabled': {
      color: palette.text.secondary,
    },
  },
}));
