import { useEffect, useState } from 'react';

import { Icons } from 'config/icons/feather';

import ErrorDecorator from 'decorators/ErrorDecorator';

import Alert, { AlertTypes } from 'components/ui/molecules/Alert';

import type { FileProps } from './types';

const File = ({ localFile, onCancel, upload, setUploads, noWrap, setLoading }: FileProps) => {
  const [errorText, setErrorText] = useState('');
  const [uploading, setUploading] = useState(false);

  const setLoad = (flag: boolean) => {
    setUploading(flag);
    setLoading(flag);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoad(true);
        const uploaded = await upload(localFile);
        setUploads(uploaded);
      } catch (error) {
        setErrorText(new ErrorDecorator(error).getMessages()[0]);
        setUploads(null);
      } finally {
        setLoad(false);
      }
    })();
  }, []);

  const { name } = localFile;
  return (
    <Alert
      type={errorText ? AlertTypes.Error : AlertTypes.Info}
      pending={uploading}
      iconType={errorText ? Icons.AlertTriangle : Icons.File}
      action={onCancel}
      noWrap={noWrap}
    >
      {errorText || name}
    </Alert>
  );
};

export default File;
