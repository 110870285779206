import type { ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { Theme as MaterialUITheme } from '@mui/material';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const Container = styled.div<{ theme?: MaterialUITheme }>(
  ({
    theme: {
      breakpoints: { values: breakpoints, down },
      spacing,
    },
  }) => css`
    max-width: 720px;
    margin: ${spacing(5, 0)};
    flex-grow: 1;
    ${down(breakpoints.md)} {
      max-width: 100%;
      padding: ${spacing(0, 2)};
    }
  `,
);

type Props = {
  children?: ReactNode;
};

const DefaultTemplate = ({ children }: Props): JSX.Element => {
  return (
    <Wrapper>
      <Container>{children}</Container>
    </Wrapper>
  );
};

export default DefaultTemplate;
