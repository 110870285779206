import { Link as ReactLink } from 'react-router-dom';

import { INTAKE_LANDING } from 'config/routes';

import Logo from 'assets/icons/logoBlue.svg';

import Link from 'components/ui/atoms/Link';

import { HeaderContainer, HeaderLogo, HelperText } from './styled';
import type { HeaderProps } from './types';

const Header = ({ brandedPage }: HeaderProps) => {
  if (!brandedPage) return null;

  return (
    <HeaderContainer>
      <ReactLink to={INTAKE_LANDING}>
        <HeaderLogo src={Logo} alt="logo" />
      </ReactLink>
      <HelperText>
        Questions? <Link href="tel:2129316858">212-931-6858</Link>
      </HelperText>
    </HeaderContainer>
  );
};

export default Header;
