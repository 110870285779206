import type { ReactNode } from 'react';

import { Wrapper } from './styled';

type LoaderProps = {
  children?: ReactNode;
  customStyles?: CSSStyleSheet;
};

const Loader = ({ children, customStyles }: LoaderProps) =>
  children ? (
    <Wrapper customStyles={customStyles} data-testid="loader">
      {children}
    </Wrapper>
  ) : (
    <Wrapper customStyles={customStyles} data-testid="loader" />
  );

export default Loader;
