import type { GetOptionValue, OptionValue } from '../types';

const findOption = <TV extends string, T extends Record<TV, OptionValue>>(
  options: T[] | undefined,
  value: OptionValue,
  getOptionValue: GetOptionValue<TV, T>,
): T | undefined => {
  return options?.find((option) => getOptionValue(option) === value);
};

export default findOption;
