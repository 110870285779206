import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div(
  ({ theme: { colors, spacing } }) => css`
    width: 100%;
    height: ${spacing(15)};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${spacing(0.5)};
    border: 1px dashed ${colors.grayLight};
  `,
);

export const BrowseLink = styled.a(
  ({ theme: { palette } }) => css`
    color: ${palette.primary.main};
  `,
);
