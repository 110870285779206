import type { RefObject } from 'react';
import { createRef, useEffect } from 'react';
import Step from '@mui/material/Step';

import { StyledStepLabel, StyledStepper } from './styled';
import type { StepperProps } from './types';

const Stepper = ({ steps, activeStep }: StepperProps) => {
  const stepRefs = steps.reduce<Record<number, RefObject<HTMLDivElement>>>((reduceResult, step, index) => {
    reduceResult[index] = createRef<HTMLDivElement>();
    return reduceResult;
  }, {});

  useEffect(() => {
    stepRefs[activeStep]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
  }, [stepRefs, activeStep]);

  return (
    <StyledStepper activeStep={activeStep}>
      {steps.map(({ label }, index) => (
        <Step key={label} ref={stepRefs[index]}>
          <StyledStepLabel>{label}</StyledStepLabel>
        </Step>
      ))}
    </StyledStepper>
  );
};

export default Stepper;
