import FormSectionMediator from 'components/shared/molecules/FormSectionMediator';
import type { FormFooterProps, SubmitHandler } from 'components/ui/molecules/FormWithValidation';
import FormWithValidation from 'components/ui/molecules/FormWithValidation';

import useIntakeFormValues from '../../hooks/useIntakeFormValues';
import { FormSubmitButton } from '../../styled';
import { DEFAULT_VALUES, fields as formFields, requiredValidationFields } from './config';
import type { BasicInformationFormProps } from './types';
import useUpdateIntakeFormBasicInfo from './useUpdateIntakeFormBasicInfo';

const FormFooter = ({ submitHandler, disabled, loading }: FormFooterProps) => (
  <FormSubmitButton onClick={submitHandler} disabled={disabled} loading={loading} variant="contained" disableElevation>
    Next
  </FormSubmitButton>
);

const BasicInformationForm = ({ isMobile }: BasicInformationFormProps) => {
  const { data, loading: dataLoading } = useIntakeFormValues(DEFAULT_VALUES);
  const { updateIntakeFormBasicInfo, loading } = useUpdateIntakeFormBasicInfo();

  const handleSubmit: SubmitHandler = async (fields) => {
    await updateIntakeFormBasicInfo({ fields });
  };

  const formConfig = {
    id: 'updateLeadBusinessInfo',
    columns: isMobile ? 1 : 2,
    withHiddenFields: false,
    formFields,
  };

  return (
    <FormWithValidation
      id="intake-form-basic-info"
      loading={loading || dataLoading}
      defaultValues={data}
      requiredValidationFields={requiredValidationFields}
      submitHandler={handleSubmit}
      FormFooter={FormFooter}
    >
      <FormSectionMediator key={formConfig.id} {...formConfig} />
    </FormWithValidation>
  );
};

export default BasicInformationForm;
