import { useSearchParams } from 'react-router-dom';

import { TERMS_OF_SERVICE_URL } from 'config/constants/queryParams';
import { REACT_APP_DEFAULT_TERMS_OF_SERVICE_URL } from 'config/vars';

import Link from 'components/ui/atoms/Link';

const ConfirmationText = () => {
  const [searchParams] = useSearchParams();
  const termsOfServiceUrl = searchParams.get(TERMS_OF_SERVICE_URL);

  return (
    <span>
      By filling in this application, I agree to the{' '}
      <Link rel="noreferrer" target="_blank" href={termsOfServiceUrl || REACT_APP_DEFAULT_TERMS_OF_SERVICE_URL}>
        {' '}
        Terms&nbsp;of&nbsp;Use&nbsp;and&nbsp;Privacy&nbsp;Policy
      </Link>
      , and give my consent to being contacted using the contact information provided above.
    </span>
  );
};

export default ConfirmationText;
