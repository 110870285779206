import { useCallback, useEffect, useState } from 'react';

import { isValidForm, runValidation } from './helpers';

const getInitValidationParams = ({ fields = {}, error = {}, touched = {}, isValid = false }) => ({
  fields,
  error,
  touched,
  isValid,
});

const getResetFields = (fields) => {
  return Object.entries(fields).reduce((reduceResult, [key, value]) => {
    reduceResult[key] = !value ? value : null;
    return reduceResult;
  }, {});
};

const useValidation = (initValues = {}, validateOnInit = false) => {
  const [{ fields, error, touched, isValid }, setValidationParams] = useState(getInitValidationParams(initValues));
  const autoValidation = useCallback(
    (schema, nameField, value) => {
      const schemaErrors = runValidation(
        schema,
        nameField
          ? {
              ...fields,
              [nameField]: value,
            }
          : fields,
      );

      setValidationParams(({ fields: exFields, touched: exTouched }) => ({
        error: schemaErrors,
        fields: nameField ? { ...exFields, [nameField]: value } : exFields,
        touched: { ...exTouched, [nameField]: true },
        isValid: isValidForm(schemaErrors),
      }));
    },
    [fields],
  );

  useEffect(() => {
    if (validateOnInit) {
      setValidationParams(getInitValidationParams(initValues));
    }
  }, [JSON.stringify(initValues)]);

  const resetFormFields = useCallback(() => {
    setValidationParams(() => ({
      fields: getResetFields(initValues.fields),
      error: {},
      touched: {},
      isValid: false,
    }));
  }, []);

  return {
    fields,
    error,
    touched,
    isValid,
    resetFormFields,
    autoValidation,
    setValidationParams,
  };
};

export default useValidation;
