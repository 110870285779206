import { css } from '@emotion/react';
import { styled as muiStyled } from '@mui/material/styles';

import BaseIconButton from 'components/ui/atoms/buttons/IconButton/BaseIconButton';
import Typography from 'components/ui/atoms/Typography';

import type { SignatureProps } from './types';

export const StyledIconButton = muiStyled(BaseIconButton)(
  ({ theme: { spacing } }) => css`
    position: absolute;
    top: 0;
    right: 0;
    padding: ${spacing(1)};
  `,
);

export const Wrapper = muiStyled('div')<Pick<SignatureProps, 'width' | 'height'>>(
  ({ theme: { background }, width, height }) => css`
    position: relative;
    background-color: ${background.main};
    width: ${width}px;
    height: ${height}px;
    max-width: 100%;
  `,
);

export const StyledTypography = muiStyled(Typography)(
  ({ theme: { typography, palette } }) => css`
    ${typography.t2}

    &:hover {
      color: ${palette.primary.dark};
    }
  `,
);
