import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { Theme } from '@mui/material/styles';

const getScrollableStyles = ({ spacing, palette }: Theme) => css`
  &::-webkit-scrollbar {
    width: ${spacing(0.5)};
    height: ${spacing(0.5)};
    border-radius: ${spacing(0.75)};
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${spacing(0.75)};
    background: ${palette.scrollThumb};
  }
`;

const Scrollable = styled.div<{ maxHeight?: string; height?: string; vertical?: boolean; horizontal?: boolean }>(
  ({ theme, maxHeight, height, vertical = true, horizontal = false }) => css`
    width: 100%;
    max-height: ${maxHeight || '100%'};
    height: ${height || 'auto'};
    overflow-y: ${vertical ? 'auto' : 'hidden'};
    overflow-x: ${horizontal ? 'auto' : 'hidden'};

    ${getScrollableStyles(theme)}
  `,
);

export default Scrollable;
