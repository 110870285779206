import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

import { StyledText } from './styled';
import type { NumberFormatProps } from './types';

const getText = (value: string) => <StyledText>{value}</StyledText>;

const CustomNumberFormat = forwardRef(({ inputRef = null, renderText, ...other }: NumberFormatProps, ref) => {
  return <NumberFormat {...other} getInputRef={inputRef || ref} renderText={renderText ? getText : undefined} />;
});

export default CustomNumberFormat;
