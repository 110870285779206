import { useMemo, useState } from 'react';

import { DocumentGroup } from 'api/__generated__/enums';
import type { Document } from 'api/__generated__/types';

import DocumentsUploadForm from 'components/shared/organisms/form/DocumentsUploadForm';
import FormFooter, { Alignment } from 'components/shared/organisms/form/FormFooter';
import Grid from 'components/templates/Grid';

import useIntakeFormValues from '../../hooks/useIntakeFormValues';
import { buttonCustomStyles } from '../../styled';
import type { UploadDocumentsFormProps } from './types';
import useSubmitForm from './useSubmitForm';

// TODO: use DocumentsUploadForm with documentName prop
const UploadDocumentsForm = ({ brandedPage }: UploadDocumentsFormProps) => {
  const [uploadedBankStatements, setUploadedBankStatements] = useState({});
  const [uploadedAdditionalDocs, setUploadedAdditionalDocs] = useState({});
  const [loadingFiles, setLoadingFiles] = useState(false);

  const { data } = useIntakeFormValues();
  const { loading, createIntakeFormDocument, updateIntakeForm } = useSubmitForm();

  const submitHandler = async () => {
    await createIntakeFormDocument({ uploadedBankStatements, uploadedAdditionalDocs });
    await updateIntakeForm();
  };

  const { bankStatements, additionalDocuments } = useMemo(() => {
    if (!data) return { bankStatements: null, additionalDocuments: null };
    return data.documents.reduce(
      (reduceResult: Record<'bankStatements' | 'additionalDocuments', Document[]>, document: Document) => {
        if (document.group === DocumentGroup.BankStatement) reduceResult.bankStatements.push(document);
        else reduceResult.additionalDocuments.push(document);

        return reduceResult;
      },
      { bankStatements: [], additionalDocuments: [] },
    );
  }, [data?.documents]);

  return (
    <Grid columns={1}>
      <DocumentsUploadForm
        label="Bank Statements"
        setUploadedDocuments={setUploadedBankStatements}
        setIsDocumentsLoading={setLoadingFiles}
        previousDocuments={bankStatements}
      />
      <DocumentsUploadForm
        label="Additional Documents"
        setUploadedDocuments={setUploadedAdditionalDocs}
        setIsDocumentsLoading={setLoadingFiles}
        previousDocuments={additionalDocuments}
      />
      <FormFooter
        disabled={loadingFiles || loading}
        loading={loading}
        footerAlignment={Alignment.Stretch}
        submitHandler={submitHandler}
        brandedPage={brandedPage}
        buttonText="Submit Data"
        buttonCustomStyles={buttonCustomStyles}
      />
    </Grid>
  );
};

export default UploadDocumentsForm;
