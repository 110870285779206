import type { ApolloError } from '@apollo/client';
import { useMutation as useApolloMutation } from '@apollo/client';
import type {
  MutationHookOptions as BaseMutationHookOptions,
  MutationResult as BaseMutationResult,
} from '@apollo/client/react/types/types';

import { Icons } from 'config/icons/feather';

import useNotifier from 'hooks/useNotifier';

import type { ApolloClientError } from 'decorators/ApolloErrorDecorator';
import ApolloErrorDecorator from 'decorators/ApolloErrorDecorator';

import type { BaseCustomOptions, BaseData, BaseVariables, Mutation, NotifierParams } from './types';

export type MutationHookOptions<Data extends BaseData, Variables extends BaseVariables> = Omit<
  BaseMutationHookOptions<Data, Variables>,
  'onError'
> & {
  onError?: (error: ApolloClientError) => void;
};

export type MutationResult<Data extends BaseData> = BaseMutationResult<Data>;

export type CustomOptions<Data extends BaseData> =
  | (BaseCustomOptions<Data> & { showNotFoundError?: boolean })
  | undefined;

// showGeneralError - Allows you to display general errors in notify
const useMutation = <TData extends BaseData, TVariables extends BaseVariables>(
  mutation: Mutation<TData, TVariables>,
  options: any = {},
  { getCompletedNotify, onCompletedMessage = '', onErrorMessage = '', showGeneralError = true }: any = {},
) => {
  const { setError, setSuccess } = useNotifier();

  const newOptions = {
    ...options,
    onError: (apolloError: ApolloError) => {
      const apolloErrorDecorator = new ApolloErrorDecorator(apolloError);
      apolloErrorDecorator.showNetworkError(setError);
      apolloErrorDecorator.showDevError(setError);

      const errors = apolloErrorDecorator.getErrors();

      if (onErrorMessage) {
        setError(onErrorMessage);
        options.onError?.(errors);
        return;
      }

      if (showGeneralError) {
        apolloErrorDecorator.showGeneralError(setError);
      }

      if (options.onError) {
        options.onError(errors);
      } else {
        throw errors;
      }
    },
    onCompleted: (data: TData) => {
      const {
        message,
        iconType = Icons.Check,
        withoutIcon,
        link,
      } = getCompletedNotify ? getCompletedNotify(data) : ({ message: onCompletedMessage } as NotifierParams);

      if (message) {
        setSuccess(message, { iconType, withoutIcon, link });
      }

      if (options.onCompleted) {
        options.onCompleted(data);
      }
    },
  };

  return useApolloMutation<TData, TVariables>(mutation, newOptions);
};

export default useMutation;
