import type { ChangeEvent } from 'react';

import type { Event, EventValue } from '../types';

const transformToNullable = (value: EventValue): EventValue => {
  return value === null || value === '' ? null : value;
};

export const createEvent = (value: EventValue, name: string): Event => ({
  preventDefault: () => {
    console.error('preventDefault does not exist');
  },
  target: {
    name,
    value,
  },
});

export const getCustomEvent = (
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | Event,
  value: EventValue,
  nullable?: boolean,
): Event => {
  return {
    preventDefault: () => event.preventDefault(),
    target: {
      name: event.target.name,
      value: nullable ? transformToNullable(value) : value,
    },
  };
};

export default getCustomEvent;
