import type { ChangeEvent } from 'react';

import type { Event } from '../types';

const getCustomEvent = (event: ChangeEvent<HTMLInputElement>): Event => ({
  preventDefault: () => event.preventDefault(),
  target: {
    name: event.target.name,
    value:
      event.target.value === 'true' || event.target.value === 'false'
        ? event.target.value === 'true'
        : event.target.value,
  },
});

export default getCustomEvent;
