import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TitlesWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${spacing(4)};
    padding: ${spacing(0, 1)};
  `,
);

export const Subtitle = styled.p(
  ({ theme: { palette } }) => css`
    text-align: center;
    color: ${palette.text.primary};
    max-width: 480px;
  `,
);
