import type { MutationTuple } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

const UpdateIntakeFormLastStepV2Mutation = loader(
  '../../../graphql/mutations/intakeForm/updateIntakeFormLastStepV2.graphql',
);

type Props = {
  onCompleted: (data: any) => void;
  onError: (error: any) => void;
};

const useUpdateIntakeFormLastStepV2Mutation = ({ onCompleted, onError }: Props) => {
  const [updateIntakeFormLastStep, mutationResult] = useMutation(UpdateIntakeFormLastStepV2Mutation, {
    onCompleted,
    onError,
  });

  return [updateIntakeFormLastStep, mutationResult] as MutationTuple<any, any>;
};

export default useUpdateIntakeFormLastStepV2Mutation;
