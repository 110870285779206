import useTypeQueryHook from 'api/hooks/useTypeQueryHook';

import Select from 'components/ui/atoms/forms/Select';

import getModel from './model';
import type { EnumSelectProps } from './types';

const EnumSelect = ({ enumName, disabled, ...props }: EnumSelectProps): JSX.Element => {
  const { data, loading } = useTypeQueryHook({ name: enumName });

  return <Select disabled={disabled} options={loading || !data ? [] : data} getModel={getModel} {...props} />;
};

export default EnumSelect;
