import type { ChangeEvent, ReactElement } from 'react';
import { Children, cloneElement, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import useValidation from 'lib/hooks/validation/useValidation';

import DefaultFormFooter from 'components/shared/organisms/form/FormFooter';

import Loader from '../Loader';
import { Form } from './styled';
import type { FormContainerProps } from './types';

const FormWithValidation = <FormValues extends Record<string, any> = Record<string, any>>({
  id,
  children,
  defaultValues,
  requiredValidationFields,
  submitHandler,
  resetOnSubmit = false,
  loading,
  disabled = false,
  FormFooter = DefaultFormFooter,
}: FormContainerProps<FormValues>) => {
  const [isFormValuesChanged, setIsFormValuesChanged] = useState(false);

  const {
    autoValidation: autoRequiredValidation,
    fields,
    isValid,
    resetFormFields,
    ...formProps
  } = useValidation({ fields: defaultValues }, true);

  const requiredValidationSchema = useMemo(() => Yup.object().shape(requiredValidationFields), [fields]);

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (!isFormValuesChanged) setIsFormValuesChanged(true);
    autoRequiredValidation(requiredValidationSchema, name, value);
  };

  const handleSubmit = async () => {
    try {
      // @ts-expect-error type useValidation and use generic type there
      if (submitHandler) await submitHandler(fields);
      if (resetOnSubmit) resetFormFields();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    autoRequiredValidation(requiredValidationSchema);
  }, [requiredValidationSchema]);

  return (
    <Form id={id}>
      <div>
        {Children.map(children, (child: ReactElement) =>
          cloneElement(child, { ...formProps, validatedFields: fields, handleChange, isFormValuesChanged }),
        )}
      </div>
      <FormFooter loading={loading} submitHandler={handleSubmit} disabled={!isValid || loading || disabled} />
      {loading && <Loader />}
    </Form>
  );
};

export default FormWithValidation;
