import { StyledLink } from './styled';
import type { LinkProps } from './type';

const Link = ({ children, ...props }: LinkProps) => (
  <StyledLink className="link" {...props}>
    {children}
  </StyledLink>
);

export default Link;
