import type { ReactElement } from 'react';
import Tooltip from '@mui/material/Tooltip';

type WrapperProps = {
  title?: string;
  disabled?: boolean;
  children: ReactElement;
};

const IconButtonWrapper = ({ title, disabled, children }: WrapperProps) =>
  !title || disabled ? (
    children
  ) : (
    <Tooltip title={title} placement="top">
      {children}
    </Tooltip>
  );

export default IconButtonWrapper;
