import type { DocumentGroup } from 'api/__generated__/types';
import useTypeQueryHook from 'api/hooks/useTypeQueryHook';
import { DOCUMENT_GROUP_ENUM } from 'graphql/types/_enums';

const useDocumentName = () => {
  const { data: documentGroups } = useTypeQueryHook({ name: DOCUMENT_GROUP_ENUM });

  return (documentGroup: DocumentGroup) => documentGroups?.find(({ name }) => name === documentGroup)?.description;
};

export default useDocumentName;
