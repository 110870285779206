import type { Theme } from '@mui/material/styles';

export const getScrollableMaterialStyles = ({ spacing, palette }: Theme) => ({
  '&::-webkit-scrollbar': {
    width: spacing(0.5),
    height: spacing(0.5),
    borderRadius: spacing(0.75),
    backgroundColor: 'transparent',
  },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: spacing(0.75),
    background: `${palette.scrollThumb}`,
  },
});
