import type { Enums } from 'api/types/common/enum';

import getEnumDescription from 'utils/getEnumDescription';

export const getTermUnitText = (enumData: Enums, termUnit: string) => {
  const termUnitDescription = getEnumDescription(enumData, termUnit);

  if (!termUnitDescription) {
    return {
      singular: '',
      plural: '',
      adverb: '',
    };
  }

  return {
    singular: `${termUnitDescription.slice(0, -1)}`,
    plural: termUnitDescription,
    adverb: `${termUnitDescription.slice(0, -1)}ly`,
  };
};
