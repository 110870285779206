import { FontWeight } from 'types/theme/text';

import TitleLarge from 'components/ui/atoms/text/TitleLarge';

import { Container } from './styled';

type SubmitMessageProps = {
  text?: string;
};

const SubmitMessage = ({ text }: SubmitMessageProps) => (
  <Container>
    <TitleLarge fontWeight={FontWeight.Bold}>{text || 'Thank you for applying!'}</TitleLarge>
  </Container>
);

export default SubmitMessage;
