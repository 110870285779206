import { FUNDING_USAGE } from 'graphql/types/_enums';

import { YUP_NULLABLE_REQUIRED, YUP_REQUIRED_MONEY } from 'config/constants/yup';

import EnumSelect from 'components/ui/atoms/forms/EnumSelect';
import MoneyInput from 'components/ui/atoms/forms/MoneyInput';
import RadioGroup from 'components/ui/atoms/forms/RadioGroup';
import { RadioButtonKinds } from 'components/ui/atoms/forms/RadioGroup/types';
import CaptionSmall from 'components/ui/atoms/text/CaptionSmall';

export enum BasicInformation {
  AmountRequestedCents = 'amountRequestedCents',
  FundingUsage = 'fundingUsage',
  OpenedBusinessLoan = 'openedBusinessLoan',
}

const radioItems = [
  { label: 'No', value: false, name: BasicInformation.OpenedBusinessLoan },
  { label: 'Yes', value: true, name: BasicInformation.OpenedBusinessLoan },
];

const radioCardItems = [
  {
    label: (
      <>
        <p>Revolving Line of Credit</p>
        <CaptionSmall>
          Draw funds repeatedly without reapplying. Repay each draw over a period of up to 12 or 24 weeks.
        </CaptionSmall>
      </>
    ),
    value: 'revolvingLineOfCredit',
    name: 'preferredFinancing',
  },
  {
    label: (
      <>
        <p>One-Time Term Loan</p>
        <CaptionSmall>Draw funds once and repay over up to 24 or 52 weeks.</CaptionSmall>
      </>
    ),
    value: 'oneTimeTermLoan',
    name: 'preferredFinancing',
  },
];

// TODO: name in config should match the field in mutation
// preferred financing values are not sent to API
export const fields = [
  {
    id: 'preferred-financing',
    name: 'preferredFinancing',
    label: 'Select financing you prefer - you can always change your mind later.',
    radioItems: radioCardItems,
    control: <RadioGroup type={RadioButtonKinds.Card} />,
    columns: 1,
    required: false,
  },
  {
    id: 'business-amount-requested-cents',
    name: BasicInformation.AmountRequestedCents,
    label: 'How much are you looking for?',
    placeholder: 'Enter an amount up to $150,000',
    control: <MoneyInput />,
    required: true,
  },
  {
    id: 'funding-usage',
    name: BasicInformation.FundingUsage,
    control: <EnumSelect enumName={FUNDING_USAGE} label="Select" />,
    label: 'What would you use the funds for?',
    required: true,
  },
  {
    id: 'business-opened-business-loan',
    name: BasicInformation.OpenedBusinessLoan,
    radioItems,
    control: <RadioGroup />,
    label: 'Do you have existing short-term financing?',
    required: false,
  },
];

export const DEFAULT_VALUES = {
  [BasicInformation.AmountRequestedCents]: null,
  [BasicInformation.FundingUsage]: '',
  [BasicInformation.OpenedBusinessLoan]: null,
  preferredFinancing: '',
};

export const requiredValidationFields = {
  [BasicInformation.AmountRequestedCents]: YUP_REQUIRED_MONEY,
  [BasicInformation.FundingUsage]: YUP_NULLABLE_REQUIRED,
};
