import {
  AlertCircle as ReactAlertCircle,
  AlertTriangle as ReactAlertTriangle,
  Calendar as ReactCalendar,
  Check as ReactCheck,
  CheckCircle as ReactCheckCircle,
  ChevronDown as ReactChevronDown,
  Copy as ReactCopy,
  File as ReactFile,
  Info as ReactInfo,
  Minus as ReactMinus,
  X as ReactClose,
} from 'react-feather';

export enum Icons {
  AlertCircle = 'alertCircle',
  AlertTriangle = 'alertTriangle',
  Calendar = 'calendar',
  Check = 'check',
  CheckCircle = 'checkCircle',
  ChevronDown = 'chevronDown',
  Copy = 'copy',
  File = 'file',
  Info = 'info',
  X = 'x',
  Minus = 'minus',
}

export default {
  [Icons.AlertCircle]: ReactAlertCircle,
  [Icons.AlertTriangle]: ReactAlertTriangle,
  [Icons.Calendar]: ReactCalendar,
  [Icons.Check]: ReactCheck,
  [Icons.CheckCircle]: ReactCheckCircle,
  [Icons.ChevronDown]: ReactChevronDown,
  [Icons.Copy]: ReactCopy,
  [Icons.File]: ReactFile,
  [Icons.Info]: ReactInfo,
  [Icons.X]: ReactClose,
  [Icons.Minus]: ReactMinus,
};
