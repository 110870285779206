import dayjs from 'dayjs';
import * as Yup from 'yup';

import {
  ADDRESS_LINE_REGEXP,
  ID_REGEXP,
  PHONE_NUMBER_REGEXP,
  SSN_LAST_4_REGEXP,
  SSN_REGEXP,
  TAX_ID_REGEXP,
} from 'config/regexp/regexp';

const REQUIRED_FIELD_TEXT = 'This field is required';

const NUMBER_FIELD = 'Must be a number';
const NUMBER_MIN = 'Value must be more than ';
const NUMBER_INTEGER = 'Value must be an integer ';

export const YUP_STRING = Yup.string();
export const YUP_MIXED = Yup.mixed();
export const YUP_MULTI_SELECT_REQUIRED = Yup.array().required(REQUIRED_FIELD_TEXT).min(1, REQUIRED_FIELD_TEXT);
export const YUP_REQUIRED = YUP_STRING.required(REQUIRED_FIELD_TEXT);
export const YUP_NULLABLE_REQUIRED = YUP_REQUIRED.nullable();
export const YUP_ALLOW_NULLABLE = YUP_STRING.nullable();
export const YUP_NUMBER = Yup.number().typeError(NUMBER_FIELD);
export const YUP_VALID_POSITIVE_NUMBER = YUP_NUMBER.min(0, ({ min }) => NUMBER_MIN + min).nullable();
export const YUP_VALID_INTEGER = YUP_VALID_POSITIVE_NUMBER.integer(NUMBER_INTEGER).nullable();
export const YUP_REQUIRED_POSITIVE_NUMBER = YUP_NUMBER.required(REQUIRED_FIELD_TEXT).min(
  0,
  ({ min }) => NUMBER_MIN + min,
);
export const YUP_REQUIRED_INTEGER = YUP_REQUIRED_POSITIVE_NUMBER.integer(NUMBER_INTEGER);

/* Email */
const EMAIL_INVALID_TEXT = 'The email must be valid';
export const YUP_VALID_EMAIL = YUP_ALLOW_NULLABLE.email(EMAIL_INVALID_TEXT);
export const YUP_NULLABLE_REQUIRED_VALID_EMAIL = YUP_NULLABLE_REQUIRED.email(EMAIL_INVALID_TEXT);
export const YUP_REQUIRED_VALID_EMAIL = YUP_REQUIRED.email(EMAIL_INVALID_TEXT);

/* Money */
export const YUP_REQUIRED_MONEY = Yup.number().nullable().required(REQUIRED_FIELD_TEXT);

/* Phone number */
const PHONE_NUMBER_LENGTH = 'Phone number should be 10 digits';
export const YUP_VALID_PHONE_NUMBER = YUP_ALLOW_NULLABLE.matches(PHONE_NUMBER_REGEXP, PHONE_NUMBER_LENGTH);
export const YUP_REQUIRED_PHONE_NUMBER = YUP_NULLABLE_REQUIRED.matches(PHONE_NUMBER_REGEXP, PHONE_NUMBER_LENGTH);

/* SSN */
const SSN_LAST_4_LENGTH = 'Enter the last four digits of SSN';
const SSN_LAST_MATCHES = 'SSN should contain digits only';
export const YUP_REQUIRED_SSN_LAST_4 = YUP_NULLABLE_REQUIRED.length(4, SSN_LAST_4_LENGTH).matches(
  SSN_LAST_4_REGEXP,
  SSN_LAST_MATCHES,
);

/* Date */
const DATE_ERROR = 'Invalid Date';
const DATE_PAST = 'This date must be earlier than the current date';
export const YUP_DATE_REQUIRED = Yup.date().required(REQUIRED_FIELD_TEXT).nullable().typeError(DATE_ERROR);
export const YUP_DATE_REQUIRED_PAST = YUP_DATE_REQUIRED.max(dayjs(), DATE_PAST);

/* Identification numbers */
const ID_LENGTH = 'Identification Number should be 9 digits';
const ID_MATCHES = 'Identification Number should contain digits only';
export const YUP_VALID_ID = YUP_NULLABLE_REQUIRED.length(9, ID_LENGTH).matches(ID_REGEXP, ID_MATCHES);

/* Tax Id */
const TAX_ID_LENGTH = 'Tax ID should be 9 digits';
const TAX_ID_MATCHES = 'Tax ID should contain digits only';
export const YUP_REQUIRED_TAX_ID = YUP_NULLABLE_REQUIRED.length(9, TAX_ID_LENGTH).matches(
  TAX_ID_REGEXP,
  TAX_ID_MATCHES,
);
export const YUP_VALID_TAX_ID = YUP_ALLOW_NULLABLE.length(9, TAX_ID_LENGTH).matches(TAX_ID_REGEXP, TAX_ID_MATCHES);

/* SSN */
const SSN_LENGTH = 'SSN should be 9 characters';
export const YUP_VALID_SSN = YUP_ALLOW_NULLABLE.matches(SSN_REGEXP, SSN_LENGTH);
export const YUP_REQUIRED_SSN = YUP_NULLABLE_REQUIRED.matches(SSN_REGEXP, SSN_LENGTH);

/* Address Line */
const ADDRESS_LINE_INVALID_TEXT = 'Sorry, we can not accept PO Boxes as a valid address';
export const YUP_REQUIRED_ADDRESS_LINE = YUP_NULLABLE_REQUIRED.matches(ADDRESS_LINE_REGEXP, ADDRESS_LINE_INVALID_TEXT);
export const YUP_VALID_ADDRESS_LINE = YUP_ALLOW_NULLABLE.matches(
  ADDRESS_LINE_REGEXP,
  ADDRESS_LINE_INVALID_TEXT,
).transform((_, val) => (val === '' ? null : val));
