import { useState } from 'react';

import type { UserAgreementTextProps } from 'components/shared/molecules/UserAgreementText';
import UserAgreementText from 'components/shared/molecules/UserAgreementText';
import Button from 'components/ui/atoms/buttons/Button';

import { ButtonBlock } from './styled';
import type { FormFooterProps } from './types';

const FormFooter = ({
  disabled,
  loading,
  submitHandler,
  buttonConfirmationText,
  buttonText,
  footerAlignment,
  brandedPage,
  buttonCustomStyles,
}: FormFooterProps) => {
  const [isAgreeWithPolicy, setIsAgreeWithPolicy] = useState(true);

  const handleAgree: UserAgreementTextProps['handleAgree'] = ({ currentTarget }) =>
    setIsAgreeWithPolicy(currentTarget.checked);

  return (
    <ButtonBlock footerAlignment={footerAlignment} buttonCustomStyles={buttonCustomStyles}>
      <UserAgreementText
        isAgreeWithPolicy={isAgreeWithPolicy}
        handleAgree={handleAgree}
        label={buttonConfirmationText}
        isBranded={brandedPage}
      />

      <Button
        variant="contained"
        color="primary"
        disableRipple
        disableElevation
        disabled={disabled || !isAgreeWithPolicy}
        loading={loading}
        onClick={submitHandler}
      >
        {buttonText || 'Submit Application'}
      </Button>
    </ButtonBlock>
  );
};

export default FormFooter;
