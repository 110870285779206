import { StyledTitle } from './styled';
import type { PageTitleProps } from './types';

const PageTitle = ({ children, dataTestId, ...otherProps }: PageTitleProps) => (
  <StyledTitle data-testid={dataTestId} {...otherProps}>
    {children}
  </StyledTitle>
);

export default PageTitle;
