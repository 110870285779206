import { SubTitle, Title as StyledTitle, Wrapper } from './styled';

const Title = (): JSX.Element => {
  return (
    <Wrapper>
      <StyledTitle>
        Welcome! <br />
        Let’s get started.
      </StyledTitle>
      <SubTitle>
        Review business credit line. <br />
        Find out how much you qualify for, hassle free.
      </SubTitle>
    </Wrapper>
  );
};

export default Title;
