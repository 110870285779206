import type { MutationTuple } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

const createERCFormDraftV2Mutation = loader('../../../graphql/mutations/erc/createERCFormDraftV2.graphql');

type Props = {
  onCompleted: (data: any) => void;
  onError: (error: any) => void;
};
// TODO type props, type return result
const useCreateERCFormDraftMutation = ({ onCompleted, onError }: Props) => {
  const [createERCFormDraft, mutationResult] = useMutation(createERCFormDraftV2Mutation, {
    onCompleted: (data) => onCompleted(data?.createErcFormV2 ?? {}),
    onError: (error) => onError(error),
  });

  return [createERCFormDraft, mutationResult] as MutationTuple<any, any>;
};

export default useCreateERCFormDraftMutation;
