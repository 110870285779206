import type { MutationTuple } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

const CreateIntakeFormDocumentV2Mutation = loader(
  '../../../graphql/mutations/intakeForm/createIntakeFormDocumentV2.graphql',
);

type Props = {
  onCompleted: (data: any) => void;
  onError: (error: any) => void;
};

const useCreateIntakeFormDocumentV2Mutation = ({ onCompleted, onError }: Props) => {
  const [createIntakeForm, mutationResult] = useMutation(CreateIntakeFormDocumentV2Mutation, { onCompleted, onError });

  return [createIntakeForm, mutationResult] as MutationTuple<any, any>;
};

export default useCreateIntakeFormDocumentV2Mutation;
