/* eslint-disable no-console */
import { useEffect } from 'react';
import type {
  ApolloError,
  QueryHookOptions as BaseQueryHookOptions,
  QueryResult as BaseQueryResult,
} from '@apollo/client';
import { useQuery as useApolloQuery } from '@apollo/client';

import { Icons } from 'config/icons/feather';
import { DEV } from 'config/vars';

import useNotifier from 'hooks/useNotifier';

import ApolloErrorDecorator from 'decorators/ApolloErrorDecorator';
import type { ApolloClientError } from 'decorators/ApolloErrorDecorator/ApolloClientError';

import type { BaseCustomOptions, BaseData, BaseVariables, NotifierParams, Query } from './types';

export type CustomOptions<Data extends BaseData | null | undefined> =
  | (BaseCustomOptions<Data> & { showNotFoundError?: boolean })
  | undefined;

export type QueryHookOptions<Data extends BaseData, Variables extends BaseVariables> = Omit<
  BaseQueryHookOptions<Data, Variables>,
  'onError'
> & {
  onError?: (error: ApolloClientError) => void;
};

export type QueryResult<Data extends BaseData, Variables extends BaseVariables> = BaseQueryResult<Data, Variables>;

const useQuery = <Data extends BaseData, Variables extends BaseVariables>(
  query: Query<Data, Variables>,
  { pollInterval = undefined, ...options }: QueryHookOptions<Data, Variables> = {},
  {
    getCompletedNotify,
    onCompletedMessage,
    onErrorMessage,
    showGeneralError = true,
    showNotFoundError = true,
  }: CustomOptions<Data> = {},
): QueryResult<Data, Variables> => {
  const { setError, setSuccess } = useNotifier();

  const newOptions = {
    pollInterval,
    ...options,
    onError: (apolloError: ApolloError) => {
      const apolloErrorDecorator = new ApolloErrorDecorator(apolloError);
      apolloErrorDecorator.showNetworkError(setError);
      apolloErrorDecorator.showDevError(setError);

      const errors = apolloErrorDecorator.getErrors();

      if (onErrorMessage) {
        setError(onErrorMessage);
        options.onError?.(errors);
        return;
      }

      if (showGeneralError) {
        const notFoundError = errors.errors.find(({ code }) => code === 'not_found'); // Add error list in the next pr

        if (showNotFoundError || !notFoundError) {
          apolloErrorDecorator.showGeneralError(setError);
        }
      }

      if (options.onError) {
        options.onError(errors);
      }
    },
    onCompleted: (data: Data) => {
      const {
        message,
        iconType = Icons.Check,
        withoutIcon,
        link,
      } = getCompletedNotify ? getCompletedNotify(data) : ({ message: onCompletedMessage } as NotifierParams);

      if (message) {
        setSuccess(message, { iconType, withoutIcon, link });
      }

      if (options.onCompleted) {
        options.onCompleted(data);
      }
    },
  };

  const result = useApolloQuery(query, newOptions);
  const { startPolling, stopPolling } = result ?? {};

  const startQuery = () => {
    if (DEV) console.log('START QUERY');

    if (pollInterval) {
      startPolling(pollInterval);
    }
  };
  const stopQuery = () => {
    if (DEV) console.log('STOP QUERY');
    stopPolling();
  };

  useEffect(() => {
    if (pollInterval) {
      window.addEventListener('online', startQuery);
      window.addEventListener('offline', stopQuery);
    }
    return () => {
      if (pollInterval) {
        window.removeEventListener('online', startQuery);
        window.removeEventListener('offline', stopQuery);
      }
    };
  }, [startPolling, stopPolling]);

  return result;
};

export default useQuery;
