import styled from '@emotion/styled';

export const StyledList = styled.ul`
  li,
  a {
    padding: 0.5rem 0;
  }

  a:hover {
    text-decoration: underline;
  }
`;
