import type { Property } from 'csstype';

type ColorKeys =
  | 'blue'
  | 'blueLight'
  | 'blueLighter'
  | 'blueDarker'
  | 'blueExtraLighter'
  | 'gray'
  | 'grayLighter'
  | 'grayLight'
  | 'grayBlue'
  | 'white'
  | 'whiteDark'
  | 'whiteGrey'
  | 'green'
  | 'greenLight'
  | 'greenDown'
  | 'sapphire'
  | 'sapphireDark'
  | 'sapphireLight'
  | 'red'
  | 'redDark'
  | 'redLight'
  | 'redBright'
  | 'orange'
  | 'orangeLight'
  | 'black'
  | 'blackMiddle'
  | 'blackLight'
  | 'blackBlue';

export type Colors = { [key in ColorKeys]: Property.Color };

const configColors: Colors = {
  // white
  white: 'rgba(255, 255, 255, 1)', // #FFFFFF
  whiteDark: 'rgba(246, 248, 251, 1)', // #F6F8FB
  whiteGrey: 'rgba(241, 243, 244, 1)', // #F1F3F4
  // black
  black: 'rgba(0, 0, 0, 1)', // #000000
  blackMiddle: 'rgba(9, 16, 29, 1)', // #09101D
  blackLight: 'rgba(37, 40, 43, 1)', // #25282B
  blackBlue: 'rgba(28, 42, 70, 1)', // #1C2A46
  // gray
  grayLighter: 'rgba(13, 27, 75, 0.04)', // #0D1B4B0A
  grayLight: 'rgba(217, 221, 226, 1)', // #D9DDE2
  gray: 'rgba(160, 164, 168, 1)', // #A0A4A8 #BCBCBC
  grayBlue: 'rgba(123, 144, 172, 1)', // #7B90AC
  // blue
  blueLight: 'rgba(225, 232, 241, 1)', // #E1E8F1
  blue: 'rgba(51, 108, 251, 1)', // #336CFB
  blueLighter: 'rgba(222, 235, 255, 1)', // #DEEBFF
  blueDarker: 'rgba(31, 91, 244, 1)', // #1F5BF4
  blueExtraLighter: 'rgba(240, 245, 255, 1)', // #F0F5FF

  sapphire: 'rgba(13, 27, 75, 1)', // #0D1B4B
  sapphireDark: 'rgba(13, 29, 82, 1)', // #0D1D52
  sapphireLight: 'rgba(28, 47, 116, 1)', // #1C2F74
  // red
  red: 'rgba(233, 50, 50, 1)', // #E93232
  redDark: 'rgba(237, 28, 46, 1)', // #ED1C2E
  redBright: 'rgba(220, 38, 37, 1)', // #DC2625
  redLight: 'rgba(255, 224, 223, 1)', // #FFE0DF
  // orange
  orange: 'rgba(255, 180, 0, 1)', // #FFB400
  orangeLight: 'rgba(255, 240, 222, 1)', // #FFF0DE
  // green
  greenLight: 'rgba(221, 248, 201, 1)', // #DDF8C9
  green: 'rgba(146, 207, 47, 1)', // #92CF2F
  greenDown: 'rgba(103, 187, 37, 1)', // #67BB25
};

export default configColors;
