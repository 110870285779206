import { StyledTitle } from './styled';
import type { TitleMediumProps } from './types';

const TitleMedium = ({ children, disabled, textCase, marginBottom, fontWeight, dataTestId }: TitleMediumProps) => (
  <StyledTitle
    disabled={disabled}
    textCase={textCase}
    marginBottom={marginBottom}
    fontWeight={fontWeight}
    data-testid={dataTestId}
  >
    {children}
  </StyledTitle>
);

export default TitleMedium;
