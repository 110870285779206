import { useState } from 'react';

import type { FormField, FormSectionProps } from 'components/shared/organisms/form/FormSection/types';

const getFilteredFormFields = (allFields: FormField[], hiddenFields: Record<string, any | never>) => {
  return allFields.reduce<FormField[]>((result, { name, isDefaultVisible, dependentFields: _, ...rest }) => {
    const isFieldVisible = isDefaultVisible || hiddenFields?.[name];

    if (isFieldVisible) {
      result.push({
        name,
        ...rest,
      });
    }
    return result;
  }, []);
};

const getHiddenFields = (dependentFieldNames: string[], value: any) => {
  return dependentFieldNames.reduce<Record<string, any>>((reduceResult, dependentFieldName) => {
    reduceResult[dependentFieldName] = value;
    return reduceResult;
  }, {});
};

const useFormHiddenFields = (formFields: FormField[], handleChange?: FormSectionProps['handleChange']) => {
  const [hiddenFieldsStatus, setHiddenFieldsStatus] = useState({});

  const changeHandler: FormSectionProps['handleChange'] = (event) => {
    const { dependentFields } = formFields.find((field) => field.name === event.target.name) ?? {};

    if (dependentFields?.length) {
      dependentFields.forEach(({ dependentFieldNames, parentOptionValue }) => {
        setHiddenFieldsStatus((prevState) => ({
          ...prevState,
          ...getHiddenFields(dependentFieldNames, event.target.value === (parentOptionValue || 'true')),
        }));
      });
    }
    if (handleChange) handleChange(event);
  };

  const visibleFormFields = getFilteredFormFields(formFields, hiddenFieldsStatus);

  return { changeHandler, visibleFormFields };
};

export default useFormHiddenFields;
