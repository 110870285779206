import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const FundingStepsWrapper = styled.div(
  ({
    theme: {
      spacing,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: ${spacing(12, 0, 30)};
    text-align: center;

    ${down(breakpoints.md)} {
      margin: ${spacing(12, 0, 20)};
    }
  `,
);

// TODO: fix styles for wrapper
export const PointsWrapper = styled.div(
  ({
    theme: {
      spacing,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: ${spacing(7.5, 5, 0)};
    padding: ${spacing(0, 10)};
    gap: ${spacing(5)};

    ${down(breakpoints.lg)} {
      padding: ${spacing(0, 5)};
    }

    ${down(breakpoints.md)} {
      flex-direction: column;
      padding: ${spacing(0, 3)};
    }
  `,
);

export const Point = styled.div(
  ({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: calc(${100 / 3}% - ${spacing(6)});
    margin-bottom: ${spacing(9.5)};
  `,
);

// TODO: add pointText when it will be ready
// export const PointText = styled.p`
//   width: 80%;
//   margin: 0;
//   text-align: center;
//   font-size: 14px;
//   font-weight: 600;
// `;

// TODO: add Subtitle when it will be ready (after Title)
// export const SubTitle = styled.p`
//   margin: 0;
// `;
