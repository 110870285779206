import CircularProgress from '@mui/material/CircularProgress';

import { StyledButton } from './styled';
import type { ButtonProps } from './types';

const BaseButton = ({
  disableRipple = true,
  variant = 'text',
  color = 'primary',
  loading,
  children,
  ...other
}: ButtonProps) => {
  return (
    <StyledButton disableRipple={disableRipple} variant={variant} color={color} {...other}>
      {loading ? <CircularProgress color="inherit" size={20} thickness={5} /> : children}
    </StyledButton>
  );
};

export default BaseButton;
