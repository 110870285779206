import { useState } from 'react';

import type { FormSectionConfig } from 'components/shared/molecules/FormSectionMediator/types';
import type { FormSectionProps } from 'components/shared/organisms/form/FormSection/types';

const getFilteredFormSections = (allSections: FormSectionConfig[], hiddenSections: Record<string, any | never>) => {
  return allSections.reduce<Omit<FormSectionConfig, 'isDefaultVisible'>[]>(
    (result, { id, isDefaultVisible, ...rest }) => {
      const isSectionVisible = isDefaultVisible || hiddenSections?.[id];

      if (isSectionVisible) {
        result.push({
          id,
          ...rest,
        });
      }
      return result;
    },
    [],
  );
};

const useFormHiddenSections = (formSections: FormSectionConfig[]) => {
  const [hiddenSectionsStatus, setHiddenSectionsStatus] = useState({});

  const formSectionFields = formSections.flatMap(({ formFields }) => formFields);

  const changeHandler: FormSectionProps['handleChange'] = (event) => {
    const { dependentSections } = formSectionFields.find((field) => field.name === event.target.name) ?? {};

    if (dependentSections?.length) {
      dependentSections.forEach(({ dependentSectionId, parentOptionValue }) => {
        setHiddenSectionsStatus((prevState) => ({
          ...prevState,
          [dependentSectionId]: event.target.value === (parentOptionValue || 'true'),
        }));
      });
    }
  };

  const visibleFormSections = getFilteredFormSections(formSections, hiddenSectionsStatus);

  return { handleDependentFormSection: changeHandler, visibleFormSections };
};

export default useFormHiddenSections;
