import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div(
  ({
    theme: {
      palette,
      spacing,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    color: ${palette.primary.contrastText};

    ${down(breakpoints.md)} {
      width: 100%;
      padding: ${spacing(2, 3)};
    }
  `,
);

export const Title = styled.h1(
  ({
    theme: {
      spacing,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    margin: ${spacing(0, 0, 2)};
    font-size: 52px;

    ${down(breakpoints.md)} {
      font-size: 24px;
    }
  `,
);

export const SubTitle = styled.p`
  margin: 0;
  font-size: 18px;
`;
