import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import useERCFormQuery from 'api/query/useERCFormQuery';

import { UUID } from 'config/constants/queryParams';

import { DEFAULT_VALUES } from '../config/config';

const useERCFormValues = () => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get(UUID);

  const { data: ercForm, loading } = useERCFormQuery({
    variables: {
      // @ts-expect-error uuid can be null, so we skip in this case
      uuid,
    },
    skip: !uuid,
  });

  const formValues = useMemo(() => {
    return Object.entries(DEFAULT_VALUES).reduce<Record<string, any>>((reduceResult, [key, value]) => {
      reduceResult[key] = ercForm?.[key as keyof typeof ercForm] || value;

      return reduceResult;
    }, {});
  }, [ercForm]);

  return {
    data: formValues,
    loading,
  };
};

export default useERCFormValues;
