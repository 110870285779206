import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { transparentize } from 'polished';

export const Wrapper = styled.div<any>(
  ({ customStyles, theme, theme: { palette } }) => css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
    background-color: ${transparentize(0.4, palette.common.white)};
    opacity: 0.7;
    z-index: 100;
    ${customStyles && customStyles(theme)}
  `,
);
