export enum ApplicationFormStep {
  BasicDocuments = 'BASIC_DOCUMENTS',
  BusinessDetails = 'BUSINESS_DETAILS',
  ContactInformation = 'CONTACT_INFORMATION',
  Signature = 'SIGNATURE',
}

export enum AutocompleteSearchField {
  BusinessEmail = 'BUSINESS_EMAIL',
  BusinessFederalTaxId = 'BUSINESS_FEDERAL_TAX_ID',
  BusinessFormattedFederalTaxId = 'BUSINESS_FORMATTED_FEDERAL_TAX_ID',
  BusinessName = 'BUSINESS_NAME',
  BusinessPhone = 'BUSINESS_PHONE',
  Email = 'EMAIL',
  FullName = 'FULL_NAME',
  MerchantCellPhone = 'MERCHANT_CELL_PHONE',
  MerchantEmail = 'MERCHANT_EMAIL',
  MerchantFormattedSsn = 'MERCHANT_FORMATTED_SSN',
  MerchantFullName = 'MERCHANT_FULL_NAME',
  MerchantSsn = 'MERCHANT_SSN',
  OwnerEmail = 'OWNER_EMAIL',
  OwnerName = 'OWNER_NAME',
}

export enum AutocompleteSearchKind {
  ClosedLost = 'CLOSED_LOST',
  Lead = 'LEAD',
  Opportunity = 'OPPORTUNITY',
  User = 'USER',
}

export enum BulkActionKind {
  AddReminders = 'ADD_REMINDERS',
  ApproveDrawRequests = 'APPROVE_DRAW_REQUESTS',
  ArchiveBusinessProducts = 'ARCHIVE_BUSINESS_PRODUCTS',
  CloseLostBusinessProducts = 'CLOSE_LOST_BUSINESS_PRODUCTS',
  PayDrawRequests = 'PAY_DRAW_REQUESTS',
  ReassignBusinessProducts = 'REASSIGN_BUSINESS_PRODUCTS',
  ReassignBusinessProductsList = 'REASSIGN_BUSINESS_PRODUCTS_LIST',
  RejectDrawRequests = 'REJECT_DRAW_REQUESTS',
  RemoveBusinessProductsExclusivity = 'REMOVE_BUSINESS_PRODUCTS_EXCLUSIVITY',
  RequestOffers = 'REQUEST_OFFERS',
  SendNotifications = 'SEND_NOTIFICATIONS',
  ShareBusinessProducts = 'SHARE_BUSINESS_PRODUCTS',
  UpdateBusinessProducts = 'UPDATE_BUSINESS_PRODUCTS',
  UpdateBusinessProductsExclusivity = 'UPDATE_BUSINESS_PRODUCTS_EXCLUSIVITY',
}

export enum BusinessDealHistory {
  ExistingNew = 'EXISTING_NEW',
  New = 'NEW',
  Renewal = 'RENEWAL',
}

export enum BusinessIndustry {
  AccountingFirms = 'ACCOUNTING_FIRMS',
  AgricultureForestryFishingHunting = 'AGRICULTURE_FORESTRY_FISHING_HUNTING',
  ArchitecturalEngineering = 'ARCHITECTURAL_ENGINEERING',
  ArtsEntertainmentRecreation = 'ARTS_ENTERTAINMENT_RECREATION',
  AutoSales = 'AUTO_SALES',
  AutoServicing = 'AUTO_SERVICING',
  AutoTowing = 'AUTO_TOWING',
  BeautyWellness = 'BEAUTY_WELLNESS',
  Biotechnology = 'BIOTECHNOLOGY',
  CommunicationsMedia = 'COMMUNICATIONS_MEDIA',
  ConstructionContracting = 'CONSTRUCTION_CONTRACTING',
  EducationalServices = 'EDUCATIONAL_SERVICES',
  EquipmentSales = 'EQUIPMENT_SALES',
  FinancialServices = 'FINANCIAL_SERVICES',
  FoodBeverage = 'FOOD_BEVERAGE',
  FreightLogistics = 'FREIGHT_LOGISTICS',
  GeneralBusinessServices = 'GENERAL_BUSINESS_SERVICES',
  GreenServicesTechnology = 'GREEN_SERVICES_TECHNOLOGY',
  HealthCareServices = 'HEALTH_CARE_SERVICES',
  HomeMaintenanceRepair = 'HOME_MAINTENANCE_REPAIR',
  Insurance = 'INSURANCE',
  Landscaping = 'LANDSCAPING',
  LegalServices = 'LEGAL_SERVICES',
  LeisureHospitality = 'LEISURE_HOSPITALITY',
  Manufacturing = 'MANUFACTURING',
  Maritime = 'MARITIME',
  Nonprofit = 'NONPROFIT',
  Other = 'OTHER',
  RealEstatePropertyManagement = 'REAL_ESTATE_PROPERTY_MANAGEMENT',
  Retail = 'RETAIL',
  RetailOnline = 'RETAIL_ONLINE',
  SafetySecurityLawEnforcement = 'SAFETY_SECURITY_LAW_ENFORCEMENT',
  TechnologySoftware = 'TECHNOLOGY_SOFTWARE',
  Transportation = 'TRANSPORTATION',
  TransportationServices = 'TRANSPORTATION_SERVICES',
  Utilities = 'UTILITIES',
  WholesaleTrade = 'WHOLESALE_TRADE',
}

export enum BusinessLocationOwnership {
  Mortgage = 'MORTGAGE',
  Rented = 'RENTED',
}

export enum BusinessProductConvertationStatus {
  ClosedLost = 'CLOSED_LOST',
  Lead = 'LEAD',
  Opportunity = 'OPPORTUNITY',
}

export enum BusinessProductDealHistory {
  ExistingNew = 'EXISTING_NEW',
  New = 'NEW',
  Renewal = 'RENEWAL',
}

export enum BusinessProductLabelColor {
  Blue = 'BLUE',
  Gray = 'GRAY',
  Green = 'GREEN',
  Red = 'RED',
  Yellow = 'YELLOW',
}

export enum BusinessProductLabelKind {
  ExclusivityMatch = 'EXCLUSIVITY_MATCH',
}

export enum BusinessProductObjection {
  DoesNotNeedMoneyNow = 'DOES_NOT_NEED_MONEY_NOW',
  DoesNotWantTalk = 'DOES_NOT_WANT_TALK',
  HasBadCredit = 'HAS_BAD_CREDIT',
  NotEnoughMoney = 'NOT_ENOUGH_MONEY',
  NotFastEnough = 'NOT_FAST_ENOUGH',
  RatesTooHigh = 'RATES_TOO_HIGH',
  WrongPerson = 'WRONG_PERSON',
}

export enum BusinessProductStatus {
  AppSent = 'APP_SENT',
  Backburner = 'BACKBURNER',
  DisconnectedNumber = 'DISCONNECTED_NUMBER',
  DoesNotQualify = 'DOES_NOT_QUALIFY',
  DoNotCall = 'DO_NOT_CALL',
  FollowUp = 'FOLLOW_UP',
  HotLead = 'HOT_LEAD',
  NewLead = 'NEW_LEAD',
  NotInterested = 'NOT_INTERESTED',
  NotInBusiness = 'NOT_IN_BUSINESS',
  Voicemail = 'VOICEMAIL',
  WrongNumber = 'WRONG_NUMBER',
}

export enum BusinessStatus {
  DoNotCall = 'DO_NOT_CALL',
}

export enum ClosedLostSearchableField {
  BusinessEmail = 'BUSINESS_EMAIL',
  BusinessFederalTaxId = 'BUSINESS_FEDERAL_TAX_ID',
  BusinessFormattedFederalTaxId = 'BUSINESS_FORMATTED_FEDERAL_TAX_ID',
  BusinessName = 'BUSINESS_NAME',
  BusinessPhone = 'BUSINESS_PHONE',
  MerchantCellPhone = 'MERCHANT_CELL_PHONE',
  MerchantEmail = 'MERCHANT_EMAIL',
  MerchantFormattedSsn = 'MERCHANT_FORMATTED_SSN',
  MerchantFullName = 'MERCHANT_FULL_NAME',
  MerchantSsn = 'MERCHANT_SSN',
  OwnerEmail = 'OWNER_EMAIL',
  OwnerName = 'OWNER_NAME',
}

export enum CommissionTier {
  Iso = 'ISO',
  Leadership = 'LEADERSHIP',
  Miscellaneous = 'MISCELLANEOUS',
  Originator = 'ORIGINATOR',
  Other = 'OTHER',
  PaymentMethod = 'PAYMENT_METHOD',
  Supporting = 'SUPPORTING',
  TakenOutOfPsf = 'TAKEN_OUT_OF_PSF',
}

export enum CommissionTierInputOption {
  Leadership = 'LEADERSHIP',
  Miscellaneous = 'MISCELLANEOUS',
  Originator = 'ORIGINATOR',
  Other = 'OTHER',
  Supporting = 'SUPPORTING',
}

export enum ContactConvertationStatus {
  ClosedLost = 'CLOSED_LOST',
  Lead = 'LEAD',
  Opportunity = 'OPPORTUNITY',
}

export enum ContactOpportunityCurrentStage {
  CompleteSubmission = 'COMPLETE_SUBMISSION',
  MerchantFunded = 'MERCHANT_FUNDED',
  OfferReceived = 'OFFER_RECEIVED',
  PreApplication = 'PRE_APPLICATION',
  SeekingOffers = 'SEEKING_OFFERS',
}

export enum ContactProduct {
  Default = 'DEFAULT',
  Erc = 'ERC',
}

export enum ContactRole {
  Merchant = 'MERCHANT',
  Partner = 'PARTNER',
}

export enum ContactStatus {
  AppSent = 'APP_SENT',
  Backburner = 'BACKBURNER',
  Converted = 'CONVERTED',
  DoNotCall = 'DO_NOT_CALL',
  FollowUp = 'FOLLOW_UP',
  HotLead = 'HOT_LEAD',
  NewLead = 'NEW_LEAD',
  NotInterested = 'NOT_INTERESTED',
  NotInBusiness = 'NOT_IN_BUSINESS',
  NoAnswer = 'NO_ANSWER',
  WrongNumber = 'WRONG_NUMBER',
}

export enum DncRecordKind {
  Email = 'EMAIL',
  Phone = 'PHONE',
}

export enum DncRecordRestrictionLevel {
  Regular = 'REGULAR',
  Strict = 'STRICT',
}

export enum DncRecordSource {
  Manual = 'MANUAL',
  Tcpa = 'TCPA',
  Upload = 'UPLOAD',
}

export enum DncSpreadsheetStatus {
  Created = 'CREATED',
  Imported = 'IMPORTED',
  Importing = 'IMPORTING',
  Malformed = 'MALFORMED',
}

export enum DealHistory {
  ExistingNew = 'EXISTING_NEW',
  New = 'NEW',
  Renewal = 'RENEWAL',
}

export enum DialerCallStatus {
  Busy = 'BUSY',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Initiated = 'INITIATED',
  InProgress = 'IN_PROGRESS',
  NoAnswer = 'NO_ANSWER',
  Queued = 'QUEUED',
  Requested = 'REQUESTED',
  Ringing = 'RINGING',
}

export enum DistributionSettingPayoutTerm {
  Advance = 'ADVANCE',
  FirstPayment = 'FIRST_PAYMENT',
  PaidInFull = 'PAID_IN_FULL',
  Regular = 'REGULAR',
}

export enum DocumentGroup {
  ApplicationPdf = 'APPLICATION_PDF',
  ApplicationPdfRedacted = 'APPLICATION_PDF_REDACTED',
  BalanceSheet = 'BALANCE_SHEET',
  BankStatement = 'BANK_STATEMENT',
  Coj = 'COJ',
  Contracts = 'CONTRACTS',
  CreditCardProcessingStatements = 'CREDIT_CARD_PROCESSING_STATEMENTS',
  DebtSchedule = 'DEBT_SCHEDULE',
  DocumentsForSigning = 'DOCUMENTS_FOR_SIGNING',
  DriversLicense = 'DRIVERS_LICENSE',
  EinLetter = 'EIN_LETTER',
  LeaseAgreement = 'LEASE_AGREEMENT',
  MonthToDate = 'MONTH_TO_DATE',
  MostRecentTaxReturn = 'MOST_RECENT_TAX_RETURN',
  OriginalApplication = 'ORIGINAL_APPLICATION',
  Other = 'OTHER',
  Passport = 'PASSPORT',
  ProfitAndLoss = 'PROFIT_AND_LOSS',
  ProofOfOwnership = 'PROOF_OF_OWNERSHIP',
  Psf = 'PSF',
  RealEstateSchedule = 'REAL_ESTATE_SCHEDULE',
  RedactedApplication = 'REDACTED_APPLICATION',
  RentCheck = 'RENT_CHECK',
  VoidedCheck = 'VOIDED_CHECK',
}

export enum DocumentStatus {
  Approved = 'APPROVED',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Draft = 'DRAFT',
  ExternalReview = 'EXTERNAL_REVIEW',
  New = 'NEW',
  Paid = 'PAID',
  Rejected = 'REJECTED',
  Resended = 'RESENDED',
  Sent = 'SENT',
  Uploaded = 'UPLOADED',
  Verified = 'VERIFIED',
  Viewed = 'VIEWED',
  Voided = 'VOIDED',
  WaitingApproval = 'WAITING_APPROVAL',
  WaitingForInfo = 'WAITING_FOR_INFO',
  WaitingForUpload = 'WAITING_FOR_UPLOAD',
  WaitingPay = 'WAITING_PAY',
}

export enum DrawRequestStatus {
  Approved = 'APPROVED',
  Paid = 'PAID',
  Rejected = 'REJECTED',
  Waiting = 'WAITING',
}

export enum EmailKind {
  Additional = 'ADDITIONAL',
  Primary = 'PRIMARY',
}

export enum EmailRecipient {
  BusinessProduct = 'BUSINESS_PRODUCT',
  Funder = 'FUNDER',
}

export enum Emailable {
  Business = 'BUSINESS',
  Contact = 'CONTACT',
}

export enum ErcPriority {
  High = 'HIGH',
  Highest = 'HIGHEST',
  Low = 'LOW',
  Lowest = 'LOWEST',
  Medium = 'MEDIUM',
}

export enum ErcProviderSelected {
  Elv = 'ELV',
  Ht = 'HT',
  Other = 'OTHER',
  Rd = 'RD',
}

export enum ErcQuarters {
  Y2020Q1 = 'Y2020_Q1',
  Y2020Q2 = 'Y2020_Q2',
  Y2020Q3 = 'Y2020_Q3',
  Y2020Q4 = 'Y2020_Q4',
  Y2021Q1 = 'Y2021_Q1',
  Y2021Q2 = 'Y2021_Q2',
  Y2021Q3 = 'Y2021_Q3',
  Y2021Q4 = 'Y2021_Q4',
}

export enum ErcStage {
  AmendmentSentToMerchant = 'AMENDMENT_SENT_TO_MERCHANT',
  AmendmentSignedAndSentToProvider = 'AMENDMENT_SIGNED_AND_SENT_TO_PROVIDER',
  Analysis = 'ANALYSIS',
  CallScheduledWithSpecialist = 'CALL_SCHEDULED_WITH_SPECIALIST',
  ClosedLost = 'CLOSED_LOST',
  CollectingAdditionalDocs = 'COLLECTING_ADDITIONAL_DOCS',
  ContractSent = 'CONTRACT_SENT',
  ContractSigned = 'CONTRACT_SIGNED',
  DocCollection = 'DOC_COLLECTION',
  EligibilityConfirmed = 'ELIGIBILITY_CONFIRMED',
  EligibilityTestSent = 'ELIGIBILITY_TEST_SENT',
  EligibilityTestSigned = 'ELIGIBILITY_TEST_SIGNED',
  FeeCollected = 'FEE_COLLECTED',
  FormSubmitted = 'FORM_SUBMITTED',
  Funded = 'FUNDED',
  FundsDisbursed = 'FUNDS_DISBURSED',
  None = 'NONE',
  NotEligible = 'NOT_ELIGIBLE',
  WaitingOnIrs = 'WAITING_ON_IRS',
}

export enum ExportStatus {
  Created = 'CREATED',
  Finished = 'FINISHED',
  PreparingData = 'PREPARING_DATA',
  Snapshotting = 'SNAPSHOTTING',
  UploadingFile = 'UPLOADING_FILE',
}

export enum FdrPaymentStatus {
  Clawback = 'CLAWBACK',
  Cleared = 'CLEARED',
  Outstanding = 'OUTSTANDING',
}

export enum FdrPaymentStatusInput {
  Clawback = 'CLAWBACK',
  Cleared = 'CLEARED',
  Outstanding = 'OUTSTANDING',
}

export enum FdrReportStatus {
  Clawback = 'CLAWBACK',
  DefaultedWithApplyingToOther = 'DEFAULTED_WITH_APPLYING_TO_OTHER',
  DefaultedWithCommissionBack = 'DEFAULTED_WITH_COMMISSION_BACK',
  InDispute = 'IN_DISPUTE',
  OnTrack = 'ON_TRACK',
  PaidInFull = 'PAID_IN_FULL',
  PartiallyPaid = 'PARTIALLY_PAID',
  PartialClawback = 'PARTIAL_CLAWBACK',
}

export enum FdrTermUnit {
  Days = 'DAYS',
  Months = 'MONTHS',
  NotAvailable = 'NOT_AVAILABLE',
  Weeks = 'WEEKS',
  Years = 'YEARS',
}

export enum FilterFieldAction {
  Filter = 'FILTER',
  Sort = 'SORT',
}

export enum FilterFieldData {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Enum = 'ENUM',
  Integer = 'INTEGER',
  Money = 'MONEY',
  Percent = 'PERCENT',
  Rate = 'RATE',
  Select = 'SELECT',
  String = 'STRING',
}

export enum FilterFieldView {
  AllUsers = 'ALL_USERS',
  Businesses = 'BUSINESSES',
  DealsInProcess = 'DEALS_IN_PROCESS',
  DncRecords = 'DNC_RECORDS',
  FundedDeals = 'FUNDED_DEALS',
  Funders = 'FUNDERS',
  FundingDetailsReports = 'FUNDING_DETAILS_REPORTS',
  Leads = 'LEADS',
  Opportunities = 'OPPORTUNITIES',
  PaymentItems = 'PAYMENT_ITEMS',
  PaymentTransactions = 'PAYMENT_TRANSACTIONS',
  SearchClosedLost = 'SEARCH_CLOSED_LOST',
  SearchLeads = 'SEARCH_LEADS',
  SearchOpportunities = 'SEARCH_OPPORTUNITIES',
  ServicePhones = 'SERVICE_PHONES',
  Users = 'USERS',
}

export enum FilterMethod {
  Any = 'ANY',
  Contains = 'CONTAINS',
  DaysLeftExact = 'DAYS_LEFT_EXACT',
  DaysLeftRange = 'DAYS_LEFT_RANGE',
  Exact = 'EXACT',
  Exists = 'EXISTS',
  Expired = 'EXPIRED',
  Gt = 'GT',
  Gte = 'GTE',
  Last = 'LAST',
  Lte = 'LTE',
  Not = 'NOT',
  Range = 'RANGE',
  ThisMonth = 'THIS_MONTH',
  ThisYear = 'THIS_YEAR',
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
}

export enum FinancingRequestStatus {
  Draft = 'DRAFT',
  Requested = 'REQUESTED',
}

export enum FinancingRequestTermUnit {
  Months = 'MONTHS',
  Weeks = 'WEEKS',
  Years = 'YEARS',
}

export enum FunderGroupAttribute {
  PrimaryFunders = 'PRIMARY_FUNDERS',
  SecondaryFunders = 'SECONDARY_FUNDERS',
  TertiaryFunders = 'TERTIARY_FUNDERS',
}

export enum FundingUsage {
  Acquisition = 'ACQUISITION',
  Equipment = 'EQUIPMENT',
  Inventory = 'INVENTORY',
  Payroll = 'PAYROLL',
  Renovation = 'RENOVATION',
  WorkingCapital = 'WORKING_CAPITAL',
}

export enum Iso4217 {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bch = 'BCH',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btc = 'BTC',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clf = 'CLF',
  Clp = 'CLP',
  Cnh = 'CNH',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gbx = 'GBX',
  Gel = 'GEL',
  Ggp = 'GGP',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Imp = 'IMP',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jep = 'JEP',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mtl = 'MTL',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Skk = 'SKK',
  Sle = 'SLE',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Std = 'STD',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usdc = 'USDC',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xfu = 'XFU',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xts = 'XTS',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zwd = 'ZWD',
}

export enum IntakeFormStep {
  Step_0 = 'STEP_0',
  Step_1 = 'STEP_1',
  Step_2 = 'STEP_2',
  Step_3 = 'STEP_3',
  Step_4 = 'STEP_4',
}

export enum Interest {
  DoNotCall = 'DO_NOT_CALL',
  Interested = 'INTERESTED',
  NotInterested = 'NOT_INTERESTED',
  Ready = 'READY',
  WillThink = 'WILL_THINK',
}

export enum LeadSearchableField {
  BusinessEmail = 'BUSINESS_EMAIL',
  BusinessFederalTaxId = 'BUSINESS_FEDERAL_TAX_ID',
  BusinessFormattedFederalTaxId = 'BUSINESS_FORMATTED_FEDERAL_TAX_ID',
  BusinessName = 'BUSINESS_NAME',
  BusinessPhone = 'BUSINESS_PHONE',
  MerchantCellPhone = 'MERCHANT_CELL_PHONE',
  MerchantEmail = 'MERCHANT_EMAIL',
  MerchantFormattedSsn = 'MERCHANT_FORMATTED_SSN',
  MerchantFullName = 'MERCHANT_FULL_NAME',
  MerchantSsn = 'MERCHANT_SSN',
  OwnerEmail = 'OWNER_EMAIL',
  OwnerName = 'OWNER_NAME',
}

export enum LeadsSpreadsheetOperation {
  Import = 'IMPORT',
  Scrub = 'SCRUB',
}

export enum LeadsSpreadsheetStatus {
  Created = 'CREATED',
  Done = 'DONE',
  Imported = 'IMPORTED',
  Importing = 'IMPORTING',
  InitialParse = 'INITIAL_PARSE',
  MalformedCsv = 'MALFORMED_CSV',
  Processing = 'PROCESSING',
  Scrubbing = 'SCRUBBING',
}

export enum LoanType {
  Acquisition = 'ACQUISITION',
  Refinance = 'REFINANCE',
}

export enum Month {
  April = 'APRIL',
  August = 'AUGUST',
  December = 'DECEMBER',
  February = 'FEBRUARY',
  January = 'JANUARY',
  July = 'JULY',
  June = 'JUNE',
  March = 'MARCH',
  May = 'MAY',
  November = 'NOVEMBER',
  October = 'OCTOBER',
  September = 'SEPTEMBER',
}

export enum Notable {
  BusinessProduct = 'BUSINESS_PRODUCT',
  Document = 'DOCUMENT',
}

export enum NotificationKind {
  AlreadyFundedDeal = 'ALREADY_FUNDED_DEAL',
  BusinessManuallyMarkedAsDnc = 'BUSINESS_MANUALLY_MARKED_AS_DNC',
  BusinessMarkedAsDnc = 'BUSINESS_MARKED_AS_DNC',
  BusinessProductsAssigned = 'BUSINESS_PRODUCTS_ASSIGNED',
  BusinessProductsReassigned = 'BUSINESS_PRODUCTS_REASSIGNED',
  BusinessProductsShared = 'BUSINESS_PRODUCTS_SHARED',
  BusinessProductAssigned = 'BUSINESS_PRODUCT_ASSIGNED',
  BusinessProductCloseLost = 'BUSINESS_PRODUCT_CLOSE_LOST',
  BusinessProductStageChanged = 'BUSINESS_PRODUCT_STAGE_CHANGED',
  DealPayoutReceived = 'DEAL_PAYOUT_RECEIVED',
  DocumentStateChanged = 'DOCUMENT_STATE_CHANGED',
  DocumentUploaded = 'DOCUMENT_UPLOADED',
  DocumentVerified = 'DOCUMENT_VERIFIED',
  DrawRequestCreated = 'DRAW_REQUEST_CREATED',
  DrawRequestStatusChanged = 'DRAW_REQUEST_STATUS_CHANGED',
  ExclusivityExpiresSoon = 'EXCLUSIVITY_EXPIRES_SOON',
  ExclusivityRemoved = 'EXCLUSIVITY_REMOVED',
  FdrReportRetracted = 'FDR_REPORT_RETRACTED',
  FdrReportSubmitted = 'FDR_REPORT_SUBMITTED',
  FinancingRequestSubmitted = 'FINANCING_REQUEST_SUBMITTED',
  LeadFormSubmitted = 'LEAD_FORM_SUBMITTED',
  OfferFunderStatusChanged = 'OFFER_FUNDER_STATUS_CHANGED',
  OfferStatusChanged = 'OFFER_STATUS_CHANGED',
  PayoutReceived = 'PAYOUT_RECEIVED',
  PsfReportRetracted = 'PSF_REPORT_RETRACTED',
  PsfReportSubmitted = 'PSF_REPORT_SUBMITTED',
  Reminder = 'REMINDER',
  SplitReportSubmitted = 'SPLIT_REPORT_SUBMITTED',
  TaskAssigned = 'TASK_ASSIGNED',
  TextMessageReceived = 'TEXT_MESSAGE_RECEIVED',
  UnsubmittedSplitReport = 'UNSUBMITTED_SPLIT_REPORT',
}

export enum NotificationStatus {
  Dismissed = 'DISMISSED',
  New = 'NEW',
}

export enum OfferDeclineReason {
  AlteredStatements = 'ALTERED_STATEMENTS',
  AnotherIsoHasExclusivity = 'ANOTHER_ISO_HAS_EXCLUSIVITY',
  Bankruptcy = 'BANKRUPTCY',
  DefaultFile = 'DEFAULT_FILE',
  DoesNotMeetRiskScore = 'DOES_NOT_MEET_RISK_SCORE',
  LowCredit = 'LOW_CREDIT',
  LowDailyBalances = 'LOW_DAILY_BALANCES',
  LowDepositCount = 'LOW_DEPOSIT_COUNT',
  LowRevenue = 'LOW_REVENUE',
  MerchantDeclined = 'MERCHANT_DECLINED',
  NegativeDays = 'NEGATIVE_DAYS',
  Nsfs = 'NSFS',
  Other = 'OTHER',
  OverLeveraged = 'OVER_LEVERAGED',
  PastDueAccounts = 'PAST_DUE_ACCOUNTS',
  PoorPaymentHistory = 'POOR_PAYMENT_HISTORY',
  RestrictedIndustry = 'RESTRICTED_INDUSTRY',
  RestrictedState = 'RESTRICTED_STATE',
  ReverseConsolidation = 'REVERSE_CONSOLIDATION',
  TimeInBusiness = 'TIME_IN_BUSINESS',
  VolatileRevenue = 'VOLATILE_REVENUE',
}

export enum OfferStatus {
  Declined = 'DECLINED',
  Received = 'RECEIVED',
  Selected = 'SELECTED',
  SubmissionFailed = 'SUBMISSION_FAILED',
  Waiting = 'WAITING',
}

export enum OnCreateStatus {
  Received = 'RECEIVED',
  Waiting = 'WAITING',
}

export enum OpportunitySearchableField {
  BusinessEmail = 'BUSINESS_EMAIL',
  BusinessFederalTaxId = 'BUSINESS_FEDERAL_TAX_ID',
  BusinessFormattedFederalTaxId = 'BUSINESS_FORMATTED_FEDERAL_TAX_ID',
  BusinessName = 'BUSINESS_NAME',
  BusinessPhone = 'BUSINESS_PHONE',
  MerchantCellPhone = 'MERCHANT_CELL_PHONE',
  MerchantEmail = 'MERCHANT_EMAIL',
  MerchantFormattedSsn = 'MERCHANT_FORMATTED_SSN',
  MerchantFullName = 'MERCHANT_FULL_NAME',
  MerchantSsn = 'MERCHANT_SSN',
  OwnerEmail = 'OWNER_EMAIL',
  OwnerName = 'OWNER_NAME',
}

export enum Ownership {
  Owned = 'OWNED',
  Shared = 'SHARED',
}

export enum PaymentItemKind {
  Adjustment = 'ADJUSTMENT',
  Advance = 'ADVANCE',
  Bonus = 'BONUS',
  PaidInFull = 'PAID_IN_FULL',
  Regular = 'REGULAR',
}

export enum PaymentItemReportKind {
  Fdr = 'FDR',
  Psf = 'PSF',
}

export enum PaymentStatus {
  AccountFrozen = 'ACCOUNT_FROZEN',
  BlockedByMerchant = 'BLOCKED_BY_MERCHANT',
  Clawback = 'CLAWBACK',
  Cleared = 'CLEARED',
  InacurateRouting = 'INACURATE_ROUTING',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  Outstanding = 'OUTSTANDING',
  Processing = 'PROCESSING',
}

export enum PaymentTransactionKind {
  Bonus = 'BONUS',
  Draw = 'DRAW',
  FdrAdjustment = 'FDR_ADJUSTMENT',
  FdrBonus = 'FDR_BONUS',
  FdrClosedLost = 'FDR_CLOSED_LOST',
  FdrDeduction = 'FDR_DEDUCTION',
  FdrPayout = 'FDR_PAYOUT',
  Fine = 'FINE',
  PsfAdjustment = 'PSF_ADJUSTMENT',
  PsfClosedLost = 'PSF_CLOSED_LOST',
  PsfDeduction = 'PSF_DEDUCTION',
  PsfPayout = 'PSF_PAYOUT',
  Reimbursement = 'REIMBURSEMENT',
  Spiff = 'SPIFF',
}

export enum PaymentTransactionKindInput {
  Bonus = 'BONUS',
  Fine = 'FINE',
  Reimbursement = 'REIMBURSEMENT',
  Spiff = 'SPIFF',
}

export enum PaymentTransactionStatus {
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
}

export enum PaymentTransactionTargetEnum {
  User = 'USER',
}

export enum PersonalDocumentKind {
  Other = 'OTHER',
  PmfAgreement = 'PMF_AGREEMENT',
  TaxForm_1099 = 'TAX_FORM_1099',
}

export enum PhoneKind {
  Additional = 'ADDITIONAL',
  Primary = 'PRIMARY',
}

export enum PhoneLine {
  Cell = 'CELL',
  Landline = 'LANDLINE',
}

export enum Phoneable {
  Business = 'BUSINESS',
  Contact = 'CONTACT',
}

export enum PossibleCrossSale {
  CreditCardProcessing = 'CREDIT_CARD_PROCESSING',
  CreditServicing = 'CREDIT_SERVICING',
  EquipmentFinancing = 'EQUIPMENT_FINANCING',
  Factoring = 'FACTORING',
  Marketing = 'MARKETING',
  PayrollHrBenefits = 'PAYROLL_HR_BENEFITS',
  Sba_7A = 'SBA_7A',
}

export enum Product {
  Bonus = 'BONUS',
  CreditRepair = 'CREDIT_REPAIR',
  Eidl = 'EIDL',
  EquipmentFinancing = 'EQUIPMENT_FINANCING',
  Factoring = 'FACTORING',
  Fee = 'FEE',
  Loc = 'LOC',
  ManagementConsultingFee = 'MANAGEMENT_CONSULTING_FEE',
  Mca = 'MCA',
  McaFlex = 'MCA_FLEX',
  Other = 'OTHER',
  PersonalLoan = 'PERSONAL_LOAN',
  Ppp = 'PPP',
  RealEstate = 'REAL_ESTATE',
  ReverseConsolidation = 'REVERSE_CONSOLIDATION',
  ReverseDisbursment = 'REVERSE_DISBURSMENT',
  Sba = 'SBA',
  Sba_7A = 'SBA_7A',
  SbaEidl = 'SBA_EIDL',
  TermLoan = 'TERM_LOAN',
}

export enum ProductFieldData {
  Array = 'ARRAY',
  Bigint = 'BIGINT',
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Float = 'FLOAT',
  Integer = 'INTEGER',
  Money = 'MONEY',
  String = 'STRING',
  Text = 'TEXT',
}

export enum ProductFieldKind {
  Address = 'ADDRESS',
  Bigint = 'BIGINT',
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Email = 'EMAIL',
  Float = 'FLOAT',
  Integer = 'INTEGER',
  Money = 'MONEY',
  MultiSelect = 'MULTI_SELECT',
  Percentage = 'PERCENTAGE',
  PhoneNumber = 'PHONE_NUMBER',
  Select = 'SELECT',
  Ssn = 'SSN',
  String = 'STRING',
  TaxId = 'TAX_ID',
  Text = 'TEXT',
  Url = 'URL',
  ZipCode = 'ZIP_CODE',
}

export enum ProjectedErcAmountRange {
  From_50KTo_100K = 'FROM_50K_TO_100K',
  From_100KTo_250K = 'FROM_100K_TO_250K',
  From_250KTo_500K = 'FROM_250K_TO_500K',
  From_500KTo_1M = 'FROM_500K_TO_1M',
  MoreThan_1M = 'MORE_THAN_1M',
  Under_50K = 'UNDER_50K',
}

export enum Property {
  Hotel = 'HOTEL',
  Industrial = 'INDUSTRIAL',
  Multifamily = 'MULTIFAMILY',
  OfficeBuilding = 'OFFICE_BUILDING',
  Other = 'OTHER',
  OwnerOccupied = 'OWNER_OCCUPIED',
  SelfStorateFacility = 'SELF_STORATE_FACILITY',
  ShoppingCenter = 'SHOPPING_CENTER',
}

export enum PsfPaymentMethod {
  AchDebit = 'ACH_DEBIT',
  AchWire = 'ACH_WIRE',
  Check = 'CHECK',
  CreditCardPull = 'CREDIT_CARD_PULL',
  InHouse = 'IN_HOUSE',
  Zelle = 'ZELLE',
}

export enum PsfPaymentStatus {
  AccountFrozen = 'ACCOUNT_FROZEN',
  BlockedByMerchant = 'BLOCKED_BY_MERCHANT',
  Clawback = 'CLAWBACK',
  Cleared = 'CLEARED',
  InacurateRouting = 'INACURATE_ROUTING',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  Outstanding = 'OUTSTANDING',
  Processing = 'PROCESSING',
}

export enum PsfPaymentStatusInput {
  AccountFrozen = 'ACCOUNT_FROZEN',
  BlockedByMerchant = 'BLOCKED_BY_MERCHANT',
  Clawback = 'CLAWBACK',
  Cleared = 'CLEARED',
  InacurateRouting = 'INACURATE_ROUTING',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  Outstanding = 'OUTSTANDING',
  Processing = 'PROCESSING',
}

export enum PsfReportStatus {
  Cancelled = 'CANCELLED',
  OnTrack = 'ON_TRACK',
  PaidInFull = 'PAID_IN_FULL',
  Returned = 'RETURNED',
}

export enum RecurrenceRuleType {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Minutely = 'MINUTELY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export enum RejectionReason {
  Incomplete = 'INCOMPLETE',
  NotLegible = 'NOT_LEGIBLE',
  Personal = 'PERSONAL',
  RejectWithComment = 'REJECT_WITH_COMMENT',
}

export enum SearchField {
  BusinessName = 'BUSINESS_NAME',
  BusinessPhone = 'BUSINESS_PHONE',
  CellPhone = 'CELL_PHONE',
  Email = 'EMAIL',
  FederalTaxId = 'FEDERAL_TAX_ID',
  FormattedFederalTaxId = 'FORMATTED_FEDERAL_TAX_ID',
  FormattedSsn = 'FORMATTED_SSN',
  FullName = 'FULL_NAME',
  OwnerEmail = 'OWNER_EMAIL',
  OwnerName = 'OWNER_NAME',
  Phone = 'PHONE',
  Ssn = 'SSN',
}

export enum SearchItemKind {
  ClosedLost = 'CLOSED_LOST',
  Contact = 'CONTACT',
  Lead = 'LEAD',
  Opportunity = 'OPPORTUNITY',
  User = 'USER',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum SplitOwnership {
  AllFutureDeals = 'ALL_FUTURE_DEALS',
  AllRenewals = 'ALL_RENEWALS',
  CurrentDeal = 'CURRENT_DEAL',
  SharedOwnership = 'SHARED_OWNERSHIP',
}

export enum State {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  Az = 'AZ',
  Ca = 'CA',
  Can = 'CAN',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Other = 'Other',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Uk = 'UK',
  Ut = 'UT',
  Va = 'VA',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
}

export enum Target {
  User = 'USER',
}

export enum Taskable {
  BusinessProduct = 'BUSINESS_PRODUCT',
}

export enum TasksStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
}

export enum TextMessageStatus {
  Failed = 'FAILED',
  New = 'NEW',
  Sent = 'SENT',
}

export enum TimeZone {
  AfricaAlgiers = 'AFRICA_ALGIERS',
  AfricaCairo = 'AFRICA_CAIRO',
  AfricaCasablanca = 'AFRICA_CASABLANCA',
  AfricaHarare = 'AFRICA_HARARE',
  AfricaJohannesburg = 'AFRICA_JOHANNESBURG',
  AfricaMonrovia = 'AFRICA_MONROVIA',
  AfricaNairobi = 'AFRICA_NAIROBI',
  AmericaAdak = 'AMERICA_ADAK',
  AmericaAnchorage = 'AMERICA_ANCHORAGE',
  AmericaArgentinaBuenosAires = 'AMERICA_ARGENTINA_BUENOS_AIRES',
  AmericaBogota = 'AMERICA_BOGOTA',
  AmericaBoise = 'AMERICA_BOISE',
  AmericaCaracas = 'AMERICA_CARACAS',
  AmericaChicago = 'AMERICA_CHICAGO',
  AmericaChihuahua = 'AMERICA_CHIHUAHUA',
  AmericaDenver = 'AMERICA_DENVER',
  AmericaDetroit = 'AMERICA_DETROIT',
  AmericaGodthab = 'AMERICA_GODTHAB',
  AmericaGuatemala = 'AMERICA_GUATEMALA',
  AmericaGuyana = 'AMERICA_GUYANA',
  AmericaHalifax = 'AMERICA_HALIFAX',
  AmericaIndianaIndianapolis = 'AMERICA_INDIANA_INDIANAPOLIS',
  AmericaIndianaKnox = 'AMERICA_INDIANA_KNOX',
  AmericaIndianaMarengo = 'AMERICA_INDIANA_MARENGO',
  AmericaIndianaPetersburg = 'AMERICA_INDIANA_PETERSBURG',
  AmericaIndianaTellCity = 'AMERICA_INDIANA_TELL_CITY',
  AmericaIndianaVevay = 'AMERICA_INDIANA_VEVAY',
  AmericaIndianaVincennes = 'AMERICA_INDIANA_VINCENNES',
  AmericaIndianaWinamac = 'AMERICA_INDIANA_WINAMAC',
  AmericaJuneau = 'AMERICA_JUNEAU',
  AmericaKentuckyLouisville = 'AMERICA_KENTUCKY_LOUISVILLE',
  AmericaKentuckyMonticello = 'AMERICA_KENTUCKY_MONTICELLO',
  AmericaLaPaz = 'AMERICA_LA_PAZ',
  AmericaLima = 'AMERICA_LIMA',
  AmericaLosAngeles = 'AMERICA_LOS_ANGELES',
  AmericaMazatlan = 'AMERICA_MAZATLAN',
  AmericaMenominee = 'AMERICA_MENOMINEE',
  AmericaMetlakatla = 'AMERICA_METLAKATLA',
  AmericaMexicoCity = 'AMERICA_MEXICO_CITY',
  AmericaMonterrey = 'AMERICA_MONTERREY',
  AmericaMontevideo = 'AMERICA_MONTEVIDEO',
  AmericaNewYork = 'AMERICA_NEW_YORK',
  AmericaNome = 'AMERICA_NOME',
  AmericaNorthDakotaBeulah = 'AMERICA_NORTH_DAKOTA_BEULAH',
  AmericaNorthDakotaCenter = 'AMERICA_NORTH_DAKOTA_CENTER',
  AmericaNorthDakotaNewSalem = 'AMERICA_NORTH_DAKOTA_NEW_SALEM',
  AmericaPhoenix = 'AMERICA_PHOENIX',
  AmericaPuertoRico = 'AMERICA_PUERTO_RICO',
  AmericaRegina = 'AMERICA_REGINA',
  AmericaSantiago = 'AMERICA_SANTIAGO',
  AmericaSaoPaulo = 'AMERICA_SAO_PAULO',
  AmericaSitka = 'AMERICA_SITKA',
  AmericaStJohns = 'AMERICA_ST_JOHNS',
  AmericaTijuana = 'AMERICA_TIJUANA',
  AmericaYakutat = 'AMERICA_YAKUTAT',
  AsiaAlmaty = 'ASIA_ALMATY',
  AsiaBaghdad = 'ASIA_BAGHDAD',
  AsiaBaku = 'ASIA_BAKU',
  AsiaBangkok = 'ASIA_BANGKOK',
  AsiaChongqing = 'ASIA_CHONGQING',
  AsiaColombo = 'ASIA_COLOMBO',
  AsiaDhaka = 'ASIA_DHAKA',
  AsiaHongKong = 'ASIA_HONG_KONG',
  AsiaIrkutsk = 'ASIA_IRKUTSK',
  AsiaJakarta = 'ASIA_JAKARTA',
  AsiaJerusalem = 'ASIA_JERUSALEM',
  AsiaKabul = 'ASIA_KABUL',
  AsiaKamchatka = 'ASIA_KAMCHATKA',
  AsiaKarachi = 'ASIA_KARACHI',
  AsiaKathmandu = 'ASIA_KATHMANDU',
  AsiaKolkata = 'ASIA_KOLKATA',
  AsiaKrasnoyarsk = 'ASIA_KRASNOYARSK',
  AsiaKualaLumpur = 'ASIA_KUALA_LUMPUR',
  AsiaKuwait = 'ASIA_KUWAIT',
  AsiaMagadan = 'ASIA_MAGADAN',
  AsiaMuscat = 'ASIA_MUSCAT',
  AsiaNovosibirsk = 'ASIA_NOVOSIBIRSK',
  AsiaRangoon = 'ASIA_RANGOON',
  AsiaRiyadh = 'ASIA_RIYADH',
  AsiaSeoul = 'ASIA_SEOUL',
  AsiaShanghai = 'ASIA_SHANGHAI',
  AsiaSingapore = 'ASIA_SINGAPORE',
  AsiaSrednekolymsk = 'ASIA_SREDNEKOLYMSK',
  AsiaTaipei = 'ASIA_TAIPEI',
  AsiaTashkent = 'ASIA_TASHKENT',
  AsiaTbilisi = 'ASIA_TBILISI',
  AsiaTehran = 'ASIA_TEHRAN',
  AsiaTokyo = 'ASIA_TOKYO',
  AsiaUlaanbaatar = 'ASIA_ULAANBAATAR',
  AsiaUrumqi = 'ASIA_URUMQI',
  AsiaVladivostok = 'ASIA_VLADIVOSTOK',
  AsiaYakutsk = 'ASIA_YAKUTSK',
  AsiaYekaterinburg = 'ASIA_YEKATERINBURG',
  AsiaYerevan = 'ASIA_YEREVAN',
  AtlanticAzores = 'ATLANTIC_AZORES',
  AtlanticCapeVerde = 'ATLANTIC_CAPE_VERDE',
  AtlanticSouthGeorgia = 'ATLANTIC_SOUTH_GEORGIA',
  AustraliaAdelaide = 'AUSTRALIA_ADELAIDE',
  AustraliaBrisbane = 'AUSTRALIA_BRISBANE',
  AustraliaDarwin = 'AUSTRALIA_DARWIN',
  AustraliaHobart = 'AUSTRALIA_HOBART',
  AustraliaMelbourne = 'AUSTRALIA_MELBOURNE',
  AustraliaPerth = 'AUSTRALIA_PERTH',
  AustraliaSydney = 'AUSTRALIA_SYDNEY',
  EtcGmt_12 = 'ETC_GMT_12',
  EtcUtc = 'ETC_UTC',
  EuropeAmsterdam = 'EUROPE_AMSTERDAM',
  EuropeAthens = 'EUROPE_ATHENS',
  EuropeBelgrade = 'EUROPE_BELGRADE',
  EuropeBerlin = 'EUROPE_BERLIN',
  EuropeBratislava = 'EUROPE_BRATISLAVA',
  EuropeBrussels = 'EUROPE_BRUSSELS',
  EuropeBucharest = 'EUROPE_BUCHAREST',
  EuropeBudapest = 'EUROPE_BUDAPEST',
  EuropeCopenhagen = 'EUROPE_COPENHAGEN',
  EuropeDublin = 'EUROPE_DUBLIN',
  EuropeHelsinki = 'EUROPE_HELSINKI',
  EuropeIstanbul = 'EUROPE_ISTANBUL',
  EuropeKaliningrad = 'EUROPE_KALININGRAD',
  EuropeKiev = 'EUROPE_KIEV',
  EuropeLisbon = 'EUROPE_LISBON',
  EuropeLjubljana = 'EUROPE_LJUBLJANA',
  EuropeLondon = 'EUROPE_LONDON',
  EuropeMadrid = 'EUROPE_MADRID',
  EuropeMinsk = 'EUROPE_MINSK',
  EuropeMoscow = 'EUROPE_MOSCOW',
  EuropeParis = 'EUROPE_PARIS',
  EuropePrague = 'EUROPE_PRAGUE',
  EuropeRiga = 'EUROPE_RIGA',
  EuropeRome = 'EUROPE_ROME',
  EuropeSamara = 'EUROPE_SAMARA',
  EuropeSarajevo = 'EUROPE_SARAJEVO',
  EuropeSkopje = 'EUROPE_SKOPJE',
  EuropeSofia = 'EUROPE_SOFIA',
  EuropeStockholm = 'EUROPE_STOCKHOLM',
  EuropeTallinn = 'EUROPE_TALLINN',
  EuropeVienna = 'EUROPE_VIENNA',
  EuropeVilnius = 'EUROPE_VILNIUS',
  EuropeVolgograd = 'EUROPE_VOLGOGRAD',
  EuropeWarsaw = 'EUROPE_WARSAW',
  EuropeZagreb = 'EUROPE_ZAGREB',
  EuropeZurich = 'EUROPE_ZURICH',
  PacificApia = 'PACIFIC_APIA',
  PacificAuckland = 'PACIFIC_AUCKLAND',
  PacificChatham = 'PACIFIC_CHATHAM',
  PacificFakaofo = 'PACIFIC_FAKAOFO',
  PacificFiji = 'PACIFIC_FIJI',
  PacificGuadalcanal = 'PACIFIC_GUADALCANAL',
  PacificGuam = 'PACIFIC_GUAM',
  PacificHonolulu = 'PACIFIC_HONOLULU',
  PacificMajuro = 'PACIFIC_MAJURO',
  PacificMidway = 'PACIFIC_MIDWAY',
  PacificNoumea = 'PACIFIC_NOUMEA',
  PacificPagoPago = 'PACIFIC_PAGO_PAGO',
  PacificPortMoresby = 'PACIFIC_PORT_MORESBY',
  PacificTongatapu = 'PACIFIC_TONGATAPU',
}

export enum Timeframe {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
}

export enum UserRole {
  Accounting = 'ACCOUNTING',
  Admin = 'ADMIN',
  HrAdmin = 'HR_ADMIN',
  HumanResources = 'HUMAN_RESOURCES',
  Processor = 'PROCESSOR',
  ProcessorLead = 'PROCESSOR_LEAD',
  SalesRep = 'SALES_REP',
  SalesRepInTraining = 'SALES_REP_IN_TRAINING',
  TeamLeader = 'TEAM_LEADER',
  TeamLeaderInTraining = 'TEAM_LEADER_IN_TRAINING',
  TeamManager = 'TEAM_MANAGER',
  Underwriter = 'UNDERWRITER',
}

export enum UserStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export enum UserTier {
  Custom = 'CUSTOM',
  Tier_1 = 'TIER_1',
  Tier_2 = 'TIER_2',
  Tier_3 = 'TIER_3',
  Tier_4 = 'TIER_4',
  Tier_5 = 'TIER_5',
  Tier_6 = 'TIER_6',
  Tier_7 = 'TIER_7',
}

export enum Weekday {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}
