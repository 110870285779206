import type { MutationTuple } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

const PresignDataMutation = loader('../../graphql/mutations/presignData.graphql');

const usePresignDataMutation = () => {
  const [presignData, mutationResult] = useMutation(PresignDataMutation);

  return [presignData, mutationResult] as MutationTuple<any, any>;
};

export default usePresignDataMutation;
