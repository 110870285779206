import { loader } from 'graphql.macro';

import type {
  CustomOriginalOptions,
  MutationHook,
  MutationOriginalOptions,
  MutationOriginData,
  MutationResult,
} from 'api/types/hooks/mutation';

import { useMutation } from 'lib/apollo/hooks';

import type { CreateRealEstateFormMutationData, CreateRealEstateFormMutationVariables, MutationName } from './types';
import { MUTATION_NAME } from './types';

const CreateRealEstateFormMutation = loader('./createRealEstateForm.graphql');

export const getData = (
  data: MutationOriginData<CreateRealEstateFormMutationData, MutationName>,
): CreateRealEstateFormMutationData => data[MUTATION_NAME];

const useCreateRealEstateFormMutation: MutationHook<
  CreateRealEstateFormMutationData,
  MutationName,
  CreateRealEstateFormMutationVariables
> = (options, { getCompletedNotify, onErrorMessage, ...customOptions } = {}) => {
  const newOptions: MutationOriginalOptions<
    CreateRealEstateFormMutationData,
    MutationName,
    CreateRealEstateFormMutationVariables
  > = options
    ? {
        ...options,
        onCompleted: options.onCompleted ? (data) => options.onCompleted?.(getData(data)) : undefined,
      }
    : {};
  const newCustomOptions: CustomOriginalOptions<CreateRealEstateFormMutationData, MutationName> | undefined =
    customOptions
      ? {
          ...customOptions,
          onErrorMessage: onErrorMessage ?? 'Real Estate Financing form was not sent',
          getCompletedNotify: getCompletedNotify ? (data) => getCompletedNotify?.(getData(data)) : undefined,
        }
      : undefined;

  const [createRealEstateForm, mutationResult] = useMutation(
    CreateRealEstateFormMutation,
    newOptions,
    newCustomOptions,
  ) as MutationResult<CreateRealEstateFormMutationData, MutationName, CreateRealEstateFormMutationVariables>;

  return [createRealEstateForm, mutationResult];
};

export default useCreateRealEstateFormMutation;
