import SliderWithInput from 'components/ui/molecules/SliderWithInput';

import type { CalculatorValues } from '../FormContainer/config';
import { getFieldsInfo } from './config';
import { SliderFieldsContainer } from './styled';
import type { SliderFieldsProps } from './types';

const SliderFields = ({
  values,
  setValues,
  termUnitText,
  maxTermUnitSize,
  maxFinancingAmount,
  onChangeCommitted,
  disabled = false,
}: SliderFieldsProps) => {
  // TODO: add correct types
  const onChange = (value: any, field: any) => {
    setValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const fieldsInfo = getFieldsInfo({ maxFinancingAmount, termUnitText, maxTermUnitSize });

  return (
    <SliderFieldsContainer>
      {Object.entries(fieldsInfo).map(([key, field]) => (
        <SliderWithInput
          key={key}
          // TODO: add correct types
          value={values[key as CalculatorValues]}
          onChange={(value) => onChange(value, key)}
          onChangeCommitted={onChangeCommitted}
          disabled={disabled}
          {...field}
        />
      ))}
    </SliderFieldsContainer>
  );
};

export default SliderFields;
