import { Icons } from 'config/icons/feather';

import { DefaultCheckbox, StyledCheckbox, StyledFormControlLabel, StyledIcon } from './styled';
import type { CheckboxProps } from './types';

const Checkbox = ({
  checked,
  indeterminate,
  disabled,
  label,
  onChange,
  name,
  ...restProps
}: CheckboxProps): JSX.Element => {
  return (
    <StyledFormControlLabel htmlFor={name} disabled={disabled ?? false} {...restProps}>
      <DefaultCheckbox
        type="checkbox"
        name={name}
        id={name}
        onChange={onChange}
        checked={checked}
        disabled={disabled ?? false}
      />
      <StyledCheckbox>
        {checked && <StyledIcon type={Icons.Check} />}
        {(indeterminate ?? false) && <StyledIcon type={Icons.Minus} />}
      </StyledCheckbox>
      {label && <span>{label}</span>}
    </StyledFormControlLabel>
  );
};

export default Checkbox;
