import useNotifier from 'hooks/useNotifier';

import Toast from 'components/ui/atoms/Toast';

import { ToastWrapper, Wrapper } from './styled';

const Notifier = ({ maxStack = 3 }) => {
  const { notifierList, clearMessage } = useNotifier();

  return notifierList.length ? (
    <Wrapper>
      {notifierList
        .slice(-maxStack)
        .reverse()
        .map(({ id, message, type, actionTimeOut }) => (
          <ToastWrapper key={id}>
            <Toast key={id} type={type} action={() => clearMessage(id)} actionTimeOut={actionTimeOut}>
              {message}
            </Toast>
          </ToastWrapper>
        ))}
    </Wrapper>
  ) : null;
};

export default Notifier;
