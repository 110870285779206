import { CalculatorValues } from '../FormContainer/config';
import { StyledInput, StyledMoneyInput } from './styled';
import type { GetFieldsInfoArs, MaxFinancingAmount, MaxTermUnitSize, TermUnitText } from './types';

const FINANCING_AMOUNT_MIN = 1000000;
const FINANCING_AMOUNT_MIN_LABEL = '$10,000.00';

const FINANCING_AMOUNT_MAX_DEFAULT = FINANCING_AMOUNT_MIN * 2;
const FINANCING_AMOUNT_MAX_LABEL_DEFAULT = '$20,000.00';

const FINANCING_AMOUNT_STEP = 100000;

const getFinancingAmountMarks = (max: MaxFinancingAmount) => [
  {
    value: FINANCING_AMOUNT_MIN,
    label: FINANCING_AMOUNT_MIN_LABEL,
  },
  {
    value: max?.fractional ?? FINANCING_AMOUNT_MAX_DEFAULT,
    label: max?.displayString ?? FINANCING_AMOUNT_MAX_LABEL_DEFAULT,
  },
];

const TERM_MINS = {
  Weeks: 4,
  Months: 1,
  Years: 1,
};

const getTermMin = (termUnit: keyof typeof TERM_MINS) => TERM_MINS[termUnit];

const TERM_MIN_DEFAULT = 0;
const TERM_MAX_DEFAULT = 90;

const getTermMarks = (max: MaxTermUnitSize, termUnitText: TermUnitText) => {
  const { plural: pluralTermUnitText, singular: singularTermUnitText } = termUnitText;

  const minTermUnit = TERM_MINS[pluralTermUnitText as keyof typeof TERM_MINS];
  const termInitTextBasedOnMinValue = minTermUnit > 1 ? pluralTermUnitText : singularTermUnitText;

  return [
    {
      value: minTermUnit,
      label: `${minTermUnit ?? TERM_MIN_DEFAULT} ${termInitTextBasedOnMinValue.toLowerCase()}`,
    },
    {
      value: max,
      label: `${max ?? TERM_MAX_DEFAULT} ${pluralTermUnitText.toLowerCase()}`,
    },
  ];
};

export const getFieldsInfo = ({ maxFinancingAmount, termUnitText, maxTermUnitSize }: GetFieldsInfoArs) => ({
  [CalculatorValues.RequestedFinancingAmountCents]: {
    id: CalculatorValues.RequestedFinancingAmountCents,
    label: 'Financing Amount',
    control: <StyledMoneyInput decimalScale={0} />,
    min: FINANCING_AMOUNT_MIN,
    max: maxFinancingAmount?.fractional ?? FINANCING_AMOUNT_MAX_DEFAULT,
    step: FINANCING_AMOUNT_STEP,
    marks: getFinancingAmountMarks(maxFinancingAmount),
  },
  [CalculatorValues.RequestedTermUnitSize]: {
    id: CalculatorValues.RequestedTermUnitSize,
    label: `Max Term (Number of ${termUnitText.adverb.toLowerCase()} payments)`,
    control: <StyledInput />,
    min: getTermMin(termUnitText.plural as keyof typeof TERM_MINS),
    max: maxTermUnitSize,
    marks: getTermMarks(maxTermUnitSize, termUnitText),
  },
});
