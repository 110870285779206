import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledLink = styled.a(
  ({ theme: { palette } }) => css`
    color: ${palette.primary.main};

    &:hover {
      color: ${palette.primary.dark};
    }
  `,
);
