import { styled as muiStyled } from '@mui/material/styles';

import Input from 'components/ui/atoms/forms/Input';

export const StyledInput = muiStyled(Input)(({ theme: { spacing } }) => ({
  height: 'auto',
  padding: `${spacing(1)}`,
  width: '100%',

  '& .MuiInputBase-input': {
    lineHeight: '32px',
    overflowX: 'hidden',
    overflowY: 'auto',
    textOverflow: 'ellipsis',
    padding: '0',
  },
}));
