import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import useCreateERCFormDraftMutation from 'api/mutations/erc/useCreateERCFormDraftV2Mutation';
import useUpdateERCFormDraftMutation from 'api/mutations/erc/useUpdateERCFormDraftV2Mutation';

import { ORIGIN, UUID } from 'config/constants/queryParams';

import useNotifier from 'hooks/useNotifier';
import useReferralId from 'hooks/useReferralId';
import useSaveDraftButtonClick from 'hooks/useSaveDraftButtonClick';

import BaseCallBackButton from 'components/shared/molecules/BaseCallBackButton';

import { saveForLaterValidationFields } from '../../../../config/config';
import getErcReferralIdFromOrigin from '../../../../getErcReferralIdFromOrigin';

const CallBackButton = ({ validatedFields, isFormValuesChanged }: any) => {
  const [isValid, setIsValid] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const referralId = useReferralId(getErcReferralIdFromOrigin);
  const uuid = searchParams.get(UUID);
  const origin = searchParams.get(ORIGIN);

  const { saveDraftButtonClick } = useSaveDraftButtonClick();

  const { setSuccess, setError } = useNotifier();

  const requiredValidationSchema = Yup.object().shape(saveForLaterValidationFields);

  useEffect(() => {
    requiredValidationSchema
      .validate(validatedFields)
      .then(() => setIsValid(true))
      .catch(() => setIsValid(false));
  }, [validatedFields]);

  const [updateERCFormDraft, { loading: updateERCLoading }] = useUpdateERCFormDraftMutation({
    onCompleted: () => {
      setSuccess('ERC form updated successfully!');
    },
    onError: (err) => setError(err),
  });

  const [createERCFormDraft, { loading }] = useCreateERCFormDraftMutation({
    onCompleted: (data) => {
      saveDraftButtonClick({ uuid: data?.uuid });
      setSuccess('ERC form draft was created successfully!');
    },
    onError: (gqlError) => {
      setError(`Form error: ${gqlError}`);
    },
  });

  const clickHandler = async () => {
    const {
      businessName,
      businessEmployeesCount,
      merchantFirstName,
      merchantLastName,
      merchantEmail,
      merchantCellPhone,
    } = validatedFields ?? {};
    const variables = {
      userReferralId: referralId,
      businessName,
      businessEmployeesCount: +businessEmployeesCount,
      merchantFirstName,
      merchantLastName,
      merchantEmail,
      merchantCellPhone,
      savedForLater: true,
    };
    if (uuid) {
      await updateERCFormDraft({
        variables: {
          uuid,
          ...variables,
        },
      });
    } else {
      await createERCFormDraft({
        variables: {
          applicationFormSource: origin || window.location.href,
          ...variables,
        },
      });
    }
  };

  const isDisabled = Boolean(!isValid || loading || updateERCLoading || !isFormValuesChanged);

  return <BaseCallBackButton productLabel="ERC" isDisabledRequestCall={isDisabled} onRequestCall={clickHandler} />;
};

export default CallBackButton;
