import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
`;

export const TextWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing(2)};
  `,
);
