import { css } from '@emotion/react';
import styled from '@emotion/styled';

import type { ButtonBlockProps } from './types';
import { Alignment } from './types';

export const ButtonBlock = styled.div<ButtonBlockProps>(
  ({ theme, footerAlignment = Alignment.End, buttonCustomStyles }) => css`
    display: flex;
    flex-direction: column;
    align-items: ${footerAlignment};
    margin-top: ${theme.spacing(2)};

    button {
      ${buttonCustomStyles?.(theme)};
    }
  `,
);
