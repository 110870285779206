import { FontWeight } from 'types/theme/text';

import SecondStepIcon from 'assets/icons/cloud.svg';
import FirstStepIcon from 'assets/icons/pencil.svg';
import ThirdStepIcon from 'assets/icons/signature.svg';

import TitleLarge from 'components/ui/atoms/text/TitleLarge';
import TitleMedium from 'components/ui/atoms/text/TitleMedium';

import { FundingStepsWrapper, Point, PointsWrapper } from './styled';

const FundingSteps = (): JSX.Element => {
  // TODO: add pointText when it will be ready
  const points = [
    {
      icon: <img src={FirstStepIcon} alt="first" />,
      pointTitle: 'Enter Business Data',
      // pointText: 'At eripuit signiferumque sea, vel ad mucius molestie, cu labitur iuvaret vulputate sed.',
    },
    {
      icon: <img src={SecondStepIcon} alt="second" />,
      pointTitle: 'Upload bank statements',
      // pointText: 'At eripuit signiferumque sea, vel ad mucius molestie, cu labitur iuvaret vulputate sed.',
    },
    {
      icon: <img src={ThirdStepIcon} alt="third" />,
      pointTitle: 'Review Rates & Terms!',
      // pointText: 'At eripuit signiferumque sea, vel ad mucius molestie, cu labitur iuvaret vulputate sed.',
    },
  ];

  return (
    <FundingStepsWrapper>
      <TitleLarge fontWeight={FontWeight.Bold}>Just 3 steps to funding</TitleLarge>
      <PointsWrapper>
        {points.map(({ icon, pointTitle }, index) => (
          <Point key={index}>
            {icon}
            <TitleMedium>{pointTitle}</TitleMedium>
          </Point>
        ))}
      </PointsWrapper>
    </FundingStepsWrapper>
  );
};

export default FundingSteps;
