import type { StringSchema } from 'yup';

import { YUP_ALLOW_NULLABLE, YUP_NULLABLE_REQUIRED, YUP_STRING } from '../constants/yup';
import { ZIP_NUMBER_REGEXP } from '../regexp/zipCode';

const ZIP_NUMBER_REGEXP_TEXT = 'ZIP-code can contain digits, letters and space in the middle';
const ZIP_NUMBER_MIN_LENGTH = 5;
const ZIP_NUMBER_MIN_LENGTH_TEXT = 'ZIP-code shouldn’t be less than 5 characters';
const ZIP_NUMBER_MAX_LENGTH = 8;
const ZIP_NUMBER_MAX_LENGTH_TEXT = 'ZIP-code shouldn’t be longer than 8 characters';

const getZipValidation = (schema: StringSchema<any>) =>
  schema
    .min(ZIP_NUMBER_MIN_LENGTH, ZIP_NUMBER_MIN_LENGTH_TEXT)
    .max(ZIP_NUMBER_MAX_LENGTH, ZIP_NUMBER_MAX_LENGTH_TEXT)
    .matches(ZIP_NUMBER_REGEXP, ZIP_NUMBER_REGEXP_TEXT);

export const YUP_ZIP = getZipValidation(YUP_STRING);
export const YUP_NULLABLE_REQUIRED_ZIP = getZipValidation(YUP_NULLABLE_REQUIRED);
export const YUP_NULLABLE_ZIP = getZipValidation(YUP_ALLOW_NULLABLE);
