import type { ReactNode } from 'react';
import { Children } from 'react';

import { GridContainer, GridElement } from './styled';
import type { Grid as GridProps } from './types';

type Props = GridProps & {
  children: ReactNode;
};

const Grid = ({ children, gap, columns }: Props) => {
  const elements = Children.toArray(children);

  return (
    <GridContainer gap={gap}>
      {elements.map((child, i) => (
        <GridElement gap={gap} columns={columns} key={i}>
          {child}
        </GridElement>
      ))}
    </GridContainer>
  );
};

export default Grid;
