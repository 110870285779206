import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const DetailsWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${spacing(1)};
  `,
);
