import CustomNumberFormat from 'components/ui/atoms/CustomNumberFormat';
import Input from 'components/ui/atoms/forms/Input';

import { DECIMAL_COUNT, DOLLAR_PREFIX } from './config';
import { getDisplayedValue, getEvent, getExportedValue, transformToValidValue } from './helpers';
import type { BlurEventHandler, ChangeEventHandler, MoneyInputProps } from './types';

// work with cents but display dollars
const MoneyInput = ({
  value,
  onChange,
  onBlur,
  isNoneNullable,
  allowNegative,
  isNumericString,
  thousandSeparator,
  prefix,
  decimalScale,
  ...props
}: MoneyInputProps): JSX.Element => {
  const handleChange: ChangeEventHandler = (formatValues, { event }) => {
    if (!event) return;

    const exportedValue = getExportedValue(transformToValidValue(formatValues.floatValue));
    onChange?.(getEvent(event, exportedValue, isNoneNullable));
  };

  const handleBlur: BlurEventHandler = (event) => {
    onBlur?.(getEvent(event, transformToValidValue(value), isNoneNullable));
  };

  return (
    <Input
      value={getDisplayedValue(value)}
      onBlur={handleBlur}
      inputComponent={CustomNumberFormat}
      inputProps={{
        onValueChange: handleChange,
        isNumericString: isNumericString ?? true,
        thousandSeparator: thousandSeparator ?? true,
        prefix: prefix ?? DOLLAR_PREFIX,
        decimalScale: decimalScale ?? DECIMAL_COUNT,
        allowNegative: allowNegative ?? false,
      }}
      {...props}
    />
  );
};

export default MoneyInput;
