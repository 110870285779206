import type { FormField } from 'components/shared/organisms/form/FormSection/types';
import MoneyInput from 'components/ui/atoms/forms/MoneyInput';

import type { CreateRealEstateFormValues } from '../mutationsValues';

export const refinanceFields: FormField<CreateRealEstateFormValues>[] = [
  {
    id: 'currentMortgageBalance',
    name: 'currentMortgageBalance',
    label: 'Current Mortgage balance on the property ($)',
    control: <MoneyInput />,
  },
  {
    id: 'desiredLoanAmount',
    name: 'desiredLoanAmount',
    label: 'Desired Loan Amount ($)',
    control: <MoneyInput />,
  },
];
