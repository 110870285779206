import { Global, ThemeProvider as StyledThemeProvider } from '@emotion/react';
import { StyledEngineProvider, ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';

import globalStyles from 'styles/globalStyles';
import DefaultTheme from 'styles/themes/defaultTheme';

import { THEMES } from './themes';
import type { ThemeProviderProps } from './types';

const ThemeProvider = ({ children, themeType }: ThemeProviderProps) => {
  const theme = themeType ? THEMES[themeType] : DefaultTheme;

  return (
    <StyledThemeProvider theme={theme}>
      <MaterialThemeProvider theme={theme}>
        <Global styles={globalStyles} />
        <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
      </MaterialThemeProvider>
    </StyledThemeProvider>
  );
};

export default ThemeProvider;
