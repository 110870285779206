import { useContext } from 'react';

import NotifierContext from 'contexts/NotifierContext';

const useNotifier = () => {
  const { notifierList, setError, setSuccess, setInfo, clearMessage } = useContext(NotifierContext);
  return { notifierList, setError, setSuccess, setInfo, clearMessage };
};

export default useNotifier;
