import { css } from '@emotion/react';
import styled from '@emotion/styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled as muiStyled } from '@mui/material/styles';

export const StyledRadioGroup = muiStyled(RadioGroup)(() => ({
  flexDirection: 'row',
}));

export const StyledRadioCardGroup = muiStyled(StyledRadioGroup)(
  ({
    theme: {
      spacing,
      breakpoints: { values: breakpoints, down },
    },
  }) => ({
    gap: spacing(3),
    [down(breakpoints.md)]: {
      flexDirection: 'column',
    },
  }),
);

export const StyledRadioButtonLabel = muiStyled(FormControlLabel)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  verticalAlign: 'middle',
  flexDirection: 'row',
  margin: 0,

  '& .MuiFormControlLabel-label': {
    color: theme.palette.text.primary,
    marginBottom: 0,

    ...theme.typography.t2,
  },
}));

export const StyledRadioCardLabel = styled(StyledRadioButtonLabel)(
  ({ theme: { shape, spacing, palette }, checked }) => css`
    display: flex;
    flex: 1;
    padding: ${spacing(3)};
    box-shadow: ${checked ? `0 4px 8px ${palette.border}` : `0 0 0 1px ${palette.border}`};
    border-radius: ${shape.borderRadius}px;
  `,
);

export const StyledRadio = muiStyled(Radio)(({ theme }) => ({
  color: theme.palette.primary.light,
  '&.Mui-checked': {
    color: theme.palette.primary.main,
  },
}));
