import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CallBackButtonBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const DescriptionWrapper = styled.div(
  ({ theme: { spacing } }: any) => css`
    margin-bottom: ${spacing(2)};
  `,
);

export const ButtonBlock = styled.div(
  ({
    theme: {
      spacing,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    margin-top: ${spacing(4)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: ${spacing(4)};

    ${down(breakpoints.md)} {
      flex-direction: column;
    }
  `,
);

export const ButtonWrapper = styled.div(
  ({
    theme: {
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    display: flex;
    justify-content: flex-end;
    min-width: 150px;

    ${down(breakpoints.md)} {
      width: 100%;
    }
  `,
);

export const AgreeBlock = styled.div(
  ({
    theme: {
      typography,
      spacing,
      palette,
      breakpoints: { values: breakpoints, down },
    },
  }: any) => css`
    ${typography.t2};
    line-height: 20px;
    display: flex;
    column-gap: ${spacing(2)};

    ${down(breakpoints.md)} {
      margin-bottom: ${spacing(2)};
      max-width: none;
    }

    a {
      color: ${palette.primary.main};

      &:hover {
        opacity: 0.5;
      }
    }
  `,
);
