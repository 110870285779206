import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ERC, EXAMPLES } from 'config/routes';
import { DEV } from 'config/vars';

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => navigate(DEV ? EXAMPLES : ERC), []);

  return null;
};

export default Home;
