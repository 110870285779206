import { NotifierProvider } from 'contexts/NotifierContext';
import ThemeProvider from 'contexts/ThemeContext';

import DefaultTemplate from 'components/templates/DefaultTemplate';
import Notifier from 'components/ui/atoms/Notifier';

import ERCForm from './components/form/ERCForm';
import Header from './components/Header';

const ERC = () => {
  return (
    <ThemeProvider>
      <NotifierProvider>
        <DefaultTemplate>
          <Header />
          <ERCForm />
          <Notifier />
        </DefaultTemplate>
      </NotifierProvider>
    </ThemeProvider>
  );
};

export default ERC;
