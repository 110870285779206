import { useMemo } from 'react';

import useRealEstateFormQuery from 'api/query/useRealEstateFormQuery';

import { DEFAULT_VALUES } from './config/form';

const useRealEstateFormValues = ({ uuid }: { uuid: string | null }) => {
  const { data, loading } = useRealEstateFormQuery({
    variables: {
      // @ts-expect-error uuid can be null, so we skip in this case
      uuid,
    },
    skip: !uuid,
  });

  const formValues = useMemo(() => {
    return Object.entries(DEFAULT_VALUES).reduce<Record<string, any>>((reduceResult, [key, value]) => {
      reduceResult[key] = data?.[key as keyof typeof data] || value;

      return reduceResult;
    }, {});
  }, [data]);

  return {
    data: formValues,
    loading,
  };
};

export default useRealEstateFormValues;
