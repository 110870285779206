import { StyledInput } from './styled';

const MultilineInput = ({ submitForm, onKeyPress, maxRows = 20, ...props }) => {
  const keydownHandler = (event) => {
    if (['\n', 'Enter'].includes(event.key)) {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
        event.stopPropagation();
        submitForm?.();
      } else {
        onKeyPress?.(event);
      }
    }
  };

  return <StyledInput multiline minRows={4} maxRows={maxRows} {...props} onKeyPress={keydownHandler} />;
};

export default MultilineInput;
