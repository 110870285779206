import { useEffect } from 'react';

import useMakeFinancingRequestMutation from 'api/mutations/calculator/useMakeFinancingRequestMutation';

import { TextColor } from 'types/theme/text';

import { FlexContainer, FlexElement, FlexTable } from 'components/templates/Grid/ResponsiveFlexGrid';
import Button from 'components/ui/atoms/buttons/Button';
import Icon, { Icons } from 'components/ui/atoms/Icon';
import CaptionSmall from 'components/ui/atoms/text/CaptionSmall';
import TextField from 'components/ui/atoms/TextField';

import { CalculatorValues } from '../FormContainer/config';
import { DEFAULT_TEXT, FinancingRequestStatus, SUBMITTED_TEXT } from './config';
import { ButtonWrapper, IconWrapper, PaymentNumber, StyledTextField, Wrapper } from './styled';
import type { FinancingRate, ResultFormProps } from './types';

const ResultForm = ({ data, termUnitText, values, isSubmitted, setIsSubmitted }: ResultFormProps) => {
  const { uuid, paymentAmount, totalPaybackAmount, financingRates, canRequest, status } = data ?? {};

  const [makeFinancingRequest, { loading }] = useMakeFinancingRequestMutation({
    onCompleted: () => setIsSubmitted(true),
  });

  useEffect(() => {
    setIsSubmitted(status === FinancingRequestStatus.Requested);
  }, [status]);

  const handleSubmit = async () => {
    await makeFinancingRequest({
      variables: { uuid },
    });
  };

  return (
    <Wrapper>
      <FlexTable>
        <FlexContainer>
          <FlexElement>
            <TextField
              label={`${termUnitText.adverb} Payment`}
              value={paymentAmount?.displayString}
              textField={<PaymentNumber />}
            />
          </FlexElement>
        </FlexContainer>
        <FlexContainer>
          <FlexElement minWidth="100px">
            <TextField
              label="Term"
              value={`${values[CalculatorValues.RequestedTermUnitSize]} ${termUnitText.plural.toLowerCase()}`}
              textField={<StyledTextField />}
            />
          </FlexElement>
          <FlexElement minWidth="100px">
            <TextField label="Payback" value={totalPaybackAmount?.displayString} textField={<StyledTextField />} />
          </FlexElement>
        </FlexContainer>

        <FlexContainer>
          {financingRates?.map(({ id, termUnitSize, payoffAmount }: FinancingRate) =>
            termUnitSize <= values[CalculatorValues.RequestedTermUnitSize] ? (
              <FlexElement minWidth="100px" key={id}>
                <TextField
                  label={`${termUnitSize} ${termUnitText.plural} Payoff`}
                  value={payoffAmount?.displayString}
                  textField={<StyledTextField />}
                />
              </FlexElement>
            ) : null,
          )}
        </FlexContainer>
      </FlexTable>
      {!isSubmitted ? (
        <>
          <ButtonWrapper>
            <Button variant="contained" size="large" disabled={loading || !canRequest?.value} onClick={handleSubmit}>
              Request the Agreement
            </Button>
          </ButtonWrapper>
          <CaptionSmall>{DEFAULT_TEXT}</CaptionSmall>
        </>
      ) : (
        <>
          <ButtonWrapper>
            <IconWrapper>
              <Icon type={Icons.Check} size={26} />
            </IconWrapper>
          </ButtonWrapper>
          <CaptionSmall textColor={TextColor.ContrastText}>{SUBMITTED_TEXT}</CaptionSmall>
        </>
      )}
    </Wrapper>
  );
};

export default ResultForm;
