import { NotifierProvider } from 'contexts/NotifierContext';
import ThemeProvider from 'contexts/ThemeContext';

import DefaultTemplate from 'components/templates/DefaultTemplate';

import Form from './components/Form';
import Header from './components/Header';

const Ccp = () => {
  return (
    <ThemeProvider>
      <NotifierProvider>
        <DefaultTemplate>
          <Header />
          <Form />
        </DefaultTemplate>
      </NotifierProvider>
    </ThemeProvider>
  );
};

export default Ccp;
