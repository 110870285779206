import type { MutationTuple } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

const CreateShortApplicationFormMutation = loader(
  '../../../graphql/mutations/oneStepForm/createShortApplicationForm.graphql',
);

type Props = {
  onCompleted: (data: any) => void;
  onError: (error: any) => void;
};

const useCreateOneStepFormMutation = ({ onCompleted, onError }: Props) => {
  const [createOneStepForm, mutationResult] = useMutation(CreateShortApplicationFormMutation, { onCompleted, onError });

  return [createOneStepForm, mutationResult] as MutationTuple<any, any>;
};

export default useCreateOneStepFormMutation;
