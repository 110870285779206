import { css } from '@emotion/react';
import styled from '@emotion/styled';

import IconButton from 'components/ui/atoms/buttons/IconButton';

import type { AlertContainerProps, AlertTextProps, PendingAnimationProps } from './types';

export const Wrapper = styled.div(
  ({ theme: { shape, spacing } }) => css`
    position: relative;
    margin-top: ${spacing(1)};
    overflow: hidden;
    border-radius: ${shape.borderRadius}px;
    z-index: 0;
  `,
);

export const Container = styled.div<AlertContainerProps>(
  ({ schema, pending, theme: { spacing } }) => css`
    background-color: ${pending ? 'transparent' : schema.bgColor};
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: ${spacing(1, 2)};
  `,
);

export const Text = styled.span<AlertTextProps>(
  ({ schema, noWrap }) => css`
    width: 100%;
    color: ${schema.color};

    ${noWrap &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  `,
);

export const PendingAnimation = styled.div<PendingAnimationProps>(
  ({ schema }) => css`
    position: absolute;
    inset: 0 0 0 -46px;

    @keyframes move-bg {
      from {
        transform: translateX(0);
      }

      to {
        transform: translateX(46px);
      }
    }

    background: repeating-linear-gradient(
      -55deg,
      transparent 1px,
      ${schema.bgColor} 2px,
      ${schema.bgColor} 11px,
      transparent 12px,
      transparent 20px
    );
    animation-name: move-bg;
    animation-duration: 0.6s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  `,
);

export const IconWrapper = styled.span(
  ({ theme: { spacing } }) => css`
    margin-right: ${spacing(2)};
  `,
);

export const StyledIconButton = styled(IconButton)(
  ({ theme: { spacing } }) => css`
    margin-left: ${spacing(2)};
  `,
);

export const AlertWrapper = styled('div')(
  ({ theme: { spacing } }) => css`
    width: 100%;
    margin: ${spacing(1, 0)};
  `,
);
