import type { FormControlLabelProps } from '@mui/material/FormControlLabel';
import type { RadioGroupProps as MUIRadioGroupProps } from '@mui/material/RadioGroup';

import type { Event as BaseEvent, EventHandler as BaseEventHandler } from 'types/eventTypes';

export enum RadioButtonKinds {
  Card = 'card',
  Usual = 'usual',
}

export type EventValue = string | boolean;
export type Event = BaseEvent<EventValue>;
export type EventHandler = BaseEventHandler<EventValue>;

export type RadioItemProps = Pick<FormControlLabelProps, 'value' | 'label' | 'disabled' | 'name'> & {
  type?: RadioButtonKinds;
};

export type RadioGroupProps = Omit<MUIRadioGroupProps, 'onChange'> & {
  onChange?: EventHandler;
  radioItems?: Array<RadioItemProps>;
  type?: RadioButtonKinds;
};
