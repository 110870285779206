import { useSearchParams } from 'react-router-dom';

import { DocumentGroup } from 'api/__generated__/enums';
import useCreateCcpFormMutation from 'api/mutations/ccpForm/useCreateCcpFormMutation';

import { ORIGIN } from 'config/constants/queryParams';

import useDocumentName from 'hooks/useDocumentName';
import useNotifier from 'hooks/useNotifier';
import useReferralId from 'hooks/useReferralId';

import getCcpReferralIdFromOrigin from '../../../getCcpFormReferralIdFromOrigin';
import type { CreateFormProps, UseCreateCcpFormProps } from '../types';

const useCreateCCPForm = ({ setIsSubmitted, onCompleted }: UseCreateCcpFormProps) => {
  const { setError, setSuccess } = useNotifier();
  const getDocumentName = useDocumentName();

  const [searchParams] = useSearchParams();
  const referralId = useReferralId(getCcpReferralIdFromOrigin);
  const origin = searchParams.get(ORIGIN);

  const [createCcpForm, { loading }] = useCreateCcpFormMutation({
    onCompleted: () => {
      setSuccess('Short application form was submitted successfully!');
      setIsSubmitted(true);
      onCompleted?.();
    },
    onError: (error) => {
      setError(`Form error: ${error}`);
    },
  });

  const createForm = async ({ fields, uploadedBankStatements }: CreateFormProps) => {
    const documents = Object.values(uploadedBankStatements).map((file) => ({
      file,
      group: DocumentGroup.BankStatement,
      name: getDocumentName(DocumentGroup.BankStatement),
    }));

    await createCcpForm({
      variables: {
        ...fields,
        userReferralId: referralId,
        applicationFormSource: origin || window.location.href,
        documentsAttributes: documents,
      },
    });
  };

  return { createForm, loading };
};

export default useCreateCCPForm;
