import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { BrowseLink, Wrapper } from './styled';
import type { DropzoneProps } from './types';

const Dropzone = ({ onFilesChanged, text = 'Drop a file or', fileInputProps }: DropzoneProps) => {
  const onDrop = useCallback((acceptedFiles: File[]) => onFilesChanged(acceptedFiles), []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...fileInputProps,
  });

  return (
    <Wrapper {...getRootProps()}>
      <input {...getInputProps()} />
      <p>
        {text} <BrowseLink>browse</BrowseLink>
      </p>
    </Wrapper>
  );
};

export default Dropzone;
