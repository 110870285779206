import PageTitle from 'components/ui/atoms/text/PageTitle';

import { Container, TextWrapper } from './styled';

const Header = () => (
  <Container>
    <PageTitle>Credit Card Processing</PageTitle>
    <TextWrapper>
      <p>Thank you for placing your trust in Premium Merchant Funding.</p>
      <p>
        Please complete the form below, and a specialist will be in touch shorty. We look forward to saving you money on
        your credit card processing.
      </p>
    </TextWrapper>
  </Container>
);

export default Header;
