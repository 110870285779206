import type { Theme } from '@mui/material/styles';

export type Sign = string | null;
type CustomTarget = { target: { value: Sign; name: string } };

export const OWNER = 'owner';
export const PARTNER = 'partner';
export type HolderTypes = typeof OWNER | typeof PARTNER;

export type SignatureProps<S = Sign> = {
  theme: Theme;
  holderType?: HolderTypes;
  name?: string;
  helperText?: string | null;
  value?: S;
  width?: number;
  height?: number;
  error?: boolean;
  // to update value in form state
  onChange?: (customTarget: CustomTarget) => void;
  // to update `signature` state in useSignature hook
  setSignature?: (newValue: S, holderType: HolderTypes) => void;
};
