import Input from 'components/ui/atoms/forms/Input';

import CustomIMaskInput from '../MaskInput/CustomIMaskInput';
import type { NumberInputProps } from './types';

const NumberInput = ({
  value,
  name,
  onChange,
  onBlur,
  negative,
  isInteger,
  inputProps,
  ...props
}: NumberInputProps) => {
  const handleAccept = (newValue: number, mask: any) => {
    onChange?.({
      target: {
        name,
        // eslint-disable-next-line no-underscore-dangle
        value: mask._value !== '' ? newValue : null,
      },
    });
  };

  const handleBlur = () => onBlur?.({ target: { name, value } });

  return (
    <Input
      value={value}
      inputComponent={CustomIMaskInput}
      name={name}
      onBlur={handleBlur}
      inputProps={{
        unmask: 'typed',
        mask: Number,
        radix: '.',
        signed: negative ?? 'false',
        scale: isInteger ? 0 : 10,
        onAccept: handleAccept,
        ...inputProps,
      }}
      {...props}
    />
  );
};

export default NumberInput;
