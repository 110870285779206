import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const FormWrapper = styled.div(
  ({
    theme: {
      spacing,
      palette,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    width: 530px;
    padding: ${spacing(0.5, 4.75, 5.625)};
    border-radius: 4px;
    background-color: ${palette.primary.contrastText};
    box-shadow: 0 16px 24px rgb(0 0 0 / 16%), 0 2px 6px rgb(0 0 0 / 4%), 0 0 1px rgb(0 0 0 / 4%);

    ${down(breakpoints.md)} {
      width: 100%;
      border-radius: 0;

      h2 {
        text-align: center;
      }
    }
  `,
);

export const FormFooterWrapper = styled.div`
  text-align: center;
`;
