import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import useCreateERCFormMutation from 'api/mutations/erc/useCreateERCFormV2Mutation';
import useUpdateERCFormMutation from 'api/mutations/erc/useUpdateERCFormV2Mutation';

import { ORIGIN, THANK_YOU_PAGE_URL, UUID } from 'config/constants/queryParams';

import useNotifier from 'hooks/useNotifier';
import useReferralId from 'hooks/useReferralId';
import openThirdPartyLink from 'utils/openThirdPartyLink';

import FormSectionMediator from 'components/shared/molecules/FormSectionMediator';
import SubmitMessage from 'components/shared/organisms/form/SubmitMessage';
import RedirectingMessage from 'components/ui/atoms/messages/RedirectingMessage';
import type { SubmitHandler } from 'components/ui/molecules/FormWithValidation';
import FormWithValidation from 'components/ui/molecules/FormWithValidation';
import Loader from 'components/ui/molecules/Loader';

import { requiredValidationFields } from '../../config/config';
import ercFormFields from '../../config/ercFormFields';
import getErcReferralIdFromOrigin from '../../getErcReferralIdFromOrigin';
import useERCFormValues from '../../hook/useERCFormValues';

const ERCForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showRedirecting, setShowRedirecting] = useState(false);
  const [onCompleted, setOnCompleted] = useState<(() => void) | undefined>(undefined);

  const { setSuccess, setError } = useNotifier();

  const [searchParams] = useSearchParams();
  const uuid = searchParams.get(UUID);
  const origin = searchParams.get(ORIGIN);
  const thankYouPage = searchParams.get(THANK_YOU_PAGE_URL);
  const referralId = useReferralId(getErcReferralIdFromOrigin);

  const { data: formValues, loading: formValuesLoading } = useERCFormValues();

  const [createERCForm, { loading }] = useCreateERCFormMutation({
    onCompleted: () => {
      setSuccess('ERC form was submitted successfully!');
      setIsSubmitted(true);
      onCompleted?.();
    },
    onError: (gqlError) => {
      setError(`Form error: ${gqlError}`);
    },
  });
  const [updateERCForm, { loading: updateFormLoading }] = useUpdateERCFormMutation({
    onCompleted: () => {
      setSuccess('ERC form was updated successfully!');
      setIsSubmitted(true);
    },
    onError: (gqlError) => {
      setError(`Form update error: ${gqlError}`);
    },
  });

  const handleSubmit: SubmitHandler = async (fields) => {
    const {
      businessEmployeesCount,
      receivedPpp2,
      submittedForgivenessAppPppLoan,
      shutDown2020,
      quartersBusinessShutDown,
      quarterWithDates,
      ...otherFields
    } = fields ?? {};
    const isShutDown2020 = shutDown2020 === 'true';
    const isReceivedPpp2 = receivedPpp2 === 'true';
    const variables = {
      ...otherFields,
      businessEmployeesCount: Number(businessEmployeesCount || 0),
      receivedPpp2: isReceivedPpp2,
      submittedForgivenessAppPppLoan: isReceivedPpp2 ? submittedForgivenessAppPppLoan === 'true' : null,
      shutDown2020: isShutDown2020,
      quartersBusinessShutDown: isShutDown2020 ? quartersBusinessShutDown : null,
      quarterWithDates: isShutDown2020 ? quarterWithDates : null,
      userReferralId: referralId,
    };

    setOnCompleted(() => {
      if (thankYouPage) {
        const { openLink, isSameDomainLink } = openThirdPartyLink(thankYouPage);
        openLink();
        if (isSameDomainLink) setShowRedirecting(true);
        else setIsSubmitted(true);
      }
    });

    if (uuid) {
      await updateERCForm({
        variables: {
          uuid,
          ...variables,
        },
      });
    } else {
      await createERCForm({
        variables: {
          applicationFormSource: origin || window.location.href,
          ...variables,
        },
      });
    }
  };

  if (showRedirecting)
    return (
      <Loader>
        <RedirectingMessage />
      </Loader>
    );

  if (isSubmitted) return <SubmitMessage />;

  return (
    <FormWithValidation
      id="erc-form"
      resetOnSubmit
      loading={loading || formValuesLoading || updateFormLoading}
      defaultValues={formValues}
      requiredValidationFields={requiredValidationFields}
      submitHandler={handleSubmit}
    >
      {ercFormFields.map((formSection) => (
        <FormSectionMediator key={formSection.id} {...formSection} />
      ))}
    </FormWithValidation>
  );
};

export default ERCForm;
