import { GridElement } from 'components/templates/Grid';
import type { FormControlProps } from 'components/ui/molecules/FormControl';
import FormControl from 'components/ui/molecules/FormControl';

type GridFormElementPros = FormControlProps & {
  value: any;
  columns?: number;
};

const GridFormElement = ({ columns, ...props }: GridFormElementPros) => {
  return (
    <GridElement columns={columns}>
      <FormControl {...props} />
    </GridElement>
  );
};

export default GridFormElement;
