import { StyledTitle } from './styled';
import type { TitleSmallProps } from './types';

const TitleSmall = ({
  children,
  disabled,
  textCase,
  textColor,
  fontWeight,
  marginBottom,
  dataTestId,
}: TitleSmallProps) => (
  <StyledTitle
    disabled={disabled}
    textCase={textCase}
    textColor={textColor}
    marginBottom={marginBottom}
    fontWeight={fontWeight}
    data-testid={dataTestId}
  >
    {children}
  </StyledTitle>
);

export default TitleSmall;
