import { useCallback } from 'react';

import { Margin, TextColor } from 'types/theme/text';

import CaptionSmall from 'components/ui/atoms/text/CaptionSmall';
import PageTitle from 'components/ui/atoms/text/PageTitle';

import SliderFields from './SliderFields';
import type { SliderFormProps } from './types';

const SliderForm = ({ data, termUnitText, setValues, values, handleUpdate, isSubmitted }: SliderFormProps) => {
  const { fixedRate, maxTermUnitSize, maxFinancingAmount, createdByFullName, merchantFullName, canPublicUpdate } =
    data ?? {};

  const onChangeCommitted = useCallback(() => {
    handleUpdate(values);
  }, [values]);

  const isDisabled = isSubmitted || !canPublicUpdate?.value;

  return (
    <>
      <PageTitle textColor={TextColor.PrimaryBlue} marginBottom={Margin.Zero}>
        {`${merchantFullName ? `${merchantFullName}'s ` : ''}Financing Calculator`}
      </PageTitle>
      <CaptionSmall>
        {`Your funding specialist, ${
          createdByFullName ? `${createdByFullName},` : ''
        } sent you our financing calculator.
        Please enter the term and financing amount you are requesting, or use the sliders below to adjust the details.`}
      </CaptionSmall>
      <SliderFields
        values={values}
        setValues={setValues}
        termUnitText={termUnitText}
        maxTermUnitSize={maxTermUnitSize}
        maxFinancingAmount={maxFinancingAmount}
        onChangeCommitted={onChangeCommitted}
        disabled={isDisabled}
      />
      {fixedRate ? <CaptionSmall>{`Rate ${fixedRate} (fixed)`}</CaptionSmall> : null}
    </>
  );
};

export default SliderForm;
