import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { Theme } from '@mui/material/styles';

import Button from 'components/ui/atoms/buttons/Button';

export const StepperContainer = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: ${colors.blueLight};

    /* stylelint-disable-next-line selector-class-pattern */
    .MuiStepper-root {
      max-width: 1000px;
    }
  `,
);

export const StepContentContainer = styled.main(
  ({
    theme: {
      spacing,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    padding: ${spacing(0, 4, 10)};

    ${down(breakpoints.md)} {
      padding: ${spacing(0, 2, 5)};
    }
  `,
);

export const StepContentWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    width: 100%;
    margin-top: ${spacing(4)};
  `,
);

export const buttonCustomStyles = ({ spacing, margin = 0 }: Theme & { margin?: number }) => css`
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${spacing(margin)} auto;
  padding: ${spacing(2, 4)};
  font-size: 16px;
`;

export const FormSubmitButton = styled(Button)(
  ({ theme }) => css`
    ${buttonCustomStyles({ ...theme, margin: 2 })}
  `,
);
