import type { FormField } from 'components/shared/organisms/form/FormSection/types';
import FormCheckbox from 'components/ui/atoms/forms/Checkbox/FormCheckbox';
import NumberInput from 'components/ui/atoms/forms/NumberInput';

import type { CreateRealEstateFormValues } from '../mutationsValues';

export const loanTermsFields: FormField<CreateRealEstateFormValues>[] = [
  {
    id: 'termYears',
    name: 'loanTerm',
    label: 'Term (Years)',
    // @ts-expect-error providing props through form
    control: <NumberInput />,
  },
  {
    id: 'amortizationYears',
    name: 'amortisation',
    label: 'Amortization (Years)',
    // @ts-expect-error providing props through form
    control: <NumberInput />,
  },
  {
    id: 'personalGuarantee',
    name: 'personalGuarantee',
    // @ts-expect-error providing props through form
    control: <FormCheckbox label="Personal Guarantee" />,
  },
];
