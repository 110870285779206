import usePresignDataMutation from 'api/mutations/usePresignDataMutation';

import { getFileToUpload } from 'lib/utils/file';

export const useFileSize = (props) => {
  const { sizeLimitMb = 10 } = props || {};
  const sizeLimit = sizeLimitMb * 1024 * 1024;

  const checkSize = (size) => {
    if (size > sizeLimit) throw new Error([`File exceeded the size limit ${sizeLimitMb} Mb`]);
  };

  return {
    checkSize,
  };
};

export const uploadFile = async ({ fields, url }, file) => {
  if (fields.length > 0 && url) {
    const [storage, id] = fields.find(({ key }) => key === 'key').value.split('/');

    // eslint-disable-next-line no-undef
    const formData = new FormData();
    fields.forEach(({ key, value }) => {
      formData.append(key, value);
    });
    formData.append('file', file);

    try {
      const { ok } = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      if (!ok) {
        throw new Error('File not uploaded');
      }

      return {
        storage,
        id,
        metadata: {
          size: file.size,
          filename: file.name,
          mimeType: file.type,
        },
      };
    } catch (e) {
      return Promise.reject(e);
    }
  }

  return undefined;
};

export const usePresignFile = ({ sizeLimitMb }) => {
  const [mutation, mutationState] = usePresignDataMutation();
  const { checkSize } = useFileSize({ sizeLimitMb });

  const mutate = async (file) => {
    const { type, name: filename, size } = file || {};
    if (!type || !filename) return { fields: [], url: '' };
    checkSize(size);

    const {
      data: { presignData: fileData },
    } = await mutation({ variables: { type, filename } });
    return fileData;
  };

  return [mutate, mutationState];
};

const useUpload = (props) => {
  const { sizeLimitMb } = props || {};
  const [presign] = usePresignFile({ sizeLimitMb });
  const upload = async (file) => {
    const fileToUpload = getFileToUpload(file);

    const presigned = await presign(fileToUpload);
    const uploaded = await uploadFile(presigned, fileToUpload);
    return uploaded;
  };

  return { upload };
};

export default useUpload;
