import { withTheme } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { formatToISOStringDateOnly } from 'lib/utils/date';

import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg';

import { StyledDatePicker } from './styled';

const KeyboardDatePicker = ({ onChange, onlyDate = true, value, placeholder, error, ...props }) => {
  const handleChange = (date) => onChange(onlyDate ? formatToISOStringDateOnly(date) : date);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDatePicker
        onChange={handleChange}
        value={value ? dayjs(value) : null}
        slots={{
          openPickerIcon: IconCalendar,
        }}
        slotProps={{
          openPickerButton: {
            color: 'primary',
          },
          textField: { placeholder, error },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default withTheme(KeyboardDatePicker);
