import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Margin, TextCase } from 'types/theme/text';

import type { CaptionSmallProps } from './types';

export const StyledCaption = styled.span<
  Pick<CaptionSmallProps, 'disabled' | 'textCase' | 'textColor' | 'marginBottom'>
>(({ theme: { palette, typography, spacing }, textCase, textColor, marginBottom, disabled }) => {
  const notDisabledTextColor = textColor ? palette.text[textColor] : palette.text.secondary;

  return css`
    ${typography.subtitle};
    text-transform: ${textCase ?? TextCase.None};
    color: ${disabled ? palette.text.disabled : notDisabledTextColor};
    ${marginBottom &&
    css`
      display: block;
      margin: 0 0 ${spacing(marginBottom ?? Margin.Zero)};
    `}
  `;
});
