import type { ReactNode } from 'react';
import type { SerializedStyles } from '@emotion/react';
import type { Theme } from '@mui/material/styles';

export enum Alignment {
  Stretch = 'stretch',
  Center = 'center',
  Start = 'flex-start',
  End = 'flex-end',
}

export type FormFooterProps = {
  disabled: boolean;
  loading?: boolean;
  submitHandler: () => void;
  buttonConfirmationText?: string | ReactNode;
  buttonText?: string;
  footerAlignment?: Alignment;
  brandedPage?: boolean;
  buttonCustomStyles?: (theme: Theme) => SerializedStyles;
};

export type ButtonBlockProps = Pick<FormFooterProps, 'footerAlignment' | 'buttonCustomStyles'>;
