import FormLabel from '@mui/material/FormLabel';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

const styles = ({ theme }: { theme: Theme }) => ({
  ...theme.typography.t3,
  color: theme.palette.text.secondary,
  marginBottom: 0,

  '&.Mui-error, &.Mui-focused, &.Mui-disabled': {
    color: theme.palette.text.secondary,
  },

  '& .MuiFormLabel-asterisk	': {
    color: theme.colors.red,
  },

  '& + .MuiInput-formControl': {
    marginTop: '0',
  },
});

const StyledLabel = styled(FormLabel)(styles);

export default StyledLabel;
