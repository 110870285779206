import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import type { CreateRealEstateFormMutationVariables } from 'api/mutations/realEstate/useCreateRealEstateFormMutation';
import useCreateRealEstateFormMutation from 'api/mutations/realEstate/useCreateRealEstateFormMutation';
import type { UpdateRealEstateFormMutationVariables } from 'api/mutations/realEstate/useUpdateRealEstateFormMutation';
import useUpdateRealEstateFormMutation from 'api/mutations/realEstate/useUpdateRealEstateFormMutation';

import { ORIGIN, UUID } from 'config/constants/queryParams';

import useNotifier from 'hooks/useNotifier';
import useReferralId from 'hooks/useReferralId';
import useSaveDraftButtonClick from 'hooks/useSaveDraftButtonClick';

import BaseCallBackButton from 'components/shared/molecules/BaseCallBackButton';

import getRealEstateFormReferralIdFromOrigin from '../../getRealEstateFormReferralIdFromOrigin';
import { saveForLaterValidationFields } from '../config/saveForLaterValidationFields';

type CallBackButtonProps = {
  validatedFields:
    | Omit<CreateRealEstateFormMutationVariables, 'applicationFormSource' | 'userReferralId' | 'savedForLater'>
    | Omit<UpdateRealEstateFormMutationVariables, 'uuid' | 'userReferralId' | 'savedForLater'>;
  isFormValuesChanged: boolean;
};

const CallBackButton = ({ validatedFields, isFormValuesChanged }: CallBackButtonProps) => {
  const [isValid, setIsValid] = useState(false);

  const [searchParams] = useSearchParams();
  const referralId = useReferralId(getRealEstateFormReferralIdFromOrigin);
  const uuid = searchParams.get(UUID);
  const origin = searchParams.get(ORIGIN);

  const { saveDraftButtonClick } = useSaveDraftButtonClick();

  const { setSuccess, setError } = useNotifier();

  const requiredValidationSchema = Yup.object().shape(saveForLaterValidationFields);

  useEffect(() => {
    requiredValidationSchema
      .validate(validatedFields)
      .then(() => setIsValid(true))
      .catch(() => setIsValid(false));
  }, [validatedFields]);

  const [createRealEstateForm, { loading: createLoading }] = useCreateRealEstateFormMutation({
    onCompleted: (data) => {
      saveDraftButtonClick({ uuid: data?.uuid });
      setSuccess('Real Estate Financing form draft was created successfully!');
    },
    onError: (gqlError) => {
      setError(`Form error: ${gqlError}`);
    },
  });

  const [updateRealEstateForm, { loading: updateFormLoading }] = useUpdateRealEstateFormMutation({
    onCompleted: () => {
      setSuccess('Real Estate Financing form updated successfully!');
    },
  });

  const clickHandler = async () => {
    const variables = {
      ...validatedFields,
      userReferralId: referralId,
      savedForLater: true,
    };

    if (uuid) {
      await updateRealEstateForm({
        variables: {
          uuid,
          ...variables,
        } as UpdateRealEstateFormMutationVariables,
      });
    } else {
      await createRealEstateForm({
        variables: {
          applicationFormSource: origin || window.location.href,
          ...variables,
        } as CreateRealEstateFormMutationVariables,
      });
    }
  };

  const isDisabled = Boolean(!isValid || createLoading || updateFormLoading || !isFormValuesChanged);

  return (
    <BaseCallBackButton productLabel="Real estate" isDisabledRequestCall={isDisabled} onRequestCall={clickHandler} />
  );
};

export default CallBackButton;
