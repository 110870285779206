import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const HeaderContainer = styled.header(
  ({
    theme: {
      spacing,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    position: absolute;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${spacing(0, 6)};

    ${down(breakpoints.md)} {
      height: 72px;
      padding: ${spacing(0, 3)};
    }
  `,
);

export const HelperText = styled.p(
  ({
    theme: {
      palette,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    margin-left: auto;
    color: ${palette.primary.contrastText};

    ${down(breakpoints.sm)} {
      font-size: 14px;
    }
  `,
);

export const StyledLink = styled.a(
  ({ theme: { palette } }) => css`
    color: ${palette.primary.contrastText};
  `,
);

export const HeaderLogo = styled.img(
  ({
    theme: {
      spacing,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    margin-right: ${spacing(2)};

    ${down(breakpoints.md)} {
      height: 32px;
    }
  `,
);
