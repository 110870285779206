import { loader } from 'graphql.macro';

import type {
  CustomOriginalOptions,
  MutationHook,
  MutationOriginalOptions,
  MutationOriginData,
  MutationResult,
} from 'api/types/hooks/mutation';

import { useMutation } from 'lib/apollo/hooks';

import type { MutationName, UpdateRealEstateFormMutationData, UpdateRealEstateFormMutationVariables } from './types';
import { MUTATION_NAME } from './types';

const UpdateRealEstateFormMutation = loader('./updateRealEstateForm.graphql');

export const getData = (
  data: MutationOriginData<UpdateRealEstateFormMutationData, MutationName>,
): UpdateRealEstateFormMutationData => data[MUTATION_NAME];

const useUpdateRealEstateFormMutation: MutationHook<
  UpdateRealEstateFormMutationData,
  MutationName,
  UpdateRealEstateFormMutationVariables
> = (options, { getCompletedNotify, onErrorMessage, ...customOptions } = {}) => {
  const newOptions: MutationOriginalOptions<
    UpdateRealEstateFormMutationData,
    MutationName,
    UpdateRealEstateFormMutationVariables
  > = options
    ? {
        ...options,
        onCompleted: options.onCompleted ? (data) => options.onCompleted?.(getData(data)) : undefined,
      }
    : {};
  const newCustomOptions: CustomOriginalOptions<UpdateRealEstateFormMutationData, MutationName> | undefined =
    customOptions
      ? {
          ...customOptions,
          onErrorMessage: onErrorMessage ?? 'Real Estate Financing form was not sent',
          getCompletedNotify: getCompletedNotify ? (data) => getCompletedNotify?.(getData(data)) : undefined,
        }
      : undefined;

  const [updateRealEstateForm, mutationResult] = useMutation(
    UpdateRealEstateFormMutation,
    newOptions,
    newCustomOptions,
  ) as MutationResult<UpdateRealEstateFormMutationData, MutationName, UpdateRealEstateFormMutationVariables>;

  return [updateRealEstateForm, mutationResult];
};

export default useUpdateRealEstateFormMutation;
