import { useState } from 'react';

import ConfirmationText from 'components/shared/organisms/form/ConfirmationText';
import Button from 'components/ui/atoms/buttons/Button';
import type { CheckboxProps } from 'components/ui/atoms/forms/Checkbox';
import Checkbox from 'components/ui/atoms/forms/Checkbox';
import Typography from 'components/ui/atoms/Typography';

import { AgreeBlock, ButtonBlock, ButtonWrapper, CallBackButtonBlock, DescriptionWrapper } from './styled';

type BaseCallBackButtonProps = {
  productLabel: string;
  isDisabledRequestCall: boolean;
  onRequestCall: () => Promise<void>;
};

const BaseCallBackButton = ({ productLabel, isDisabledRequestCall, onRequestCall }: BaseCallBackButtonProps) => {
  const [isAgreeWithPolicy, setIsAgreeWithPolicy] = useState(true);

  const isDisabled = isDisabledRequestCall || !isAgreeWithPolicy;
  const handleAgree: CheckboxProps['onChange'] = ({ currentTarget }) => setIsAgreeWithPolicy(currentTarget.checked);

  const onHandleRequestCall = async () => {
    if (isDisabled) {
      return;
    }

    await onRequestCall();
  };

  return (
    <CallBackButtonBlock>
      <DescriptionWrapper>
        <Typography variant="t2">
          Please fill in the information above to hear back from one of our specialists, or continue to the questions
          below to check your eligibility and get started with the {productLabel} today.
        </Typography>
      </DescriptionWrapper>
      <ButtonBlock>
        <AgreeBlock>
          <Checkbox checked={isAgreeWithPolicy} onChange={handleAgree} name="agree-with-policy" />
          <ConfirmationText />
        </AgreeBlock>
        <ButtonWrapper>
          <Button
            variant="contained"
            color="primary"
            disableRipple
            disableElevation
            disabled={isDisabled}
            onClick={onHandleRequestCall}
          >
            Request a call from a specialist
          </Button>
        </ButtonWrapper>
      </ButtonBlock>
    </CallBackButtonBlock>
  );
};

export default BaseCallBackButton;
