import { DEV } from 'config/vars';

import type { FormSectionProps } from 'components/shared/organisms/form/FormSection';
import FormSection from 'components/shared/organisms/form/FormSection';

import FormSectionWithHiddenFields from './FormSectionWithHiddenFields';
import type { FormSectionMediatorProps } from './types';

const FormSectionMediator = ({
  handleDependentFormSection,
  withHiddenFields,
  handleChange,
  ...props
}: FormSectionMediatorProps) => {
  if (!handleChange) {
    if (DEV) throw new Error('handleChange prop was not provided');
    return null;
  }

  const handleFieldChange: FormSectionProps['handleChange'] = (event) => {
    handleChange(event);

    if (handleDependentFormSection) handleDependentFormSection(event);
  };

  return withHiddenFields ? (
    <FormSectionWithHiddenFields handleChange={handleFieldChange} {...props} />
  ) : (
    <FormSection handleChange={handleFieldChange} {...props} />
  );
};

export default FormSectionMediator;
