import { useEffect, useState } from 'react';

import { StyledSlider } from './styled';
import type { SliderProps, SliderValue } from './types';

const Slider = ({ value, onChange, size = 'small', ...props }: SliderProps) => {
  const [sliderValue, setSliderValue] = useState<SliderValue>(value ?? 0);

  const handleChange = (event: Event, newValue: SliderValue | number[], _activeThumb: number) => {
    onChange?.(event, newValue as SliderValue);
    setSliderValue(newValue as SliderValue);
  };

  useEffect(() => {
    setSliderValue(value ?? 0);
  }, [value]);

  return <StyledSlider value={sliderValue} onChange={handleChange} aria-label="Slider" size={size} {...props} />;
};

export default Slider;
