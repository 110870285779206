import { loader } from 'graphql.macro';

import type {
  CustomOriginalOptions,
  MutationHook,
  MutationOriginalOptions,
  MutationOriginData,
  MutationResult,
} from 'api/types/hooks/mutation';

import { useMutation } from 'lib/apollo/hooks';

import type { CreateResubscribeFormMutationData, CreateResubscribeFormMutationVariables, MutationName } from './types';
import { MUTATION_NAME } from './types';

const CreateResubscribeFormMutation = loader('./createResubscribeForm.graphql');

const getData = (
  data: MutationOriginData<CreateResubscribeFormMutationData, MutationName>,
): CreateResubscribeFormMutationData => data[MUTATION_NAME];

const useCreateResubscribeFormMutation: MutationHook<
  CreateResubscribeFormMutationData,
  MutationName,
  CreateResubscribeFormMutationVariables
> = (options, customOptions) => {
  const newOptions: MutationOriginalOptions<
    CreateResubscribeFormMutationData,
    MutationName,
    CreateResubscribeFormMutationVariables
  > = options
    ? {
        ...options,
        onCompleted: options.onCompleted ? (data) => options.onCompleted?.(getData(data)) : undefined,
      }
    : {};

  const newCustomOptions: CustomOriginalOptions<CreateResubscribeFormMutationData, MutationName> | undefined =
    customOptions
      ? {
          ...customOptions,
          onErrorMessage: customOptions?.onErrorMessage ?? 'Resubscribe form submission error',
          getCompletedNotify: customOptions?.getCompletedNotify
            ? (data) => customOptions?.getCompletedNotify?.(getData(data))
            : undefined,
        }
      : undefined;

  const [createResubscribeForm, mutationResult] = useMutation(
    CreateResubscribeFormMutation,
    newOptions,
    newCustomOptions,
  ) as MutationResult<CreateResubscribeFormMutationData, MutationName, CreateResubscribeFormMutationVariables>;

  return [createResubscribeForm, mutationResult];
};

export default useCreateResubscribeFormMutation;
