import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import useShortApplicationFormQuery from 'api/query/useShortApplicationFormQuery';

import { UUID } from 'config/constants/queryParams';

import { DEFAULT_VALUES } from '../config';

const useOneStepFormValues = () => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get(UUID);

  const { data: shortApplicationForm, loading } = useShortApplicationFormQuery({
    variables: {
      // @ts-expect-error uuid can be null, so we skip in this case
      uuid,
    },
    skip: !uuid,
  });

  const formValues = useMemo(() => {
    return Object.entries(DEFAULT_VALUES).reduce<Record<string, any>>((reduceResult, [key, value]) => {
      reduceResult[key] = shortApplicationForm?.[key as keyof typeof shortApplicationForm] || value;

      return reduceResult;
    }, {});
  }, [shortApplicationForm]);

  return {
    data: formValues,
    loading,
  };
};

export default useOneStepFormValues;
