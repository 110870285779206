import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { FontWeight, LetterSpacing, Margin, TextCase } from 'types/theme/text';

import type { TitleSmallProps } from './types';

export const StyledTitle = styled.h4<
  Pick<TitleSmallProps, 'disabled' | 'textCase' | 'textColor' | 'fontWeight' | 'letterSpacing' | 'marginBottom'>
>(
  ({
    theme: { spacing, palette, typography },
    disabled,
    textCase,
    textColor,
    fontWeight,
    letterSpacing,
    marginBottom,
  }) => {
    const notDisabledTextColor = textColor ? palette.text[textColor] : palette.text.primary;
    return css`
      ${typography.t2};
      height: 24px;
      margin: 0 0 ${spacing(marginBottom ?? Margin.Zero)};
      font-weight: ${fontWeight ?? FontWeight.Bold};
      color: ${disabled ? palette.text.disabled : notDisabledTextColor};
      text-transform: ${textCase ?? TextCase.None};
      letter-spacing: ${letterSpacing ?? LetterSpacing.None};
    `;
  },
);
