import { css } from '@emotion/react';
import styled from '@emotion/styled';

import type typographyVariants from 'styles/typographyVariants';

type Props = {
  variant?: keyof typeof typographyVariants;
  fs?: string;
};

// TODO: it shouldn't be div. Instead create new text components
const Typography = styled.div<Props>(
  ({ theme: { typography }, variant = 't1', fs, color }) =>
    // TODO: resolve any type
    css`
      ${typography[variant] as any};
      ${fs && `font-size: ${fs}px`}
      ${color && `color: ${color}`}
    `,
);

export default Typography;
