import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import useIntakeFormQuery from 'api/query/useIntakeFormQuery';

import { UUID } from 'config/constants/queryParams';

import { DEFAULT_VALUES } from '../config';

const useIntakeFormValues = () => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get(UUID);

  const {
    refetch,
    data: intakeForm,
    loading,
  } = useIntakeFormQuery({
    variables: {
      // @ts-expect-error uuid can be null, so we skip in this case
      uuid,
    },
    skip: !uuid,
  });

  const formValues = useMemo(() => {
    return Object.entries(DEFAULT_VALUES).reduce<Record<string, any>>((reduceResult, [key, value]) => {
      reduceResult[key] = intakeForm?.[key as keyof typeof intakeForm] || value;

      return reduceResult;
    }, {});
  }, [intakeForm]);

  return {
    data: formValues,
    loading,
    refetch,
  };
};

export default useIntakeFormValues;
