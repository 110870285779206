// Function to handle adding new query parameters
import { useSearchParams } from 'react-router-dom';

const useAddingQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParamsWithAdded = (newParams: Record<string, string>) => {
    const params = new URLSearchParams(searchParams);

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(newParams)) {
      params.set(key, value);
    }

    return params;
  };

  const addQueryParams = (newParams: Record<string, string | null>) => {
    const params = new URLSearchParams(searchParams);

    // Add or update query parameters as needed
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(newParams)) {
      if (value) params.set(key, value);
    }

    // Update the search parameters
    setSearchParams(params);
  };

  return { addQueryParams, getParamsWithAdded };
};

export default useAddingQueryParams;
