import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { IntakeFormData } from 'api/query/useIntakeFormQuery';
import useIntakeFormQuery from 'api/query/useIntakeFormQuery';

import { UUID } from 'config/constants/queryParams';

type DefaultValues = Record<keyof IntakeFormData, string | null>;

const useIntakeFormValues = (defaultValues?: DefaultValues) => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get(UUID);

  const {
    refetch,
    data: intakeForm,
    loading,
  } = useIntakeFormQuery({
    variables: {
      // @ts-expect-error uuid can be null, so we skip in this case
      uuid,
    },
    skip: !uuid,
  });

  const formValues = useMemo(() => {
    if (!defaultValues) return intakeForm;

    return Object.entries(defaultValues).reduce<Record<string, any>>((reduceResult, [key, value]) => {
      const intakeFormValue = intakeForm?.[key as keyof typeof intakeForm];

      if (key === 'merchantSsn' && typeof intakeFormValue === 'string') {
        reduceResult[key] = intakeFormValue.slice(-4);
      } else {
        reduceResult[key] = intakeFormValue || value;
      }
      return reduceResult;
    }, {});
  }, [intakeForm]);

  return {
    data: formValues,
    loading,
    refetch,
  };
};

export default useIntakeFormValues;
