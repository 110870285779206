import { ACCEPT_DOCUMENT_EXTENSIONS, ACCEPT_DOCUMENT_EXTENSIONS_CAPTION } from 'config/constants/fileFormats';

import PreviousDocuments from 'components/shared/molecules/PreviousDocuments';
import CaptionSmall from 'components/ui/atoms/text/CaptionSmall';
import FileUploader from 'components/ui/organisms/FileUploader';

import { DetailsWrapper } from './styled';
import type { DocumentsUploadFormProps } from './types';

const DocumentsUploadForm = ({
  setUploadedDocuments,
  setIsDocumentsLoading,
  previousDocuments,
  label,
  name,
}: DocumentsUploadFormProps) => {
  const fileInputProps = {
    accept: ACCEPT_DOCUMENT_EXTENSIONS,
  };

  return (
    <DetailsWrapper>
      {label && <CaptionSmall>{label}</CaptionSmall>}
      <CaptionSmall>{ACCEPT_DOCUMENT_EXTENSIONS_CAPTION}</CaptionSmall>
      <FileUploader
        setUploaded={setUploadedDocuments}
        multiple
        fileInputProps={fileInputProps}
        setLoading={setIsDocumentsLoading}
        documentName={name}
      />
      {previousDocuments && <PreviousDocuments documents={previousDocuments} columns={1} />}
    </DetailsWrapper>
  );
};

export default DocumentsUploadForm;
