import { cloneElement, forwardRef } from 'react';
import MaterialFormControl from '@mui/material/FormControl';

import FormHelperText from 'components/ui/atoms/FormHelperText';
import TitleSmall from 'components/ui/atoms/text/TitleSmall';

import FormLabel from './components/Label.styled';
import type { FormControlProps } from './types';

// TODO type props
const generateErrorText = (attribute: string, props: any): string =>
  `FormControl component: "${attribute}" attribute is required. Passed props - "id": ${props.id}, "name": ${props.name}`;

const requiredProps = ['id'];

// TODO type ref
const FormControl = forwardRef<any, FormControlProps>((props, ref) => {
  requiredProps.forEach((attribute) => {
    if (!props[attribute as keyof typeof props]) console.error(generateErrorText(attribute, props));
  });

  const { id, error, disabled, required, label, helperText, errorText, control, component, fieldTitle, ...other } =
    props;

  const helperTextId = `${id}-helper-text`;

  const labelProps = {
    error,
    disabled,
    required,
    // htmlFor: id,
  };

  const controlProps = {
    id,
    error,
    disabled,
    required,
    'aria-describedby': helperTextId,
    className: 'form-control',
    ...other,
  };

  return component ? (
    cloneElement(component, { label, ref, ...controlProps })
  ) : (
    <MaterialFormControl error={error} disabled={disabled} ref={ref} fullWidth>
      {fieldTitle && <TitleSmall>{fieldTitle}</TitleSmall>}

      {label && <FormLabel {...labelProps}>{label}</FormLabel>}

      {control && cloneElement(control, controlProps)}

      {errorText && (
        <FormHelperText id={helperTextId}>
          <span>{errorText}</span>
        </FormHelperText>
      )}

      {helperText && (
        <FormHelperText error={false} id="component-helper-text">
          <span>{helperText}</span>
        </FormHelperText>
      )}
    </MaterialFormControl>
  );
});

export default FormControl;
