import {
  YUP_NULLABLE_REQUIRED,
  YUP_NULLABLE_REQUIRED_VALID_EMAIL,
  YUP_REQUIRED_PHONE_NUMBER,
} from 'config/constants/yup';

import type { CreateRealEstateFormValues } from './mutationsValues';

type ValidationFields = Record<CreateRealEstateFormValues, any>;

export const saveForLaterValidationFields: Pick<
  ValidationFields,
  | 'merchantFirstName'
  | 'merchantLastName'
  | 'merchantEmail'
  | 'merchantCellPhone'
  | 'businessName'
  | 'propertyType'
  | 'loanType'
> = {
  merchantFirstName: YUP_NULLABLE_REQUIRED,
  merchantLastName: YUP_NULLABLE_REQUIRED,
  merchantEmail: YUP_NULLABLE_REQUIRED_VALID_EMAIL,
  merchantCellPhone: YUP_REQUIRED_PHONE_NUMBER,
  businessName: YUP_NULLABLE_REQUIRED,
  propertyType: YUP_NULLABLE_REQUIRED,
  loanType: YUP_NULLABLE_REQUIRED,
};
