import { StyledCaption } from './styled';
import type { CaptionSmallProps } from './types';

const CaptionSmall = ({ children, disabled, textCase, textColor, marginBottom, dataTestId }: CaptionSmallProps) => (
  <StyledCaption
    disabled={disabled}
    textCase={textCase}
    textColor={textColor}
    marginBottom={marginBottom}
    data-testid={dataTestId}
  >
    {children}
  </StyledCaption>
);

export default CaptionSmall;
