import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { FontWeight, Margin, TextCase } from 'types/theme/text';

import type { TitleMediumProps } from './types';

export const StyledTitle = styled.h3<Pick<TitleMediumProps, 'disabled' | 'textCase' | 'marginBottom' | 'fontWeight'>>(
  ({ theme: { spacing, palette, typography }, disabled, textCase, marginBottom, fontWeight }) => css`
    ${typography.title};
    margin: 0 0 ${spacing(marginBottom ?? Margin.Medium)};
    text-transform: ${textCase ?? TextCase.None};
    color: ${disabled ? palette.text.disabled : palette.text.primary};
    font-weight: ${fontWeight ?? FontWeight.Normal};
  `,
);
