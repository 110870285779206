import { loader } from 'graphql.macro';

import type { QueryHook } from 'api/types/hooks/query';

import useQuery from 'lib/apollo/hooks/useQuery';

import type { RealEstateFormQueryData, RealEstateFormQueryOriginalData, RealEstateFormQueryVariables } from './types';

const RealEstateFormQuery = loader('./realEstateForm.graphql');

const getData = (data?: RealEstateFormQueryOriginalData): RealEstateFormQueryData => data?.realEstateForm;

const useRealEstateFormQuery: QueryHook<
  RealEstateFormQueryOriginalData,
  RealEstateFormQueryVariables,
  RealEstateFormQueryData
> = (options = {}) => {
  const { data, ...rest } = useQuery(RealEstateFormQuery, {
    fetchPolicy: 'cache-first',
    ...options,
    onCompleted: options?.onCompleted ? (completedData) => options.onCompleted?.(getData(completedData)) : undefined,
  });

  return {
    data: getData(data),
    ...rest,
  };
};

export default useRealEstateFormQuery;
