import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div(
  ({ theme: { colors, spacing } }) => css`
    position: relative;
    padding: ${spacing(4, 5)};
    border-radius: 8px;
    background-color: ${colors.blackBlue};
  `,
);

export const StyledTextField = styled.div(
  ({ theme: { palette } }) => css`
    color: ${palette.primary.contrastText};
  `,
);

export const PaymentNumber = styled(StyledTextField)`
  font-size: 60px;
`;

export const ButtonWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    margin: ${spacing(4, 0, 3)};
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 100%;
    }
  `,
);

export const IconWrapper = styled.div(
  ({ theme: { colors } }) => css`
    width: 58px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${colors.green};

    svg {
      stroke: ${colors.white};
    }
  `,
);
