import { createTheme as createMuiTheme } from '@mui/material/styles';
import type { OverridesStyleRules } from '@mui/material/styles/overrides';
import deepmerge from 'deepmerge';
import { transparentize } from 'polished';

import type { CustomTheme } from 'types/theme/customTheme';

import breakpoints from '../breakpoints';
import colors from '../colors';
import typographyVariants from '../typographyVariants';

const spacing = 8;

const numberSpacing = (value: number) => {
  return value * spacing;
};

const labelStyles = {
  root: {
    fontSize: '12px',
    lineHeight: '24px',
    marginBottom: spacing * 0.75,
    color: transparentize(0.5, colors.black),
  },
  asterisk: {
    color: colors.red,
  },
  animated: {
    transition: 'none',
  },
  formControl: {
    transform: 'none',
  },
  shrink: {
    transform: 'none',
  },
};

const listItemStyles = {
  color: colors.white,
  backgroundColor: colors.blue,
};

const overrides: OverridesStyleRules = {
  MuiInputLabel: { styleOverrides: { ...labelStyles } },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        ...labelStyles.root,
      },
      asterisk: { ...labelStyles.asterisk },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        alignItems: 'flex-start',
        marginLeft: 0,
        marginRight: 0,
        flexDirection: 'column-reverse',
      },
      label: (props: { required: boolean }) => ({
        ...labelStyles.root,
        '&:after': {
          content: props.required ? '" *"' : '""',
          color: colors.red,
        },
      }),
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        padding: `0 ${spacing}px`,
        borderRadius: '8px',
        boxShadow:
          '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
        color: colors.grayBlue,

        '&.Mui-selected': {
          ...listItemStyles,

          '& a': {
            color: colors.white,
          },

          '&:hover': {
            ...listItemStyles,

            '& a': {
              color: colors.white,
            },
          },
        },

        '&:hover': {
          ...listItemStyles,

          '& a': {
            color: colors.white,
          },
        },

        '&:not(:last-child)': {
          marginBottom: spacing * 0.45,
        },
      },
      button: {
        '&:hover': listItemStyles,
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      line: {
        borderColor: colors.blueLight,
      },
    },
  },
};

const THEME_BASE_PROPERTIES = {
  // global properties
  colors,
  // custom func; save default indent size in number format. Use this function when you have to do the math
  numberSpacing, // Usage: theme.numberSpacing(2) => '16'
  // common properties for material-ui & styled components
  spacing, // unit indent (px); Usage: theme.spacing(2, 4) => '16px 32px'
  breakpoints: {
    values: {
      ...breakpoints,
    },
  },
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: 14,
    ...typographyVariants,
  },
  iconSize: 18,
  components: overrides,
};

const createTheme = (theme: CustomTheme) => createMuiTheme(deepmerge(THEME_BASE_PROPERTIES, theme));

export default createTheme;
