import Icon from 'components/ui/atoms/Icon';

import BaseIconButton from './BaseIconButton';
import type { IconButtonProps } from './types';

const IconButton = ({ children, iconType, iconProps, ...restProps }: IconButtonProps) => (
  <BaseIconButton {...restProps}>{children || <Icon type={iconType} {...iconProps} />}</BaseIconButton>
);

export default IconButton;
