import type { ChangeEvent, DefaultEvent, Event, EventValue } from '../types';

export const getEvent = (
  event: NonNullable<ChangeEvent | DefaultEvent>,
  value: EventValue,
  isNoneNullable?: boolean,
): Event => ({
  preventDefault: () => event.preventDefault(),
  target: {
    name: event.target.name,
    value: isNoneNullable ? Number(value) : value,
  },
});

export default getEvent;
