import type { FormField } from 'components/shared/organisms/form/FormSection/types';
import MoneyInput from 'components/ui/atoms/forms/MoneyInput';

export const GrossReceipts2020: FormField[] = [
  {
    id: 'grossReceipts2020Q1Cents',
    name: 'grossReceipts2020Q1Cents',
    label: '2020 Q1 gross receipts?',
    placeholder: 'Enter number',
    control: <MoneyInput />,
    required: true,
  },
  {
    id: 'grossReceipts2020Q2Cents',
    name: 'grossReceipts2020Q2Cents',
    label: '2020 Q2 gross receipts?',
    placeholder: 'Enter number',
    control: <MoneyInput />,
    required: true,
  },
  {
    id: 'grossReceipts2020Q3Cents',
    name: 'grossReceipts2020Q3Cents',
    label: '2020 Q3 gross receipts?',
    placeholder: 'Enter number',
    control: <MoneyInput />,
    required: true,
  },
  {
    id: 'grossReceipts2020Q4Cents',
    name: 'grossReceipts2020Q4Cents',
    label: '2020 Q4 gross receipts?',
    placeholder: 'Enter number',
    control: <MoneyInput />,
    required: true,
  },
];
