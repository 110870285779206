import type { FormSectionConfig } from 'components/shared/molecules/FormSectionMediator/types';

import CallBackButton from '../components/CallBackButton';
import { acquisitionFields } from './formFields/acquisitionFields';
import { contactInformationFields } from './formFields/contactInformationFields';
import type { GetDocumentsFieldsArgs } from './formFields/documentsFields';
import { getDocumentsFields } from './formFields/documentsFields';
import { loanTermsFields } from './formFields/loanTermsFields';
import { propertyInformationFields } from './formFields/propertyInformationFields';
import { refinanceFields } from './formFields/refinanceFields';

export type GetFormSectionsArgs = ({
  setUploadedDocuments,
  setIsDocumentsLoading,
}: GetDocumentsFieldsArgs) => FormSectionConfig[];
export const getFormSections: GetFormSectionsArgs = ({ setUploadedDocuments, setIsDocumentsLoading }) => [
  {
    id: 'contactInformation',
    columns: 2,
    title: 'Contact Information',
    withHiddenFields: false,
    formFields: contactInformationFields,
    isDefaultVisible: true,
  },
  {
    id: 'propertyInformation',
    columns: 1,
    title: 'Property Information',
    withHiddenFields: true,
    formFields: propertyInformationFields,
    isDefaultVisible: true,
    Footer: CallBackButton,
  },
  {
    id: 'acquisitionInformation',
    columns: 2,
    title: 'Acquisition',
    formFields: acquisitionFields,
    isDefaultVisible: false,
  },
  {
    id: 'refinanceInformation',
    columns: 2,
    title: 'Refinance',
    formFields: refinanceFields,
    isDefaultVisible: false,
  },
  {
    id: 'loadTerms',
    columns: 2,
    title: 'Loan Terms',
    formFields: loanTermsFields,
    isDefaultVisible: true,
  },
  {
    id: 'documents',
    columns: 1,
    title: 'Documents',
    formFields: getDocumentsFields({ setUploadedDocuments, setIsDocumentsLoading }),
    isDefaultVisible: true,
  },
];
