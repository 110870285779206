import { StyledTitle } from './styled';
import type { TitleLargeProps } from './types';

const TitleLarge = ({ children, dataTestId, ...otherProps }: TitleLargeProps) => (
  <StyledTitle data-testid={dataTestId} {...otherProps}>
    {children}
  </StyledTitle>
);

export default TitleLarge;
