import type { ChangeEvent } from 'react';

import Checkbox from './Checkbox';
import type { FormCheckboxProps } from './types';

const FormCheckbox = ({
  name,
  onChange,
  value = false,
  truthyValue = true,
  falsyValue = false,
  ...props
}: FormCheckboxProps) => {
  const checked = value === truthyValue;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // @ts-expect-error by types value can be boolean or string. Investigate it
    onChange?.({ target: { name, value: event.target.checked ? truthyValue : falsyValue } });
  };

  return <Checkbox name={name} checked={checked} onChange={handleChange} {...props} />;
};

export default FormCheckbox;
