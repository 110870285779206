import { Icons } from 'components/ui/atoms/Icon';

import IconButton from './IconButton';
import type { CloseIconButtonProps } from './types';

export type { CloseIconButtonProps };

const CloseButton = (props: CloseIconButtonProps) => <IconButton iconType={Icons.X} {...props} />;

export default CloseButton;
