import { useMemo, useRef, useState } from 'react';

import TYPES from 'config/types/notifierTypes';

import ErrorDecorator from 'decorators/ErrorDecorator';

import NotifierContext from './NotifierContext';
import Notifiers from './Notifiers';

const NotifierProvider = ({ children }) => {
  const { current: notifiers } = useRef(new Notifiers());
  const [notifierList, setNotifierList] = useState(notifiers.newList);

  const setError = (error = {}) => {
    const messages = new ErrorDecorator(error).getMessages();

    messages.forEach((message) =>
      notifiers.set({
        message,
        type: TYPES.error,
        actionTimeOut: undefined,
      }),
    );

    setNotifierList(notifiers.newList);
  };

  const setInfo = (message, { iconType = '', withoutIcon = false, link } = {}) => {
    notifiers.set({
      message,
      iconType,
      link,
      type: TYPES.info,
      withoutIcon,
      actionTimeOut: 5000,
    });

    setNotifierList(notifiers.newList);
  };

  const setSuccess = (message = {}) => {
    notifiers.set({
      message,
      type: TYPES.success,
      actionTimeOut: 5000,
    });

    setNotifierList(notifiers.newList);
  };

  const clearMessage = (id) => {
    if (notifiers.remove(id)) {
      setNotifierList(notifiers.newList);
    }
  };

  const context = useMemo(
    () => ({
      notifierList,
      setError,
      setSuccess,
      setInfo,
      clearMessage,
    }),
    [notifierList],
  );

  return <NotifierContext.Provider value={context}>{children}</NotifierContext.Provider>;
};

export default NotifierProvider;
