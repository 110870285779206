import FormLabel from '@mui/material/FormLabel';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

const styles = ({ theme }: { theme: Theme }) => ({
  ...theme.typography.t3,
  color: theme.palette.text.secondary,
  marginBottom: 0,
  alignSelf: 'baseline',
  cursor: 'default',
});

const Label = styled(FormLabel)(styles);

export default Label;
