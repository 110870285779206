import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import useCreateIntakeFormV2Mutation from 'api/mutations/intakeForm/useCreateIntakeFormV2Mutation';

import { ORIGIN, STEP, UUID } from 'config/constants/queryParams';
import { INTAKE_FORM } from 'config/routes';

import useAddingQueryParams from 'hooks/query/useAddingQueryParams';
import useNotifier from 'hooks/useNotifier';
import useReferralId from 'hooks/useReferralId';

import getIntakeFormReferralIdFromOrigin from 'components/pages/IntakeForm/getIntakeFormReferralIdFromOrigin';
import { INTAKE_FORM_FIRST_STEP } from 'components/pages/IntakeFormSteps/config';

const mutationName = 'createIntakeFormV2';

const useCreateIntakeForm = () => {
  const navigate = useNavigate();
  const { setError } = useNotifier();
  const [searchParams] = useSearchParams();
  const origin = searchParams.get(ORIGIN);
  const userReferralId = useReferralId(getIntakeFormReferralIdFromOrigin);

  const { getParamsWithAdded } = useAddingQueryParams();

  const [createIntakeFormMutation, { loading }] = useCreateIntakeFormV2Mutation({
    onCompleted: (data) => {
      const params = getParamsWithAdded({ [UUID]: data?.[mutationName]?.uuid ?? null, [STEP]: INTAKE_FORM_FIRST_STEP });

      const pathname = userReferralId ? `${INTAKE_FORM}/${userReferralId}` : INTAKE_FORM;
      navigate({ pathname, search: `?${createSearchParams(params)}` });
    },
    onError: (error) => {
      setError(`Form error: ${error}`);
    },
  });

  // TODO: use correct fields for this mutation
  const createIntakeForm = async ({ fields }: Record<string, any>) => {
    const variables = {
      ...fields,
      userReferralId,
      applicationFormSource: origin || window.location.href,
    };

    await createIntakeFormMutation({
      variables,
    });
  };

  return { createIntakeForm, loading };
};

export default useCreateIntakeForm;
