import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div(
  ({ theme: { spacing } }) => css`
    position: fixed;
    z-index: 1310;
    bottom: ${spacing(4)};
    left: ${spacing(2)};
  `,
);

export const ToastWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    margin-top: ${spacing(3)};
  `,
);
