import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

export const StyledSlider = styled(Slider)(() => ({
  '& >:nth-last-of-type(2)': {
    left: 'auto !important',
    right: '0 !important',
  },
  '& .MuiSlider-markLabel': {
    left: 0,
    transform: 'none',
  },
}));
