export const HOME = '/';
export const ERC = '/erc';
export const INTAKE_LANDING = '/apply';
export const INTAKE_FORM = '/apply/form';
export const ONE_STEP_FORM = '/mini';
export const CCP_FORM = '/ccp';
export const CALCULATOR = '/calculator';
export const RESUBSCRIBE = '/resubscribe';
export const REAL_ESTATE = '/real-estate';
export const EXAMPLES = '/examples';
