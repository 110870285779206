import type { FormField } from 'components/shared/organisms/form/FormSection/types';
import EmailInput from 'components/ui/atoms/forms/EmailInput';
import Input from 'components/ui/atoms/forms/Input';
import PhoneInput from 'components/ui/atoms/forms/PhoneInput';
import StringInput from 'components/ui/atoms/forms/StringInput';

export const ERCGeneralInformationFields: FormField[] = [
  {
    id: 'business-name',
    name: 'businessName',
    label: 'Business Name',
    placeholder: 'Smith Roofing',
    control: <StringInput />,
    required: true,
  },
  {
    id: 'business-employees-count',
    name: 'businessEmployeesCount',
    label: 'Number of Employees',
    control: <Input type="number" />,
    required: true,
  },
  {
    id: 'merchant-first-name',
    name: 'merchantFirstName',
    label: 'First Name',
    placeholder: 'John',
    control: <StringInput />,
    required: true,
  },
  {
    id: 'merchant-last-name',
    name: 'merchantLastName',
    label: 'Last Name',
    placeholder: 'Smith',
    control: <StringInput />,
    required: true,
  },
  {
    id: 'merchant-email',
    name: 'merchantEmail',
    label: 'Email Address',
    control: <EmailInput />,
    required: true,
  },
  {
    id: 'merchant-cell-phone',
    name: 'merchantCellPhone',
    label: 'Contact #',
    control: <PhoneInput />,
    required: true,
  },
];
