import PageTitle from 'components/ui/atoms/text/PageTitle';

import { Container } from './styled';

const Header = () => (
  <Container>
    <PageTitle>One Short Step to Funding</PageTitle>
    <p>
      Thank you for placing your trust in us. Please take a moment to complete the form below. We will process your
      request right away.
    </p>
  </Container>
);

export default Header;
