import { FontWeight, Margin } from 'types/theme/text';

import PageTitle from 'components/ui/atoms/text/PageTitle';
import TitleLarge from 'components/ui/atoms/text/TitleLarge';

import { Container } from './styled';

const Header = () => (
  <Container>
    <PageTitle>Real Estate Financing</PageTitle>
    <TitleLarge fontWeight={FontWeight.Bold} marginBottom={Margin.Large}>
      Application Form
    </TitleLarge>
    <p>
      Thank you for placing your trust in Premium Merchant Funding. Please take a moment to review filled information
      before submitting.
    </p>
  </Container>
);

export default Header;
