import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const StyledDatePicker = styled(DatePicker)<{ error?: any }>(
  ({ theme: { typography, breakpoints, colors, palette }, error }) => ({
    '& .MuiOutlinedInput-root': {
      ...typography.input,
      [breakpoints.down('sm')]: {
        ...typography.inputForMobile,
      },
      height: '40px',
      background: colors.white,
      borderColor: palette.border,

      '& .MuiOutlinedInput-input': {
        boxSizing: 'border-box',

        '&::placeholder': {
          opacity: 1,
          color: palette.text.secondary,
        },
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: error ? palette.error.main : palette.border,
      },

      '&:hover:not(.Mui-focused):not(.Mui-error):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.border,
      },

      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
        },

        '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.primary.main,
        },
      },

      '&.Mui-disabled': {
        background: palette.form.disabled.background,
        color: palette.form.disabled.text,
        '&::placeholder': {
          color: palette.form.disabled.text,
        },

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.form.disabled.border,
        },
      },

      '& .MuiIconButton-root': {
        borderRadius: 0,
        backgroundColor: 'transparent',

        '& > svg': {
          width: '18px',
          height: '18px',
        },
      },
    },
  }),
);
