import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import {
  CALCULATOR,
  CCP_FORM,
  ERC as ERCRoute,
  EXAMPLES,
  HOME,
  INTAKE_FORM,
  INTAKE_LANDING,
  ONE_STEP_FORM,
  REAL_ESTATE,
  RESUBSCRIBE,
} from 'config/routes';
import { DEV } from 'config/vars';

import Calculator from 'components/pages/Calculator';
import Ccp from 'components/pages/CCP';
import ERC from 'components/pages/ERC';
import Examples from 'components/pages/Examples';
import Home from 'components/pages/Home';
import IntakeFormLanding from 'components/pages/IntakeFormLanding';
import IntakeFormSteps from 'components/pages/IntakeFormSteps';
import OneStep from 'components/pages/OneStep';
import RealEstate from 'components/pages/RealEstate';
import Resubscribe from 'components/pages/Resubscribe';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path={HOME} element={<Home />} />
        <Route path={ERCRoute} element={<ERC />}>
          <Route path=":referralId" element={<ERC />} />
        </Route>
        <Route path={INTAKE_FORM} element={<IntakeFormSteps />}>
          <Route path=":referralId" element={<IntakeFormSteps />} />
        </Route>
        <Route path={INTAKE_LANDING} element={<IntakeFormLanding />}>
          <Route path=":referralId" element={<IntakeFormLanding />} />
        </Route>
        <Route path={ONE_STEP_FORM} element={<OneStep />}>
          <Route path=":referralId" element={<OneStep />} />
        </Route>
        <Route path={CCP_FORM} element={<Ccp />}>
          <Route path=":referralId" element={<Ccp />} />
        </Route>
        <Route path={RESUBSCRIBE} element={<Resubscribe />} />
        <Route path={CALCULATOR} element={<Calculator />} />
        <Route path={REAL_ESTATE} element={<RealEstate />}>
          <Route path=":referralId" element={<RealEstate />} />
        </Route>
        {DEV && <Route path={EXAMPLES} element={<Examples />} />}
      </Routes>
    </Router>
  );
};

export default App;
