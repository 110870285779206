import styled from '@emotion/styled';
import AccordionWrapper from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { styled as muiStyled } from '@mui/material/styles';

export const Wrapper = styled.div`
  position: relative;
`;

export const SummaryContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledAccordion = muiStyled(AccordionWrapper)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

export const StyledAccordionSummary = muiStyled(AccordionSummary)(({ theme: { palette, spacing, typography } }) => ({
  ...typography.t1,
  margin: '0',
  justifyContent: 'flex-start',
  padding: spacing(1, 0),
  minHeight: 'auto',
  alignItems: 'center',
  '&.Mui-expanded': {
    minHeight: 'auto',
  },

  '& .MuiAccordionSummary-content': {
    marginLeft: spacing(1),
    flexGrow: 'initial',
    margin: '0',
    '&.Mui-expanded': {
      margin: '0',
    },
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    padding: spacing(0, 1, 0, 1),

    '& svg': {
      stroke: palette.text.primary,
    },
  },
}));

export const StyledAccordionDetails = muiStyled(AccordionDetails)(({ theme: { spacing } }) => ({
  padding: spacing(0.5, 0, 1),
}));

export const AccordionInnerWrapper = styled.div`
  width: 100%;
`;
