import PageTitle from 'components/ui/atoms/text/PageTitle';

import { Container } from './styled';

const Header = () => (
  <Container>
    <PageTitle>Employee Retention Credit Eligibility Test</PageTitle>
    <p>
      This form will help us determine which quarters, if any, you are eligible to receive the employee retention
      credit. This form is intended to be sent to your accountant, and should not be used in lieu of advice from your
      tax advisor.
    </p>
  </Container>
);

export default Header;
