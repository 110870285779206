import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const HeaderContainer = styled.header(
  ({
    theme: {
      spacing,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${spacing(1.5, 4)};

    ${down(breakpoints.md)} {
      padding: ${spacing(1, 3)};
    }
  `,
);

export const HelperText = styled.p(
  ({
    theme: {
      spacing,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    margin-left: auto;
    display: flex;
    gap: ${spacing(1)};

    ${down(breakpoints.sm)} {
      font-size: 14px;
    }
  `,
);

export const HeaderLogo = styled.img(
  ({
    theme: {
      spacing,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    margin-right: ${spacing(2)};

    ${down(breakpoints.md)} {
      height: ${spacing(4)};
    }
  `,
);
