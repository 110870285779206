import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Margin } from 'types/theme/text';

import { H1 } from 'styles/typographyVariants';

import type { PageTitleProps } from './types';

export const StyledTitle = styled.h1<Pick<PageTitleProps, 'marginBottom' | 'textColor'>>(
  ({ theme: { palette, spacing }, marginBottom, textColor }) => css`
    ${H1};
    height: auto;
    margin: 0 0 ${spacing(marginBottom ?? Margin.Large)};
    font-weight: bold;
    color: ${textColor ? palette.text[textColor] : palette.text.primary};
  `,
);
