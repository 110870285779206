import { css } from '@emotion/react';
import { LatoLatin, LatoLatinExt } from 'fonts/Lato';
import { Mulish } from 'fonts/Mulish';

const globalStyles = css`
  ${LatoLatin};
  ${LatoLatinExt};
  ${Mulish}

  * {
    box-sizing: border-box;
  }

  html,
  body,
  #__next {
    height: 100%;
  }

  html {
    text-size-adjust: 100%;
  }

  body {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
  }

  :focus {
    outline: 0;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  a,
  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
    transition: all 0.25s ease-in-out 0s;
    outline: none;
  }

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
  }
`;

export default globalStyles;
